export const tour1init = 'tour_1_init'; //  Стартовая модалка
export const tour1egg2 = 'tour_1_2'; // Рейтинг
export const tour1egg3 = 'tour_1_3'; // ЛидсКоины
export const tour1egg4 = 'tour_1_4'; // Маркет каталог
export const tour1egg5 = 'tour_1_5'; // Centrmama
export const tour1egg6 = 'tour_1_6'; // Динамика
export const tour1egg7 = 'tour_1_7'; // Футер

export const tour2init = 'tour_2_init';
export const tour2egg2 = 'tour_2_2';
export const tour2egg3 = 'tour_2_3';
export const tour2egg4 = 'tour_2_4';
export const tour2egg5 = 'tour_2_5';
export const tour2egg6 = 'tour_2_6';
export const tour2egg7 = 'tour_2_7';
export const tour2egg8 = 'tour_2_8';

export const tour3init = 'tour_3_init';
export const tour3egg2 = 'tour_3_2';
export const tour3egg3 = 'tour_3_3';
export const tour3egg4 = 'tour_3_4';
export const tour3egg5 = 'tour_3_5';
export const tour3egg6 = 'tour_3_6';
export const tour3egg7 = 'tour_3_7';
export const tour3egg8 = 'tour_3_8';

export default class EasterEgg {
    constructor(
        id,
        code,
        available,
        collected,
        hint,
        availableFrom,
        order,
    ) {
        this.id = id;
        this.code = code;
        this.available = available;
        this.collected = collected;
        this.hint = hint;
        this.showHint = false;
        this.availableFrom = availableFrom;
        this.order = order;
    }

    get formattedAvailableFrom() {
        const date = new Date(this.availableFrom);
        const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        };
        return date.toLocaleDateString('ru', options);
    }

    get needShow() {
        return !this.collected && this.available;
    }

    collect() {
        this.collected = true;
    }

    disable() {
        this.available = false;
    }
}
