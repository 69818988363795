<template>
    <div class="banner-task-mob">
        <img src="@/assets/images/banners/bannerMob.png"
             alt="img">
        <div  v-if="easterEgg && easterEgg.needShow"
              class="banner-task-mob__tour3-egg3"
              @click="collectEasterEgg" />
        <router-link :to="{name: 'MarketCatalog'}"
                     class="banner-task-mob__button">
            Узнать подробнее
        </router-link>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {tour3egg3} from '@/models/EasterEggs/EasterEgg.js';

export default {
    name: 'BannerTasksMob',
    computed: {
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        easterEgg() {
            return this.getAvailable(tour3egg3);
        },
    },
    created() {
        this.check(tour3egg3);
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        collectEasterEgg() {
            this.collect(this.easterEgg);
        },
    },
};
</script>

<style scoped>

</style>
