<template>
    <Feedback />
</template>

<script>
import Feedback from '@/components/feedback/Feedback.vue';

export default {
    name: 'FeedbackPage',
    components: {
        Feedback,
    },
};
</script>
