export default class Credential {
    /**
     *
     * @param token
     * @param expire
     * @param refreshToken
     */

    constructor(
        token, expire, refreshToken,
    ) {
        this.token = token;
        this.expire = expire;
        this.refreshToken = refreshToken;
    }

    toJson() {
        return JSON.stringify({
            token: this.token,
            expire: this.expire,
            refreshToken: this.refreshToken,
        });
    }
}
