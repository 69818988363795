<template>
    <div class="clnt-tasks-group">
        <div class="clnt-tasks-group__gallery">
            <div class="clnt-tasks-gallery__header"
                 v-if="title">
                <h2 class="clnt-tasks-gallery__title">
                    {{ title }}
                </h2>
                <router-link :to="{name: 'Tasks'}"
                             class="clnt-tasks-gallery__link"
                             v-if="more">
                    Смотреть все
                </router-link>
            </div>
            <div class="clnt-tasks-group__filter"
                 v-if="filterBtnsАvailable.length > 2">
                <button class="clnt-tasks-group__filter-btn"
                        :class="filterBtnClass(item.code)"
                        v-for="item in filterBtnsАvailable"
                        :key="item.code"
                        @click="filter(item.code)">
                    {{ item.title }}
                </button>
            </div>
            <TasksGallery :title="title"
                          :slider-id="sliderId"
                          :tasks="tasksFiltered" />
        </div>
    </div>
</template>

<script>
import TasksGallery from '@/components/tasks/TasksGallery';

export default {
    name: 'TasksGroup',
    components: {
        TasksGallery,
    },
    props: {
        offerId: {
            type: String,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        sliderId: {
            type: String,
            default: '0',
        },
        title: {
            type: String,
            default: '',
        },
        more: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            filterBtns: [
                {
                    title: 'Все',
                    code: 0,
                },
                {
                    title: 'Легкие',
                    code: 1,
                },
                {
                    title: 'Средние',
                    code: 2,
                },
                {
                    title: 'Сложные',
                    code: 3,
                },
            ],
            selectedFilterCode: 0,
            tasksFiltered: this.tasks,
        };
    },
    computed: {
        filterBtnsАvailable() {
            const result = [this.filterBtns[0]];
            for (let i = 0; i < this.filterBtns.length; i++) {
                const item = this.filterBtns[i];
                const filter = this.tasks.filter(elem => elem.complicacyCode === item.code);
                if (filter.length) {
                    result.push(item);
                }
            }
            return result;
        },
    },
    watch: {
        tasks(newValue) {
            this.tasksFiltered = this.sortTasks(newValue);
        },
    },
    mounted() {
        this.filter(0);
    },
    methods: {
        filter(code) {
            this.selectedFilterCode = code;
            let filter;
            if (code) {
                filter = this.tasks.filter(elem => elem.complicacyCode === code);
            } else {
                filter = this.tasks;
            }

            this.tasksFiltered = this.sortTasks(filter);
        },
        filterBtnClass(code) {
            return {
                'clnt-tasks-group__filter-btn_active': (code === this.selectedFilterCode),
            };
        },
        sortTasks(tasks) {
            const statusCodeCustom = {
                '1': 1,
                '6': 2,
                '3': 3,
                '2': 4,
                '7': 5,
                '5': 6,
                '4': 7,
            };

            return tasks.slice().sort((a, b) => {
                const status = statusCodeCustom[a.statusCode] - statusCodeCustom[b.statusCode];
                if (status) {
                    return status;
                }
                const isCheckingRequired = a.isCheckingRequired - b.isCheckingRequired;
                if (isCheckingRequired) {
                    return isCheckingRequired;
                }
                const complicacy = a.complicacyCode - b.complicacyCode;
                if (complicacy) {
                    return complicacy;
                }
                const amount = b.amount - a.amount;
                if (amount) {
                    return amount;
                }
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        },
    },
};
</script>
