<template>
    <div class="lm-charity">
        <template v-if="hasDonations">
            <div class="lds-container">
                <div class="lm-charity__donations">
                    <div class="lm-charity__donations-left">
                        <div class="lm-charity__title lm-charity__title_sm">
                            Спасибо, что Вы с нами!
                        </div>
                        <div class="lm-charity__text lm-charity__text_sm">
                            Самый быстрый способ принять участи в общем деле - это сделать взнос в Leads.Coins.
                            Мы ведем
                            <router-link :to="{name: 'MarketCharityDonations'}">
                                журнал взносов
                            </router-link>,
                            а так же публикуем
                            <router-link :to="{name: 'MarketCharityReports'}">
                                отчеты
                            </router-link>,
                            на что были использованы Leads.Coins.
                        </div>
                        <div class="lm-charity__form">
                            <a class="lds-btn lds-btn_fill -green"
                               href="#targets"
                               @click.prevent="scrollTo('targets')">
                                Принять участие
                            </a>
                        </div>
                    </div>
                    <div class="lm-charity__donations-right">
                        <div class="lm-charity__history">
                            <div class="lm-charity__history-title">
                                Ваши пожертвования:
                            </div>
                            <div class="lm-charity__history-item"
                                 v-for="(donation, index) in sortedDonations"
                                 :key="index">
                                <div class="lm-charity__history-date">
                                    {{ donation.created }}
                                </div>
                                <div class="lm-charity__history-amount">
                                    {{ formatCoins(donation.amount) }}
                                    <SvgIcon name="leads-coins" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div class="lm-charity__section lm-charity__section_light">
            <div class="lm-charity__container lm-charity__container_lg">
                <div class="lm-charity__intro">
                    <div class="lm-charity__intro-image">
                        <img src="@/assets/images/charity/charity_1.png"
                             alt>

                        <a v-if="isTour2Egg8birdClicked && easterTour2Egg8 && easterTour2Egg8.needShow"
                           class="lm-charity__intro-egg"
                           @click="collectEasterTour2Egg8">
                            <img src="@/assets/images/charity/charity_easter_flower.png"
                                 alt>
                        </a>
                    </div>
                    <div class="lm-charity__intro-info">
                        <h1 class="lm-charity__intro-title">
                            CENTR<span>MAMA</span>
                        </h1>
                        <p class="lm-charity__intro-label">
                            совместный проект
                        </p>
                        <div class="lm-charity__intro-logos">
                            <div class="lm-charity__intro-logo">
                                <img src="@/assets/images/charity/leads_logo_black.svg"
                                     alt>
                            </div>
                            <div class="lm-charity__intro-logo">
                                <img src="@/assets/images/charity/dobro_darom_logo.png"
                                     alt>
                                <div class="lm-charity__tour3egg7"
                                     v-if="isTour3Egg7"
                                     @click="tour3Egg7Advance" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="targets"
             v-if="hasTargets"
             ref="targets"
             data-test="targets"
             class="lm-charity__section">
            <div class="lm-charity__container lm-charity__container_xl">
                <h2 class="lm-charity__title">
                    Цели
                </h2>
                <CentrmamaTargets :list="getTargetListAvailable" />
            </div>
        </div>

        <div class="lm-charity__section lm-charity__section_light">
            <div class="lm-charity__container">
                <h2 class="lm-charity__title">
                    Хочу помочь
                </h2>
                <div class="lm-charity__grid">
                    <div class="lm-charity__grid-info">
                        <div class="lm-charity__title lm-charity__title_sm">
                            Самый простой способ
                        </div>
                        <div class="lm-charity__text lm-charity__text_sm">
                            Самый быстрый способ принять участи в общем деле - это сделать взнос в Leads.Coins.
                            Мы ведем
                            <router-link :to="{name: 'MarketCharityDonations'}">
                                журнал взносов
                            </router-link>,
                            а так же публикуем
                            <router-link :to="{name: 'MarketCharityReports'}">
                                отчеты
                            </router-link>,
                            на что были использованы Leads.Coins.
                        </div>
                        <div class="lm-charity__form">
                            <a class="lds-btn lds-btn_fill -green"
                               href="#targets"
                               @click.prevent="scrollTo('targets')">
                                Принять участие
                            </a>
                        </div>
                    </div>
                    <div class="lm-charity__grid-img -desktop">
                        <img src="@/assets/images/charity/charity_3.png"
                             alt>
                    </div>
                </div>
            </div>
        </div>

        <div class="lm-charity__section">
            <div class="lm-charity__container">
                <h2 class="lm-charity__title">
                    Что это?
                </h2>
                <ul class="lm-charity__ul">
                    <li class="lm-charity__ul-item">
                        это Центр комплексной помощи беременным женщинам и женщинам с младенцами, оказавшимся в трудной жизненной
                        ситуации
                    </li>
                    <li class="lm-charity__ul-item">
                        <b>LEADS</b> является официальным партнером благотворительного фонда «Добро Даром Республики Татарстан» в Казани уже <b>4 года</b>
                    </li>
                </ul>
                <div class="lm-charity__video">
                    <iframe class="lm-charity__frame-video"
                            src="https://www.youtube.com/embed/9-46s2FaLG8?showinfo=0"
                            frameborder="0"
                            allowfullscreen />
                </div>
                <div class="lm-charity__section-contact">
                    <div class="lm-charity__social">
                        <div class="lm-charity__social-link">
                            <a href="https://t.me/centrmama"
                               target="_blank">
                                <img src="@/assets/images/charity/charity_telegram.png"
                                     alt>
                            </a>
                        </div>

                        <div class="lm-charity__social-link">
                            <a href="https://www.youtube.com/channel/UCjv2st7tB0aGTsGh5ZBzeUA"
                               target="_blank">
                                <img src="@/assets/images/charity/charity_youtube.png"
                                     alt>
                            </a>
                        </div>

                        <div class="lm-charity__social-link">
                            <a href="https://dobrodarom.ru/campaign/donate"
                               target="_blank">
                                <img src="@/assets/images/charity/charity_web.png"
                                     alt>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="lm-charity__section lm-charity__section_light">
            <div class="lm-charity__container">
                <h2 class="lm-charity__title">
                    Кому помогаем?
                </h2>
                <ol class="lm-charity__ol">
                    <li class="lm-charity__ol-item">
                        Беременным женщинам, стоящим на грани совершения <b>аборта</b>
                    </li>
                    <li class="lm-charity__ol-item">
                        <b>Одиноким матерям</b>, попавшим в тяжелую жизненную ситуацию
                    </li>
                    <li class="lm-charity__ol-item">
                        <b>Многодетным матерям и семьям</b>, находящимся в крайне стесненном материальном положении
                    </li>
                </ol>
                <div class="lm-charity__container-img-right">
                    <img src="@/assets/images/charity/charity_2.png"
                         alt>
                </div>
            </div>
        </div>

        <div class="lm-charity__section">
            <div class="lm-charity__container">
                <h2 class="lm-charity__title">
                    Как именно?
                </h2>
                <ol class="lm-charity__ol">
                    <li class="lm-charity__ol-item">
                        <b>Материальная и продуктовая помощь</b>
                        <div class="lm-charity__ol-description">
                            Помощь предметами первой необходимости: детскими колясками, кроватками, памперсами, дет. вещами,
                            гигиеническими средствами по уходу за новорожденными.
                            Помощь продуктами питания.
                        </div>
                    </li>
                    <li class="lm-charity__ol-item">
                        <b>Информационная поддержка</b>
                        <div class="lm-charity__ol-description">
                            Просветительские материалы об аборте и радости материнства.
                            размещение информационных материалов с телефонами, которые могут быть полезны беременным женщинам и
                            матерям с детьми.
                        </div>
                    </li>
                    <li class="lm-charity__ol-item">
                        <b>Приют временного пребывания</b>
                        <div class="lm-charity__ol-description">
                            Предоставление временного места для проживания матери с ребенком или беременной женщине в кризисной
                            ситуации. Помощь по уходу за детьми, а также предоставление надомного труда и возможности заработать
                            средства на будущую жизнь.
                        </div>
                    </li>
                    <li class="lm-charity__ol-item">
                        <b>Консультационная помощь</b>
                        <div class="lm-charity__ol-description">
                            Оказание юридической, психологической и социальной помощи. Мы привлекаем психологов, врачей, юристов и
                            социльных работников, неравнодушных к чужим бедам.
                        </div>
                    </li>
                </ol>
                <div class="lm-charity__section-action">
                    <a class="lds-btn lds-btn_fill -green"
                       href="#targets"
                       @click.prevent="scrollTo('targets')">
                        Принять участие
                    </a>
                </div>
            </div>
        </div>

        <div class="lm-charity__section lm-charity__section_light">
            <div class="lm-charity__container">
                <div class="lm-charity__grid">
                    <div class="lm-charity__grid-info">
                        <h2 class="lm-charity__title lm-charity__title_sm">
                            Расскажите миру
                        </h2>
                        <div class="lm-charity__text">
                            <p>
                                Мы будем очень благодарны, если Вы расскажете о нашей инициативе своим коллегам по цеху, друзьям и
                                знакомым.
                            </p>
                        </div>
                        <div class="lm-charity__section-contact">
                            <div class="lm-charity__share">
                                <yandex-share :services="share.services"
                                              :title="share.title"
                                              :description="share.description"
                                              :image="share.image"
                                              :url="share.url" />
                            </div>
                        </div>
                    </div>
                    <div class="lm-charity__grid-img">
                        <a v-if="!isTour2Egg8birdClicked && easterTour2Egg8 && easterTour2Egg8.needShow"
                           href="#"
                           @click.prevent="tour2Egg8birdClick">
                            <img src="@/assets/images/charity/charity_4.png"
                                 alt>
                        </a>
                        <img v-else
                             src="@/assets/images/charity/charity_4.png"
                             alt>
                    </div>
                </div>
            </div>
        </div>

        <div class="lm-charity__section">
            <div class="lm-charity__container">
                <div class="lm-charity__grid">
                    <div class="lm-charity__grid-info">
                        <h2 class="lm-charity__title lm-charity__title_sm">
                            Примите участие лично
                        </h2>
                        <div class="lm-charity__text">
                            <p>
                                Вы можете связаться с центром
                                <a href="http://centrmama.ru/"
                                   target="_blank"
                                   rel="noopener">CENTRMAMA</a>
                                лично и оказать помощь непосредственно.
                                Для этого просто отправьте запрос и мы обязательно свяжемся с Вами.
                            </p>
                        </div>
                        <div class="lm-charity__section-contact">
                            <b>Ольга, директор CENTRMAMA</b>
                            <p>+7 917 255-45-40 </p>
                            <br>
                            <b>Раушан, директор фонда Добродаром</b>
                            <p>+7 937 009-09-60</p>
                        </div>
                    </div>
                    <div class="lm-charity__grid-img">
                        <img src="@/assets/images/charity/charity_5.png"
                             alt>
                        <span v-if="!!easterTour1Egg5 && easterTour1Egg5.needShow"
                              class="lm-charity__easter-egg"
                              @click="collect(easterTour1Egg5)">❤</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import YandexShare from '@cookieseater/vue-yandex-share';
import SvgIcon from '@/components/common/SvgIcon';
import formattingCoins from '@/mixins/formattingCoins';
import CentrmamaTargets from '@/components/centrmama/targets';
import {tour1egg5, tour2egg8, tour3egg7} from '@/models/EasterEggs/EasterEgg';
import EasterEggCookies, {
    tour2Egg8birdClicked,
    tour3Egg7WindowClicked,
} from '@/models/EasterEggs/EasterEggCookies';

export default {
    name: 'MarketCharityLanding',
    components: {
        CentrmamaTargets,
        SvgIcon,
        YandexShare,
    },
    mixins: [
        formattingCoins,
    ],
    data: () => ({
        showCount: 5,
        share: {
            title: 'Я участвую в благотворительном проекте Leads и "Добро Даром" - CENTRMAMA. Присоединяйтесь!',
            description: 'Я участвую в благотворительном проекте Leads и "Добро Даром" - CENTRMAMA. Присоединяйтесь!',
            image: 'http://centrmama.ru/wp-content/themes/centrmama/img/logo2.png',
            url: null,
            services: [
                'vkontakte',
                'telegram',
                'twitter',
                'whatsapp',
            ],
        },
        isTour2Egg8birdClicked: EasterEggCookies.getState(tour2Egg8birdClicked),
        tour3Egg7WindowClicked: false,
    }),
    computed: {
        ...mapState('centrmama', [
            'donations',
        ]),
        ...mapGetters('centrmama', [
            'getTargetListAvailable',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        easterTour1Egg5() {
            return this.getAvailable(tour1egg5);
        },
        easterTour2Egg8() {
            return this.getAvailable(tour2egg8);
        },
        easterTour3Egg7() {
            return this.getAvailable(tour3egg7);
        },
        sortedDonations() {
            const donations = this.donations;
            return donations
                .sort((a, b) => b.id - a.id)
                .slice(0, this.showCount);
        },
        hasMoreDonations() {
            return this.donations.length > this.showCount;
        },
        hasDonations() {
            return this.donations.length > 0;
        },
        hasTargets() {
            return this.getTargetListAvailable.length > 0;
        },
        isTour3Egg7() {
            const windowClicked = EasterEggCookies.getState(tour3Egg7WindowClicked, false);
            return !this.tour3Egg7WindowClicked && !windowClicked && this.easterTour3Egg7 && this.easterTour3Egg7.needShow;
        },
    },
    mounted() {
        this.getDonations(this.showCount);
        this.getTargets();
        this.check(tour1egg5);
        this.check(tour2egg8);
        this.check(tour3egg7);
    },
    methods: {
        ...mapActions('centrmama', [
            'getDonations',
            'getTargets',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'collect',
            'showNextStepModal',
        ]),
        scrollTo(ref) {
            const elem = this.$refs[ref];
            if (elem !== undefined) {
                const header = document.querySelector('.clnt-header');
                const yOffset = header ? header.offsetHeight : 0;
                const y = elem.getBoundingClientRect().top + window.pageYOffset - yOffset;

                if (elem) {
                    window.scrollTo({
                        top: y,
                        behavior: 'smooth',
                    });
                }
            }
        },
        collectEasterTour2Egg8() {
            this.collect(this.easterTour2Egg8)
                .then(() => EasterEggCookies.deleteState(tour2Egg8birdClicked));
        },
        tour2Egg8birdClick() {
            if (!EasterEggCookies.getState(tour2Egg8birdClicked)) {
                this.check(tour2egg8)
                    .then(() => {
                        if (this.getAvailable(tour2egg8)) {
                            EasterEggCookies.setState(tour2Egg8birdClicked, true);
                            this.isTour2Egg8birdClicked = true;
                            this.showNextStepModal();
                        }
                    });
            }
        },
        tour3Egg7Advance() {
            EasterEggCookies.setState(tour3Egg7WindowClicked, true);
            this.tour3Egg7WindowClicked = true;
            this.showNextStepModal();
        },
    },
};
</script>
