export default class AccountContact {
    constructor(
        id, fullName, phones, email, address,
    ) {
        this.id = id;
        this.fullName = fullName;
        this.phones = phones;
        this.email = email;
        this.address = address;
    }
}
