<template>
    <LeadersWrap v-show="loaded"
                 :offers="offers"
                 :current-round="currentRound"
                 :winners="winners"
                 @update="loadCurrent" />
</template>

<script>
import { mapActions } from 'vuex';
import { typesRound } from '@/services/enums/leaders';
import LeadersWrap from '@/components/Leaders/leadersWrap/index';

export default {
    name: 'LeadersGold',
    components: {
        LeadersWrap,
    },
    data() {
        return {
            offers: [],
            currentRound: null,
            winners: null,
            typesRound: typesRound,
            loaded: false,
        };
    },
    created() {
        this.loadOffers();
        this.loadCurrent();
        this.loadWinners();
    },
    methods: {
        ...mapActions('leaders', [
            'getMyOffers',
            'getCurrentRound',
            'getWinners',
        ]),
        async loadOffers() {
            try {
                this.offers = await this.getMyOffers(this.typesRound.GOLD);
            } catch (error) {
                this.offers = [];
            }
        },
        async loadCurrent() {
            try {
                this.currentRound = await this.getCurrentRound(this.typesRound.GOLD);
            } catch (error) {
                this.currentRound = null;
            }
        },
        async loadWinners() {
            try {
                this.winners = await this.getWinners(this.typesRound.GOLD);
            } catch (error) {
                this.winners = null;
            } finally {
                this.loaded = true;
            }
        },
    },
};
</script>
