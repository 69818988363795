export const typePickup = 'pickup';
export const typeToTheDoor = 'toTheDoor';
export const typePost = 'post';

const TITLES = {
    [typePickup]: 'Самовывоз',
    [typeToTheDoor]: 'Доставка до двери',
    [typePost]: 'Доставка в пункт самовывоза',
};

export class DeliveryType {
    constructor(value) {
        this.value = value;
    }

    get title() {
        return TITLES[this.value] || null;
    }

    static options() {
        const options = [];
        for (const type in TITLES) {
            options.push({
                value: type,
                title: TITLES[type],
            });
        }
        return options;
    }
}
