<template>
    <div id="app">
        <vue-extend-layouts />
        <EasterEggsWelcomeModal v-if="credential" />
        <EasterEggsEndRoundModal v-if="credential" />
        <Notifications />
    </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';
import { mapActions, mapState } from 'vuex';
import Notifications from '@/components/common/Notifications';
import EasterEggsWelcomeModal from '@/components/easterEggs/easterEggsWelcomeModal';
import EasterEggsEndRoundModal from '@/components/easterEggs/easterEggsEndRoundModal';

export default {
    name: 'App',
    components: {
        EasterEggsEndRoundModal,
        EasterEggsWelcomeModal,
        VueExtendLayouts,
        Notifications,
    },
    computed: {
        ...mapState('authorization', [
            'credential',
        ]),
    },
    created() {
        if (this.credential) {
            this.getMaintenance();
            this.loadAccountInfo();
            this.loadParticipantProgress();
            this.loadBalance();
            this.loadTasks();
        }
    },
    methods: {
        ...mapActions('account', [
            'loadAccountInfo',
            'loadBalance',
        ]),
        ...mapActions('common', [
            'getMaintenance',
        ]),
        ...mapActions('tasks', [
            'loadTasks',
        ]),
        ...mapActions('easterEggs', [
            'loadParticipantProgress',
        ]),
    },
};
</script>
