<template>
    <div class="clnt-stub"
         :class="{'clnt-stub_desktop': !mobile}">
        <div class="clnt-stub lds-stub-container">
            <div class="clnt-stub__title"
                 v-if="title">
                {{ title }}
            </div>
            <div class="clnt-stub__img-container">
                <img src="@/assets/images/stub/stub.png"
                     class="clnt-stub__img"
                     alt=""
                     v-if="img">

                <div class="clnt-stub__egg"
                     @click="collectEasterTour2Egg3"
                     v-if="!!easterTour2Egg3 && easterTour2Egg3.needShow" />
            </div>

            <div class="clnt-stub__text"
                 v-if="text"
                 v-html="text" />
            <template v-if="actions">
                <div class="clnt-stub__actions"
                     v-if="checkedVote">
                    <button class="clnt-stub__btn lds-btn lds-btn_fill"
                            :class="className('yes')"
                            @click="setVote('yes')">
                        Сделайте, пожалуйста побыстрее...
                    </button>
                    <button class="clnt-stub__btn lds-btn lds-btn_fill"
                            :class="className('neutral')"
                            @click="setVote('neutral')">
                        Ок, жду...
                    </button>
                    <button class="clnt-stub__btn lds-btn lds-btn_fill"
                            :class="className('no')"
                            @click="setVote('no')">
                        Мне это не нужно, не&nbsp;торопитесь
                    </button>
                </div>
                <div class="clnt-stub__text">
                    <template v-if="vote === 'yes'">
                        Стараемся как можем
                    </template>
                    <template v-if="vote === 'neutral'">
                        Спасибо! :)
                    </template>
                    <template v-if="vote === 'no'">
                        Спасибо! :)
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {tour2egg3} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'Stub',
    props: {
        title: {
            type: String,
            default: 'Уже совсем скоро...',
        },
        text: {
            type: String,
            default: 'Добрый день, уважаемый клиент. <br> Данный функционал в&nbsp;активной разработке.',
        },
        img: {
            type: Boolean,
            default: true,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            page: '',
            checkedVote: false,
        };
    },
    computed: {
        ...mapState('stub', [
            'vote',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        easterTour2Egg3() {
            return this.getAvailable(tour2egg3);
        },
    },
    mounted() {
        this.page = window.location.pathname.replace(/^\//, '');
        this.checkedVote = true;
        this.checkVote(this.page);

        if (this.page === 'app/statistics/webmasters') {
            this.check(tour2egg3);
        }
    },
    methods: {
        ...mapActions('stub', [
            'checkVote',
            'saveVote',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        className(value) {
            return this.vote === value ? '-blue' : '-white';
        },
        setVote(value) {
            const data = {
                'page': this.page,
                'vote': value,
            };
            this.saveVote(data);
        },
        collectEasterTour2Egg3() {
            this.collect(this.easterTour2Egg3);
        },
    },
};
</script>
