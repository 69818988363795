<template>
    <div class="lm-order-created">
        <div class="lm-order-created__header">
            <div class="lm-order-created__header-mobile">
                <div class="lm-order-created__header-item">
                    <div class="lm-order-created__header-number">
                        Заказ {{ order.id }}
                    </div>
                    <div class="lm-order-created__header-label">
                        {{ order.formattedCreated }}
                    </div>
                </div>
                <button class="lm-order-created__header-more"
                        @click="openModal()" />
            </div>
            <div class="lm-order-created__header-overlay"
                 :ref="`overlay-${order.id}`"
                 @click="closeModal()"
                 @touchstart="touchstartModal()"
                 @touchmove="touchmoveModal"
                 @touchend="touchendModal" />
            <div class="lm-order-created__header-description"
                 :ref="`modal-${order.id}`"
                 @touchstart="touchstartModal"
                 @touchmove="touchmoveModal"
                 @touchend="touchendModal">
                <div class="lm-order-created__header-description-btn"
                     @click="closeModal()" />
                <div class="lm-order-created__header-item">
                    <div class="lm-order-created__header-number">
                        Заказ {{ order.id }}
                    </div>
                    <div class="lm-order-created__header-label">
                        {{ order.formattedCreated }}
                    </div>
                </div>
                <div class="lm-order-created__header-item"
                     v-if="order.contact">
                    <div class="lm-order-created__header-label">
                        Получатель
                    </div>
                    <div class="lm-order-created__header-value">
                        {{ order.contact.fullName }}
                    </div>
                </div>
                <div class="lm-order-created__header-item"
                     v-if="order.contact">
                    <div class="lm-order-created__header-label">
                        Адрес
                    </div>
                    <div id="valueAddress"
                         class="lm-order-created__header-value">
                        {{ order.contact.address.string }}
                    </div>
                </div>
                <div class="lm-order-created__header-item"
                     v-if="order.contact">
                    <div class="lm-order-created__header-label">
                        Телефон
                    </div>
                    <a class="lm-order-created__header-value"
                       v-for="(item, index) in order.contact.phones"
                       :key="index"
                       id="valuePhones"
                       :href="`tel:${item}`">
                        {{ item }}
                    </a>
                </div>
                <div class="lm-order-created__header-item"
                     v-if="order.contact">
                    <div class="lm-order-created__header-label">
                        Почта
                    </div>
                    <a class="lm-order-created__header-value"
                       id="mailValue"
                       :href="`mailto:${order.contact.email}`">
                        {{ order.contact.email }}
                    </a>
                </div>
                <div class="lm-order-created__track lm-order-created__track_mobile"
                     v-if="order.trackNumber">
                    <span class="lm-order-created__track-title">
                        Трек-номер:
                    </span>
                    <span class="lm-order-created__track-value">
                        {{ order.trackNumber }}
                    </span>
                </div>
            </div>
        </div>
        <div class="lm-order-created__products">
            <template v-for="position in order.positions">
                <router-link
                    :key="position.id"
                    v-if="position.product.isActive"
                    :to="{
                        name: 'MarketProduct',
                        params: {
                            productId: position.product.id,
                        },
                    }"
                    class="lm-order-created__product">
                    <img
                        v-if="position.product.mainImage"
                        :src="position.product.imageSrc"
                        :alt="position.product.name">
                    <div class="lm-order-created__product-count">
                        x{{ position.count }}
                    </div>
                </router-link>
                <a v-else
                   :key="position.id"
                   title="Товар снят с продаж"
                   class="lm-order-created__product lm-order-created__product_disabled">
                    <img
                        v-if="position.product.mainImage"
                        :src="position.product.imageSrc"
                        :alt="position.product.name">
                    <div class="lm-order-created__product-count">
                        x{{ position.count }}
                    </div>
                </a>
            </template>
        </div>
        <div class="lm-order-created__footer">
            <div class="lm-order-created__total">
                <div class="lm-order-created__total-title">
                    Итого
                </div>
                <div class="lm-order-created__total-value"
                     id="positionsSumAmount">
                    {{ formatCoins(order.positionsSumAmount) }}
                </div>
            </div>
            <div class="lm-order-created__tags">
                <div class="lm-order-created__track lm-order-created__track_desktop"
                     v-if="order.trackNumber">
                    <span class="lm-order-created__track-title">
                        Трек-номер:
                    </span>
                    <span class="lm-order-created__track-value"
                          id="footerTrackNumber">
                        {{ order.trackNumber }}
                    </span>
                </div>
                <div class="lm-order-created__status"
                     :class="'lm-order-created__status_'+ order.status.class">
                    {{ order.status.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {gsap} from 'gsap';
import formattingCoins from '@/mixins/formattingCoins';
import Order from '@/models/Market/Order';

export default {
    name: 'OrderCreated',
    mixins: [
        formattingCoins,
    ],
    props: {
        order: Order,
    },
    data: () => ({
        modal: {
            drag: false,
            initialMouseY: 0,
            currentMouseY: 0,
            delta: 0,
            opacityMax: 0.2,
            screenWidth: 1024,
        },
    }),
    computed: {
        dateFormatter() {
            return new Intl.DateTimeFormat('ru');
        },
        refOverlay() {
            return this.$refs[`overlay-${this.order.id}`];
        },
        refModal() {
            return this.$refs[`modal-${this.order.id}`];
        },
    },
    mounted() {
        Vue.nextTick(() => {
            gsap.set(
                this.refModal, {
                    yPercent: 100,
                }, 0,
            );
            gsap.set(
                this.refOverlay, {
                    visibility: 'hidden',
                    opacity: 0,
                }, 0.5,
            );
        });
    },
    methods: {
        getMouseYPosition(e) {
            return e.clientY || e.touches[0].pageY;
        },
        touchstartModal(e) {
            this.modal.initialMouseY = this.getMouseYPosition(e);
        },
        touchmoveModal(e) {
            if (document.documentElement.clientWidth < this.modal.screenWidth) {
                this.modal.drag = true;
                this.modal.currentMouseY = this.getMouseYPosition(e);
                this.modal.delta = this.modal.currentMouseY - this.modal.initialMouseY;

                if (this.modal.delta > 0) {
                    const heightModal = this.refModal.offsetHeight;
                    const percent = (this.modal.delta * 100) / heightModal;
                    const opacity = this.modal.opacityMax - ((this.modal.opacityMax * percent) / 100);

                    gsap.to(
                        this.refModal, {
                            yPercent: percent,
                            duration: 0,
                        }, 0,
                    );
                    gsap.to(
                        this.refOverlay, {
                            opacity: opacity,
                            duration: 0,
                        }, 0,
                    );
                }
            }
        },
        touchendModal() {
            if (!this.modal.drag) {
                return;
            }

            this.modal.drag = false;
            if (this.modal.delta <= 70) {
                this.openModal();
            } else {
                this.closeModal();
            }
        },
        closeModal() {
            const tl = gsap.timeline();

            tl.to(
                this.refModal, {
                    yPercent: 100,
                    willChange: 'transform',
                    ease: 'expo.out',
                    duration: 0.4,
                }, 0,
            );
            tl.to(
                this.refOverlay, {
                    opacity: 0,
                    ease: 'expo.out',
                    duration: 0.4,
                }, 0,
            );
            tl.set(
                this.refOverlay, {
                    visibility: 'hidden',
                }, 0.4,
            );
            tl.set(
                'body', {
                    overflow: '',
                }, 0.4,
            );

            return tl;
        },
        openModal() {
            const tl = gsap.timeline();

            tl.set(
                'body', {
                    overflow: 'hidden',
                }, 0,
            );
            tl.set(
                this.refOverlay, {
                    visibility: 'visible',
                }, 0,
            );
            tl.to(
                this.refModal, {
                    yPercent: 0,
                    willChange: 'transform',
                    ease: 'expo.out',
                    duration: 0.5,
                }, 0,
            );
            tl.to(
                this.refOverlay, {
                    opacity: this.modal.opacityMax,
                    ease: 'expo.out',
                    duration: 0.5,
                }, 0,
            );

            return tl;
        },
    },
};
</script>
