import axios from 'axios';

const mutations = {
    setAgreement: (state, data) => {
        state.agreement = data;
    },
};
const actions = {
    getAgreementTextLC: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/account/agreement', config)
            .then(response => {
                commit('setAgreement', response.data.data);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};
const state = {
    agreement: null,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
