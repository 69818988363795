<template>
    <div class="lm-categories">
        <router-link
            class="lm-categories__btn"
            :class="{'lm-categories__btn_active': isAllCategories}"
            :to="{
                name: 'MarketCatalog',
                query: query,
            }">
            Все товары
        </router-link>
        <router-link
            class="lm-categories__btn"
            :class="categoryBtnClass(item.id)"
            v-for="item in categories"
            :key="`category_${item.id}`"
            :data-key="item.id"
            :to="{
                name: 'MarketCatalogCategories',
                params: {
                    categoryId: item.id,
                },
                query: query,
            }">
            {{ item.name }}
        </router-link>
        <router-link
            class="lm-categories__btn lm-categories__btn_charity"
            :to="{name: 'MarketCharity'}">
            Centrmama
        </router-link>
        <router-link class="lm-categories__btn lm-categories__btn_output-lc"
                     :to="{name: 'OutputLC'}">
            Вывод средств
        </router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'MarketCategories',
    computed: {
        ...mapState('market', [
            'categories',
        ]),
        selectedCategoryId() {
            return this.$route.params.categoryId;
        },
        isAllCategories() {
            return !this.selectedCategoryId;
        },
        query() {
            const query = {};
            if (this.$route.query.priceTo) {
                query.priceTo = this.$route.query.priceTo;
            }
            if (this.$route.query.priceFrom) {
                query.priceFrom = this.$route.query.priceFrom;
            }
            return query;
        },
    },
    methods: {
        categoryBtnClass(id) {
            return {
                'lm-categories__btn_active': (parseInt(id) === parseInt(this.selectedCategoryId)),
            };
        },
    },
};
</script>
