<template>
    <div class="clnt-auth">
        <form class="clnt-auth__form"
              @submit.prevent="changePassSubmit()">
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.user.password.$error}">
                    <label class="lds-control__label"
                           for="password">Пароль</label>
                    <input type="password"
                           autocomplete="current-password"
                           class="lds-input"
                           id="password"
                           v-model="user.password"
                           @change="$v.user.password.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.password.$error && $v.user.password.$model == ''">
                        Поле обязательно для заполнения.
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.user.password.$error && $v.user.password.$model !== ''">
                        Пароль должен содержать более 8 символов.
                    </div>
                    <div class="lds-control__message"
                         v-if="false">
                        Неправильный пароль.
                    </div>
                </div>
            </div>
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{
                         'lds-control_error': $v.user.passwordRepeat.$error || !passwordCheck
                     }">
                    <label class="lds-control__label"
                           for="password-check">Подтвердить пароль</label>
                    <input type="password"
                           autocomplete="current-password"
                           class="lds-input"
                           id="password-check"
                           v-model="user.passwordRepeat"
                           @change="$v.user.passwordRepeat.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.passwordRepeat.$error">
                        Поле обязательно для заполнения.
                    </div>
                    <div class="lds-control__message"
                         v-if="!passwordCheck">
                        Пароль не совпадает.
                    </div>
                </div>
            </div>
            <button type="submit"
                    class="lds-btn lds-btn_full lds-btn_fill -blue">
                Сменить пароль
            </button>
        </form>
        <div class="clnt-auth__error"
             v-if="changePassErrorMessage">
            {{ changePassErrorMessage }}
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { required, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'ChangePasswordForm',
    data() {
        return {
            user: {
                password: '',
                passwordRepeat: '',
            },
            passwordCheck: true,
            hash: '',
        };
    },
    validations: {
        user: {
            password: {
                required,
                minLength: minLength(6),
            },
            passwordRepeat: {
                required,
            },
        },
    },
    computed: {
        ...mapState('authorization', [
            'changePassErrorMessage',
        ]),
    },
    mounted() {
        this.hash = this.$route.query.hash;
    },
    methods: {
        ...mapActions('authorization', {
            changePass: 'changePass',
        }),
        changePassSubmit() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                const data = {
                    'hash': this.hash,
                    'password': this.user.password,
                    'password_repeat': this.user.passwordRepeat,
                };

                this.changePass(data).then(() => {
                    if (!this.changePassErrorMessage) {
                        this.$router.push({
                            name: 'Authorization',
                        });
                    }
                });
            }
        },
    },
};
</script>
