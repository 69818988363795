<template>
    <article class="clnt-traffics-card"
             :class="`clnt-traffics-card_${info.statusCode}`">
        <div class="clnt-traffics-card__header">
            <p class="clnt-traffics-card__status">
                {{ info.status.title }}
            </p>
            <time class="clnt-traffics-card__time">
                {{ formattingDate(info.period.dateFrom) }} - {{ formattingDate(info.period.dateTo) }}
            </time>
        </div>
        <div class="clnt-traffics-card__content">
            <h3 class="clnt-traffics-card__title">
                {{ info.type.title }}
            </h3>
            <ul v-if="info.conditions.length"
                class="clnt-traffics-card__info">
                <li v-for="(item, index) in info.conditions"
                    :key="index"
                    class="clnt-traffics-card__info-item">
                    <p class="clnt-traffics-card__info-title">
                        {{ item.title }}
                    </p>
                    <p class="clnt-traffics-card__info-value">
                        {{ formattingValue(item.value, item.type) }}
                    </p>
                </li>
            </ul>
        </div>
    </article>
</template>

<script>
import moment from 'moment';

export default {
    name: 'TrafficContractsCard',
    props: {
        info: {
            type: Object,
            required: true,
        },
    },
    methods: {
        formattingValue(value, type) {
            if (type === 'percent') {
                return parseInt(value) + '%';
            }
            return parseInt(value);
        },
        formattingDate(date) {
            return moment(date).format('DD.MM.YY');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
