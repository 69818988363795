import Vue from 'vue';
import axios from 'axios';
import NotificationService from '@/services/notificationService';

const mutations = {
    setHeading: (state, data) => {
        state.heading = data;
    },
    setTargetRout: (state, data) => {
        state.targetRout = data;
    },
    setBreadcrumbs: (state, data) => {
        state.breadcrumbs = data;
    },
    replaceBreadcrumbs: (state, data) => {
        const index = state.breadcrumbs.findIndex(item => item.id === data.id);
        if (index > 0) {
            state.breadcrumbs[index].name = data.name;
        }
    },
    setNotification: (state, data) => {
        const message = NotificationService.buildNotificationMessage(data.value);
        const dataResult = {
            text: message,
            type: data.type,
        };

        state.notificationsId++;
        const id = state.notificationsId;
        dataResult.id = id;
        state.notifications.unshift(dataResult);
        const limit = document.documentElement.clientWidth > 768 ? 3 : 2;
        state.notifications = state.notifications.slice(0, limit);

        setTimeout(() => {
            const index = state.notifications.map(item => item.id).indexOf(id);
            if (index !== -1) {
                Vue.delete(state.notifications, index);
            }
        }, 7000);
    },
    removeNotification: (state, id) => {
        const index = state.notifications.map(item => item.id).indexOf(id);
        if (index !== -1) {
            Vue.delete(state.notifications, index);
        }
    },
    setFromRout: (state, data) => {
        state.fromRout = data;
    },
    setMaintenance: (state, data) => {
        state.maintenance = data;
    },
};

const actions = {
    getMaintenance: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .post(
                '/rest/maintenance/info', '', config,
            )
            .then(response => {
                const result = response.data.enabled;
                commit('setMaintenance', result);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const state = () => ({
    heading: '',
    targetRout: '/',
    breadcrumbs: [],
    notifications: [],
    notificationsId: 0,
    fromRout: null,
    maintenance: false,
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
