import { LEAGUES_CODE } from '@/services/enumLeague';

export default class OfferCategory {
    /**
     *
     * @param id
     * @param name
     * @param rating
     * @param grades
     */

    constructor(
        id, name, rating, league, grades,
    ) {
        this.id = id;
        this.name = name;
        this.rating = rating;
        this.league = league;
        this.grades = grades;
    }

    get iconLeague() {
        return LEAGUES_CODE[this.league.value] || null;
    }
}
