<template>
    <div class="lm-header lds-container"
         v-if="!isHiddenHeader">
        <div class="lm-header__heading">
            <h1 class="lm-header__title">
                {{ heading }}
                <span class="lm-header__title-quantity"
                      v-if="isVisibilitySelectedQuantity">
                    {{ cartCount }} {{ cartCountLabel }}
                </span>
            </h1>
        </div>
        <div class="lm-header__cart"
             v-if="isVisibilityCartBtn && !productNotFound">
            <router-link class="lm-btn lm-btn_cart"
                         :to="{name: 'MarketOrder'}">
                <SvgIcon name="cart" />
                Корзина
                <span class="lm-btn_cart__quantity">
                    {{ cartCount }}
                </span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import formattingText from '@/mixins/formattingText';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'MarketHeader',
    components: {
        SvgIcon,
    },
    mixins: [
        formattingText,
    ],
    computed: {
        ...mapState('market', [
            'cart',
            'products',
        ]),
        ...mapState('common', [
            'heading',
        ]),
        cartCount() {
            return this.cart.totalCount;
        },
        cartCountLabel() {
            return this.declensionOfNum(this.cart.totalCount, ['товар', 'товара', 'товаров']);
        },
        isVisibilityCartBtn() {
            return this.$route.meta.props && this.$route.meta.props.isVisibilityCartBtn && this.cartCount;
        },
        isVisibilitySelectedQuantity() {
            return this.$route.meta.props && this.$route.meta.props.isVisibilitySelectedQuantity && this.cartCount;
        },
        isHiddenHeader() {
            return this.$route.meta.heading === false;
        },
        productNotFound() {
            const id = this.$route.params.productId;
            if (id) {
                return !this.products[id];
            }
            return false;
        },
    },
};
</script>
