<template>
    <transition name="fade">
        <div class="lds-notifications"
             v-if="isShowNotifications">
            <div class="lds-notification"
                 :class="`lds-notification_${item.type}`"
                 v-for="(item, index) in notifications"
                 :key="index">
                <div class="lds-notification__icon" />
                <div class="lds-notification__info">
                    <div class="lds-notification__heading"
                         v-if="heading(item.type)">
                        {{ heading(item.type) }}
                    </div>
                    <div class="lds-notification__text"
                         v-if="item.text"
                         v-html="item.text" />
                </div>
                <button class="lds-notification__close"
                        aria-label="Закрыть"
                        @click="removeNotification(item.id)">
                    <SvgIcon name="close" />
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'Notifications',
    components: {
        SvgIcon,
    },
    computed: {
        ...mapState('common', [
            'notifications',
        ]),
        isShowNotifications() {
            return this.notifications.length;
        },
    },
    methods: {
        ...mapMutations('common', [
            'removeNotification',
        ]),
        heading(type) {
            let heading;
            switch (type) {
                case 'success':
                    heading = 'Отличные новости';
                    break;
                case 'error':
                    heading = 'Ошибка';
                    break;
                default:
                    heading = '';
                    break;
            }
            return heading;
        },
    },
};
</script>
