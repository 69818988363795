<template>
    <div class="clnt-leaders-winner"
         v-if="info">
        <div class="clnt-leaders-winner__header">
            <h2 class="clnt-leaders-winner__title">
                Победители раунда {{ info.id }}
            </h2>
            <router-link :to="{name: 'LeadersWinners'}"
                         class="clnt-leaders-winner__link">
                Смотреть
            </router-link>
        </div>
        <table class="clnt-leaders-winner__table"
               v-if="info.winners">
            <tr v-for="(winner, index) in info.winners"
                :key="index">
                <td class="clnt-leaders-winner__table-title">
                    {{ winner.offer.title }}
                </td>
                <td>
                    <div class="clnt-leaders-winner__table-value">
                        {{ ratingChange(winner.ratingChange) }}
                    </div>
                </td>
            </tr>
        </table>
        <div class="clnt-leaders-winner__awards">
            <div class="clnt-leaders-winner__award lds-btn lds-btn_fill lds-btn_full -gray"
                 v-for="(reward, index) in info.rewards"
                 :key="index">
                {{ reward.title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeadersWinner',
    props: {
        info: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    methods: {
        ratingChange(value) {
            if (parseFloat(value) > 0) {
                return `+${value}`;
            }
            return value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
