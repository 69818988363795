<template>
    <Offer />
</template>

<script>
import Offer from '@/components/offer/Offer';

export default {
    name: 'OfferDetailed',
    components: {
        Offer,
    },
};
</script>
