<template>
    <div>
        <OfferList v-if="!detailed" />
        <router-view v-else />
    </div>
</template>

<script>
import OfferList from '@/components/offer/OfferList';

export default {
    name: 'Offers',
    components: {
        OfferList,
    },
    computed: {
        detailed() {
            return Object.keys(this.$route.params).length;
        },
    },
};
</script>
