export default class AccountAddress {
    constructor(
        country, region, city, cityAddress,
    ) {
        this.country = country;
        this.region = region;
        this.city = city;
        this.cityAddress = cityAddress;
    }

    get string() {
        const data = [
            this.country,
            this.region,
            this.city,
            this.cityAddress,
        ];

        return data.filter(item => !!item).join(', ');
    }
}
