<template>
    <div class="clnt-leaders-stat">
        <template v-if="currentRound">
            <div class="clnt-leaders-stat__header">
                <h2 class="clnt-leaders-stat__title">
                    Раунд {{ currentRound.id }}
                </h2>
                <router-link :to="{name: 'LeadersRegulations'}"
                             class="clnt-leaders-stat__link">
                    Правила
                </router-link>
            </div>
            <time class="clnt-leaders-stat__date">
                {{ currentRound.period.dateFrom.split(' ')[0] }} - {{ currentRound.period.dateTo.split(' ')[0] }}
            </time>
            <div class="clnt-leaders-stat__cols">
                <div>
                    <table v-if="currentRound.participants.length"
                           class="clnt-leaders-stat__table">
                        <tr v-for="(item, index) in currentRound.participants"
                            :key="index">
                            <td>
                                <span class="clnt-leaders-stat__table-number"
                                      :class="{'clnt-leaders-stat__table-number_border': isOfferMatched(item.offer.id)}">
                                    {{ item.place }}
                                </span>
                            </td>
                            <td class="clnt-leaders-stat__table-title">
                                {{ item.offer.title }}
                            </td>
                            <td>
                                <div class="clnt-leaders-stat__table-value">
                                    <p class="clnt-leaders-stat__table-rating">
                                        {{ item.lastRating }}
                                    </p>
                                    <p class="clnt-leaders-stat__table-dynamic">
                                        {{ ratingChange(item.ratingChange) }}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <p v-else>
                        Нет участников
                    </p>
                </div>
                <div v-if="currentRound.rewards.length"
                     class="clnt-leaders-stat__awards">
                    <p class="clnt-leaders-stat__awards-label">
                        Награда
                    </p>
                    <p v-if="awardTitle"
                       class="clnt-leaders-stat__awards-title">
                        {{ awardTitle }}
                    </p>
                    <ul class="clnt-leaders-stat__awards-list">
                        <li v-for="item in currentRound.rewards"
                            :key="item.id"
                            class="clnt-leaders-stat__awards-item">
                            {{ item.title }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <div v-else
             class="clnt-leaders-stat__empty">
            Нет активных раундов
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeadersStat',
    props: {
        currentRound: {
            type: Object,
            default() {
                return null;
            },
        },
        offers: {
            type: Array,
            default() {
                return [];
            },
        },
        awardTitle: {
            type: String,
            default: '',
        },
    },
    methods: {
        isOfferMatched(partId) {
            return this.offers.find(offer => Number(partId) === Number(offer.id));
        },
        ratingChange(value) {
            if (parseFloat(value) > 0) {
                return `+${value}`;
            }
            return value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
