export default class Offer {
    /**
     *
     * @param id
     * @param name
     * @param status
     * @param categories
     */

    constructor(
        id, name, status, categories,
    ) {
        this.id = id;
        this.name = name;
        this.status = status;
        this.categories = categories;
    }

    get key() {
        return 'offer_analysis_request';
    }

    get subject() {
        return 'Заявка на разбор оффера';
    }
}
