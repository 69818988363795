<template>
    <div class="clnt-task"
         :class="classModificator">
        <div class="clnt-task__header">
            <div class="clnt-task__top">
                <div class="clnt-task__status"
                     v-if="task.statusTitle">
                    <template v-if="task.statusName === 'run' && !task.isCheckingRequired">
                        {{ task.statusTitle }} {{ task.dueDateFormatting }}
                    </template>
                    <template v-else-if="task.statusName === 'run' && task.isCheckingRequired">
                        Осталось:
                        <CountDown :end="dateFormattingCounter" />
                    </template>
                    <template v-else>
                        {{ task.statusTitle }}
                    </template>
                </div>
                <div v-if="task.complicacy"
                     class="clnt-task__level">
                    <span class="clnt-task__level-title">
                        {{ task.complicacy }}
                    </span>
                    <span class="clnt-task__level-value"
                          :class="'clnt-task__level-value_' + task.complicacyClass">
                        <span />
                        <span />
                        <span />
                    </span>
                </div>
            </div>
            <div class="clnt-task__name">
                {{ task.name }}
            </div>
        </div>
        <div class="clnt-task__body">
            <div class="clnt-task__caption">
                Задача:
            </div>
            <div class="clnt-task__description">
                <vue-scroll :ops="scrollOptions">
                    <div class="clnt-task__text"
                         v-html="task.description" />
                </vue-scroll>
            </div>
            <template v-if="task.statusName !== 'completed' && task.statusName !== 'not-done'">
                <div class="clnt-task__caption">
                    Награда:
                </div>
                <div class="clnt-task__award">
                    <div class="clnt-task__award-item">
                        <SvgIcon name="leads-coins" />
                        {{ formatCoinsShort(task.amount) }}
                    </div>
                </div>
                <router-link
                    class="clnt-task__can-buy"
                    :to="buildUrlCanBuy">
                    <SvgIcon name="present" />
                    Что я смогу купить?
                </router-link>
            </template>
            <div class="clnt-task__action">
                <button class="lds-btn lds-btn_full lds-btn_fill -green"
                        v-if="task.statusName === 'new'"
                        @click="runEasterEggRiddle() && setStatusRun(task.id)">
                    Начать выполнение
                </button>
                <div class="clnt-timer"
                     v-if="task.statusName === 'run' && !task.isCheckingRequired">
                    <CountDown :end="dateFormattingCounter" />
                </div>
                <button class="lds-btn lds-btn_full lds-btn_fill -simple"
                        v-if="task.statusName === 'run' && task.isCheckingRequired"
                        @click="setStatusChecking(task.id)">
                    Отправить на проверку
                </button>
                <button class="lds-btn lds-btn_full lds-btn_fill -secondary"
                        v-if="task.statusName === 'done'"
                        @click="setStatusCompleted(task.id)">
                    Получить награду
                </button>
                <button class="lds-btn lds-btn_full lds-btn_border"
                        v-if="task.statusName === 'checking'"
                        disabled>
                    Получить награду
                </button>
                <div class="clnt-task__result clnt-task__result_not-done"
                     v-if="task.statusName === 'not-done'">
                    Не выполнено до {{ task.dueDateFormatting }}
                </div>
                <div class="clnt-task__result clnt-task__result_completed"
                     v-if="task.statusName === 'completed'">
                    Выполнено
                </div>
                <div class="clnt-task__result clnt-task__result_teaser"
                     v-if="task.statusName === 'teaser'">
                    Скоро будет
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CountDown from 'vuejs-countdown';
import moment from 'moment';
import vuescroll from 'vuescroll/dist/vuescroll-native';
import SvgIcon from '@/components/common/SvgIcon';
import formattingCoins from '@/mixins/formattingCoins';

import 'vuescroll/dist/vuescroll.css';
import {tour2egg6} from '@/models/EasterEggs/EasterEgg';
import EasterEggCookies, {tour2Egg6RiddleSolved} from '@/models/EasterEggs/EasterEggCookies';

Vue.use(vuescroll);

export default {
    name: 'Task',
    components: {
        SvgIcon,
        CountDown,
    },
    mixins: [
        formattingCoins,
    ],
    props: {
        task: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            scrollOptions: {
                rail: {
                    background: '#F5F7F9',
                    size: '7px',
                    opacity: 1,
                },
                bar: {
                    background: '#C5C7D0',
                    size: '7px',
                    keepShow: true,
                    minSize: 0.2,
                },
            },
        };
    },
    computed: {
        dateFormattingCounter() {
            return moment(this.task.dueDate).format('MM.DD.YYYY');
        },
        classModificator() {
            let className = this.task.statusName;
            const statusCode = this.task.statusCode;
            if ((statusCode === 2 || statusCode === '2') && this.task.isCheckingRequired) {
                className = 'run-checking';
            }
            return `clnt-task_${className}`;
        },
        buildUrlCanBuy() {
            const amount = parseInt(this.task.amount).toFixed(0).slice(0, -3);
            return {
                name: 'MarketCatalog',
                query: {
                    priceTo: amount,
                },
            };
        },
    },
    methods: {
        runEasterEggRiddle() {
            const eggAvailable = this.getAvailable(tour2egg6);
            const riddleSolved = EasterEggCookies.getState(tour2Egg6RiddleSolved);
            if (eggAvailable && eggAvailable.needShow && !riddleSolved) {
                this.setTour2egg6RiddleModal({show: true});
                return false;
            }
            return true;
        },
        ...mapActions('tasks', [
            'setStatusRun',
            'setStatusCompleted',
            'setStatusChecking',
        ]),
        ...mapGetters('easterEggs', ['getAvailable']),
        ...mapMutations('easterEggs', ['setTour2egg6RiddleModal']),
    },
};
</script>
