import { render, staticRenderFns } from "./tour3egg6.vue?vue&type=template&id=8101bedc&scoped=true&"
import script from "./tour3egg6.vue?vue&type=script&lang=js&"
export * from "./tour3egg6.vue?vue&type=script&lang=js&"
import style0 from "./tour3egg6.vue?vue&type=style&index=0&id=8101bedc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8101bedc",
  null
  
)

export default component.exports