<template>
    <div>
        <router-view v-if="detailed" />
        <NotFound v-else />
    </div>
</template>

<script>
import NotFound from '@/components/common/NotFound.vue';

export default {
    name: 'TasksWrap',
    components: {
        NotFound,
    },
    computed: {
        detailed() {
            return this.$route.name !== 'TasksWrap';
        },
    },
};
</script>
