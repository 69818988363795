<template>
    <div class="lds-not-found">
        <div class="lds-container">
            <h1 class="lds-not-found__title">
                404
            </h1>
            <p class="lds-not-found__text">
                Старница не найдена
            </p>
            <router-link class="lds-btn lds-btn_fill -blue"
                         to="/">
                На главную
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>
