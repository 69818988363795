<template>
    <AuthorizationForm />
</template>

<script>
import AuthorizationForm from '@/components/auth/AuthorizationForm.vue';

export default {
    name: 'Authorization',
    components: {
        AuthorizationForm,
    },
};
</script>
