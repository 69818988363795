<template>
    <RestorePasswordForm />
</template>

<script>
import RestorePasswordForm from '@/components/auth/RestorePasswordForm.vue';

export default {
    name: 'RestorePassword',
    components: {
        RestorePasswordForm,
    },
};
</script>
