<template>
    <div>
        <TasksList v-if="!maintenance" />
        <Stub v-else
              class="clnt-stub_sm"
              title="Уважаемые партнеры!"
              :text="textMaintenance"
              :actions="false" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import TasksList from '@/components/tasks/TasksList';
import Stub from '@/components/common/Stub';
import settingsMaintenance from '@/mixins/settingsMaintenance';

export default {
    name: 'Tasks',
    components: {
        TasksList,
        Stub,
    },
    mixins: [
        settingsMaintenance,
    ],
    computed: {
        ...mapState('common', [
            'maintenance',
        ]),
    },
};
</script>
