const captchaWrapper = {
    id: null,
    captcha: null,
    /**
     * После прохождения капчи нужно выполнить какое-то действие: сабмит формы, откравка ajax-запроса.
     * Делаем это в виде callback'а. Если капча отключена, не проверяем капчу. Не дев-окружении
     * разработчика пропустит как есть, на проде бэк будет ругаться на отключенную капчу.
     */
    execute() {
        return new Promise(resolve => {
            if (!this.captcha) {
                throw new Error('window.smartCaptcha is not defined');
            }
            const unsubscribe = this.captcha.subscribe(
                this.id, 'success', token => {
                    // После каждого подтверждения токен становится невалидным. Сбрасываем его, чтобы следующее действие подтвердить
                    // новым токеном.
                    this.captcha.reset();
                    // Отписываемся, чтобы, например, при входе в ЛКВ не отправлять новый запрос на смс.
                    unsubscribe();
                    resolve(token);
                },
            );
            this.captcha.execute();
        });
    },
    policy: '<a href="https://yandex.ru/legal/smartcaptcha_notice/" target="_blank">Политика обработки данных Yandex Smart Captcha</a>',
};
export default {
    install(Vue, clientKey) {
        // https://yandex.cloud/ru/docs/smartcaptcha/concepts/widget-methods
        // Собрал все скрипты и контейнеры в одном месте, чтоб не искать по проекту.
        const script = document.createElement('script');
        script.src = 'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onYaCaptchaLoad';
        document.head.appendChild(script);

        const container = document.createElement('div');
        container.id = 'yaCapthaContainer';
        document.body.append(container);

        window.onYaCaptchaLoad = () => {
            if (!window.smartCaptcha) {
                console.error('Yandex Smart Captcha is not installed');
                return;
            }
            captchaWrapper.captcha = window.smartCaptcha;
            captchaWrapper.id = window.smartCaptcha.render(container, {
                sitekey: clientKey,
                hl: 'ru',
                invisible: true,
                // Яндекс требует показывать политику их обработки данных, но дефолтный shield уродливый.
                // Поэтому отображаем ее самостоятельно в футере.
                hideShield: true,
            });
        };
        Vue.prototype.$yaCaptcha = captchaWrapper;
    },
};
