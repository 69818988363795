import axios from 'axios';
import Vue from 'vue';

const mutations = {
    setSentPromotion: (state, data) => {
        Vue.set(
            state.sentPromotion, data.key, data,
        );
    },
};

const getters = {
    isDisabledBtn: state => key => state.sentPromotion[key],
    isVisiblePopover: state => key => {
        const current = state.sentPromotion[key];
        if (current) {
            return current.visible;
        }
        return false;
    },
};

const actions = {
    featureRequest: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        const dataPost = {
            feature: data.feature,
        };
        return axios
            .post(
                '/rest/premium/premium/featureRequest', dataPost, config,
            ).then(() => {
                const dataSuccess = {
                    key: data.key,
                    sent: true,
                    visible: true,
                };
                commit('setSentPromotion', dataSuccess);
                setTimeout(() => {
                    dataSuccess.visible = false;
                    commit('setSentPromotion', dataSuccess);
                }, 3000);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const state = () => ({
    sentPromotion: {},
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
