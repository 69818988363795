<template>
    <div class="lds-container">
        <Loader v-if="loading" />
        <template v-else>
            <div class="clnt-empty"
                 v-if="isEmptyOfferList">
                Офферов нет
            </div>
            <div class="clnt-offers"
                 v-else>
                <div class="clnt-offers__grid">
                    <router-link :to="{
                                     name: 'Offer',
                                     params: {
                                         offerID: item.id,
                                     },
                                 }"
                                 v-for="(item, index) in offerList"
                                 :key="index"
                                 class="clnt-offers__item"
                                 :class="`clnt-offers__item_${item.status.class}`">
                        <div class="clnt-offers__status">
                            {{ item.status.title }}
                        </div>
                        <div class="clnt-offers__id">
                            ID: {{ item.id }}
                        </div>
                        <div class="clnt-offers__name">
                            {{ item.name }}
                        </div>
                        <div class="clnt-offers__categories">
                            <div class="clnt-offers__category"
                                 v-for="(itemCategory, indexCategory) in item.categories"
                                 :key="indexCategory">
                                <div class="clnt-offers__category-name">
                                    {{ itemCategory.name }}
                                </div>
                                <div class="clnt-offers__category-rating">
                                    <div class="clnt-offers__category-rating-text">
                                        {{ itemCategory.rating || 0 }}
                                    </div>
                                    <League :league="itemCategory.league.value"
                                            class="lds-popover_right-center" />
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader';
import League from '@/components/common/League';

export default {
    name: 'OfferList',
    components: {
        League,
        Loader,
    },
    computed: {
        ...mapState('offers', [
            'offerList',
            'loading',
        ]),
        ...mapGetters('offers', [
            'isEmptyOfferList',
        ]),
    },
    mounted() {
        this.loadOffers();
    },
    methods: {
        ...mapActions('offers', [
            'loadOffers',
        ]),
    },
};
</script>
