<template>
    <div>
        <easter-eggs-progress-line v-if="!detailed && challengeAvailable" />
        <NotFound v-else-if="!challengeAvailable" />
        <router-view v-else />
    </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';
import EasterEggsProgressLine from '@/components/easterEggs/easterEggsProgressLine/index';
import NotFound from '@/components/common/NotFound';

export default {
    name: 'EasterEggs',
    components: {
        NotFound,
        EasterEggsProgressLine,
    },
    computed: {
        ...mapState('easterEggs', [
            'challengeAvailable',
        ]),
        ...mapGetters('easterEggs', [
            'isWelcomeState',
        ]),
        detailed() {
            return Object.keys(this.$route.params).length;
        },
    },
    created() {
        this.loadParticipantProgress()
            .then(() => {
                if (this.isWelcomeState && (this.$router.currentRoute.path !== '/')) {
                    this.$router.push('/');
                    this.showWelcomeModal();
                }
            });
    },
    methods: {
        ...mapActions('easterEggs', [
            'loadParticipantProgress',
            'showWelcomeModal',
        ]),
    },
};
</script>
