<template>
    <NotFound />
</template>

<script>
import NotFound from '@/components/common/NotFound.vue';

export default {
    name: '404',
    components: {
        NotFound,
    },
};
</script>
