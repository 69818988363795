export const TYPE_STAT_OFFERS = 'stat-offers';
export const TYPE_EASTER_EGGS = 'EasterEggsChallenge';
export const TYPE_STAT_WEBMASTER = 'stat-webmaster';
export const TYPE_STAT_DYNAMIC = 'stat-dynamic';
export const TYPE_TASKS = 'tasks';
export const TYPE_LEADS_MARKET = 'market';
export const TYPE_PROMOTION = 'promotions';
export const TYPE_OTHER = 'other';

const TITLES = {
    [TYPE_EASTER_EGGS]: 'Tour de Leads',
    [TYPE_STAT_OFFERS]: 'Статитстика по офферам',
    [TYPE_STAT_WEBMASTER]: 'Показатели по вебмастерам',
    [TYPE_STAT_DYNAMIC]: 'Динамика показателей',
    [TYPE_TASKS]: 'Задания',
    [TYPE_LEADS_MARKET]: 'Leads.Market',
    [TYPE_PROMOTION]: 'Инструменты продвижения',
    [TYPE_OTHER]: 'Прочее',
};

export class FeedbackThemeList {
    constructor(value) {
        this.value = value;
    }

    static getTitle(value) {
        return TITLES[value] || null;
    }

    static options() {
        const options = [];
        for (const type in TITLES) {
            options.push({
                value: type,
                label: TITLES[type],
            });
        }
        return options;
    }
}
