import Credential from '@/models/Auth/Credential';

const cookieName = 'credentials';
const cookiePath = '/';

class Auth {
    saveCredentials(credential) {
        const cookieValue = credential.toJson();
        document.cookie = `${cookieName}=${cookieValue}; path=${cookiePath}`;
    }

    getCredentials() {
        const cookieNamePattern = `${cookieName}=`;
        const cookieList = document.cookie.split(';');

        for (let i = 0; i < cookieList.length; i++) {
            let item = cookieList[i];

            while (item.charAt(0) === ' ') {
                item = item.substring(1, item.length);
            }

            if (item.indexOf(cookieNamePattern) === 0) {
                const data = JSON.parse(item.substring(cookieNamePattern.length, item.length));
                return new Credential(
                    data.token, data.expire, data.refreshToken,
                );
            }
        }

        return null;
    }

    removeCredentials() {
        const date = new Date(0).toUTCString();
        document.cookie = `${cookieName}=; path=${cookiePath}; expires=${date}`;
    }

    isAuthenticated() {
        const credential = this.getCredentials();

        if (credential === null) {
            return false;
        }

        const expire = new Date(Date.parse(credential.expire));
        const current = new Date();

        return expire > current;
    }
}

export default new Auth();
