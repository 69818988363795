<template>
    <Stub />
</template>

<script>
import Stub from '@/components/common/Stub';

export default {
    name: 'RegulationsPage',
    components: {
        Stub,
    },
};
</script>
