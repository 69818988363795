<template>
    <AccrualHistory />
</template>

<script>
import AccrualHistory from '@/components/market/AccrualHistory';

export default {
    name: 'AccrualHistoryPage',
    components: {
        AccrualHistory,
    },
};
</script>
