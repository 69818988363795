export default class CartPosition {
    constructor(
        product, count, salePrice, oldSalePrice,
    ) {
        this.product = product;
        this.count = count;
        this.salePrice = salePrice;
        this.oldSalePrice = oldSalePrice;
    }

    get sumOldPrice() {
        if (this.oldSalePrice) {
            return parseFloat(this.oldSalePrice) * this.count;
        }
        return this.sumPrice;
    }

    get discount() {
        if (!this.oldSalePrice) {
            return 0;
        }
        return parseFloat(this.oldSalePrice) - parseFloat(this.salePrice);
    }

    get sumDiscount() {
        return this.discount * this.count;
    }

    get sumPrice() {
        return parseFloat(this.salePrice) * this.count;
    }

    increment() {
        this.count++;
    }

    decrement() {
        if (this.count > 1) {
            this.count--;
        }
    }

    changeCount(value) {
        this.count = value;
    }
}
