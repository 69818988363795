<template>
    <div class="lds-container">
        <div class="clnt-liga-rating">
            <h1 class="clnt-liga-rating__header">
                Общие положения
            </h1>
            <p class="clnt-liga-rating__description">
                Олимпиада Офферов - система оценки офферов по трем Лигам(Золото, Серебро, Бронза) исходя из рейтинга.
            </p>
            <p class="clnt-liga-rating__description">
                Чем выше рейтинг оффера, тем выше Лига.
            </p>
            <p class="clnt-liga-rating__description">
                Чем выше Лига, тем больше трафика получают офферы.
            </p>
            <h2 class="clnt-liga-rating__header">
                Механика Рейтинга
            </h2>
            <p class="clnt-liga-rating__description">
                Рейтинг оффера состоит из нескольких показателей(в зависимости от категории Оффера).
            </p>
            <p class="clnt-liga-rating__description">
                Для увеличения Рейтинга, необходимо улучшать следующие показатели оффера:
            </p>
            <ul>
                <li class="clnt-liga-rating__description-list-top">
                    Актуальность статистики (для всех) - чем чаще обновляется статистика, тем оперативнее Вебмастеры понимают эффективность их площадки и улучшают их. Редко обновляющаяся статистика увеличивает риски и уменьшает объем трафика на оффер.
                </li>
                <li class="clnt-liga-rating__description-list-top">
                    EPC (кроме Потребительских кредитов, кредитных карт и вкладов) - показатель среднего дохода от клика. Рассчитывается он как общий объем дохода Вебмастера деленный на количество кликов от него. Повышению EPC способствуют следующие меры:
                    Повысить выплату для вебмастеров;
                    Повысить уровень одобрения конверсий(Approval rate или AR);
                </li>
                <li class="clnt-liga-rating__description-list-top">
                    Платежный рейтинг (для всех) - показатель, который помогает понять точность и своевременность прихода выплат Вебмастеру. Высокий Платежный рейтинг это возможность для LEADS привлечь топ-вебмастеров на оффер.
                </li>
                <li class="clnt-liga-rating__description-list-top">
                    HOLD (для всех) - это время, за которое о
                    добренная конверсия должна быть проверена, а заработок по ней - доступен Вебмастеру (по-умолчанию HOLD составляет  14 дней, у лучших офферов HOLD -  3 дня). Чем выше HOLD - тем дольше Вебмастер получает заработок. Поэтому, чаще всего Вебмастеры выбирают офферы с невысоким HOLD.
                </li>
                <li class="clnt-liga-rating__description-list-top">
                    Репутация/Надежность (для всех) - Вебмастеры отдают предпочтение тем офферам, в которых уверены. Наибольшее доверие вызывают офферы которые не имеют неожиданных остановок и сбоев. Высокий уровень надежности могут обеспечить компенсации в случае остановок или максимальное устранение остановок и сбоев.
                </li>
                <li class="clnt-liga-rating__description-list-top">
                    Участие в акциях (для всех) - Участие в различных акциях(Endorfin, Акция по SubID и т.д.) позволяет увеличить доверие к офферу со стороны Вебмастеров, а также дает
                    <span class="clnt-liga-rating__easter-egg-text"
                          v-if="!!currentEasterEgg && currentEasterEgg.needShow"
                          @click="collect(currentEasterEgg)">
                        дополнительную мотивацию
                    </span>
                    <span v-else>
                        дополнительную мотивацию
                    </span>
                    для перевода трафика на оффер.
                </li>
            </ul>

            <h2 class="clnt-liga-rating__header">
                Зачем повышать лиги?
            </h2>
            <p  class="clnt-liga-rating__description">
                По итогам расчетов и анализа, было определено что переход в более высокую лигу может дать кратное увеличение объема трафика.
                Пример изменения объемов трафика на один оффер в категории
            </p>
            <h2 class="clnt-liga-rating__header">
                Микрозаймы:
            </h2>
            <p class="clnt-liga-rating__description">
                Всего конверсий
            </p>
            <ul class="clnt-liga-rating__list">
                <li class="clnt-liga-rating__description">
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="league-bronze" />
                    &nbsp;<p>Бронзовая  - 25805 конв.</p>
                </li>
                <li class="clnt-liga-rating__description">
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="league-silver" />
                    &nbsp;<p>Серебряная  - 132117 конв. -  в 5 раз больше</p>
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="fire" />
                </li>
                <li class="clnt-liga-rating__description">
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="league-gold" />
                    &nbsp;<p>Золотая  - 449006 конв. -  в 17 раз больше</p>
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="fire" />
                </li>
            </ul>
            <p class="clnt-liga-rating__description">
                Одобренных конверсий
            </p>
            <ul class="clnt-liga-rating__list">
                <li class="clnt-liga-rating__description">
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="league-bronze" />
                    &nbsp;<p>Бронзовая  - 6051 конв.</p>
                </li>
                <li class="clnt-liga-rating__description">
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="league-silver" />
                    <p>&nbsp;Серебряная  - 24565 конв. -  в 4 раза больше</p>
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="fire" />
                </li>
                <li class="clnt-liga-rating__description">
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="league-gold" />
                    &nbsp;<p>Золотая  - 89829 конв. -  в 15 раз больше</p>
                    <SvgIcon class="clnt-liga-rating__icon"
                             name="fire" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {
    mapActions, mapGetters,
} from 'vuex';
import SvgIcon from '@/components/common/SvgIcon';
import {
    tour1egg2,
} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'LeagueRatingDescription',
    components: {
        SvgIcon,
    },
    computed: {
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        currentEasterEgg() {
            return this.getAvailable(tour1egg2);
        },
    },
    created() {
        this.check(tour1egg2);
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
    },
};
</script>

<style scoped>

</style>
