export default {
    data() {
        return {
            textMaintenance: `<p>Некоторые блоки кабинета находятся в режиме технического обслуживания.<br>
                  Показ статистики, рейтинга, лиг и Telegram-бот работают в штатном режиме.<br>
                  Leads.Market и задания будут доступны для вас 15 декабря.</p>
                  <p>По всем вопросам вы можете обратиться к личному менеджеру.<br>
                  Спасибо что остаетесь с нами<br>
                  Вместе мы становимся лучше!</p>`,
        };
    },
};
