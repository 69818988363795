<template>
    <form class="clnt-summary__sort"
          @submit.prevent="submitStatistic()">
        <div class="clnt-datepicker clnt-datepicker_sm">
            <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_from"
                         v-model="dateFrom"
                         format="DD.MM.YYYY"
                         :disabled-date="disabledRangeFrom"
                         :lang="lang" />
            <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_to"
                         v-model="dateTo"
                         format="DD.MM.YYYY"
                         :disabled-date="disabledRangeTo"
                         :lang="lang" />
        </div>
        <div class="clnt-summary__submit">
            <button v-if="!loading"
                    class="lds-btn lds-btn_border lds-btn_size-xs"
                    :disabled="disabledSubmit"
                    type="submit">
                Сформировать
            </button>
            <div v-else
                 class="lds-btn lds-btn_border lds-btn_size-xs lds-btn_loader">
                <SvgIcon name="loader" />
                Формируется
            </div>
        </div>
    </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import SvgIcon from '@/components/common/SvgIcon';
import settingsDatePickerLang from '@/services/settingsDatePickerLang';
import getLastWeek from '@/services/getLastWeek';

export default {
    name: 'Summary',
    components: {
        DatePicker,
        SvgIcon,
    },
    data() {
        return {
            dateFrom: getLastWeek.getLastMonday(),
            dateTo: getLastWeek.getLastSunday(),
            lang: settingsDatePickerLang,
        };
    },
    computed: {
        ...mapState('summary', [
            'loading',
        ]),
        disabledSubmit() {
            return !this.dateFrom || !this.dateTo;
        },
    },
    created() {
        const data = {
            dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
        };
        this.getSummary(data);
    },
    methods: {
        ...mapActions('summary', [
            'getSummary',
        ]),
        disabledRangeFrom(date) {
            const dateMax = this.dateTo || new Date();
            return date > dateMax;
        },
        disabledRangeTo(date) {
            return date > new Date() || date < this.dateFrom;
        },
        submitStatistic() {
            const data = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
            };
            this.getSummary(data);
        },
    },
};
</script>
