export default class AccrualHistoryItem {
    constructor(
        id, approvedPointsChange, comment, created, operationType, orderedPointsChange,
    ) {
        this.id = id;
        this.approvedPointsChange = approvedPointsChange;
        this.comment = comment;
        this.created = created;
        this.operationType = operationType;
        this.orderedPointsChange = orderedPointsChange;
    }
}
