<template>
    <div class="clnt-tasks-preview"
         v-if="tasks.length">
        <div class="clnt-tasks-preview__grid">
            <div class="clnt-tasks-preview__item"
                 v-for="item in tasks"
                 :key="item.id">
                <Task :task="item"
                      class="clnt-task_sm" />
            </div>
        </div>
    </div>
</template>

<script>
import Task from '@/components/tasks/Task';

export default {
    name: 'TasksPreview',
    components: {
        Task,
    },
    props: {
        tasks: {
            type: Array,
            required: true,
        },
    },
};
</script>
