<template>
    <div class="clnt-connect">
        <div class="clnt-connect__content">
            <div class="clnt-connect__logo">
                <img src="@/assets/images/logo-leads.svg"
                     alt="LEADS">
            </div>

            <omni-code-connection-button
                @getCode="getCode"
                :account-info="accountInfo"
                :code="code" />

            <omni-code-connection-page
                @getCode="getCode"
                :code="code"
                :code-copied="codeCopied" />
            <omni-error-message />
            <router-link class="clnt-connect__link"
                         to="/">
                <SvgIcon name="arrow-left" />
                Вернуться на главную
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SvgIcon from '@/components/common/SvgIcon';
import OmniCodeConnectionButton from '@/components/omni/OmniCodeConnectionButton';
import OmniCodeConnectionPage from '@/components/omni/OmniCodeConnectionPage';
import OmniErrorMessage from '@/components/omni/OmniErrorMessage';

export default {
    name: 'OmniCode',
    components: {
        OmniErrorMessage,
        OmniCodeConnectionPage,
        SvgIcon,
        OmniCodeConnectionButton,
    },
    props: {
        codeCopied: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            omniId: '',
        };
    },
    computed: {
        ...mapState('omni', [
            'code',
        ]),
        ...mapState('account', [
            'accountInfo',
        ]),
    },
    mounted() {
        this.omniId = this.$route.query.id;
    },
    methods: {
        ...mapActions('omni', [
            'connectAccount',
        ]),
        getCode() {
            const data = {
                'omniId': this.omniId,
            };
            this.connectAccount(data).then(() => {
                this.$children[1].$refs.timerForCode.start();
            });
        },
    },
};
</script>
