export const statusActive = 1;
export const statusCompleted = 2;

const CODE = {
    [statusActive]: 'active',
    [statusCompleted]: 'completed',
};

export default class TrafficContracts {
    /**
     *
     * @param id
     * @param offer
     * @param conditions
     * @param period
     * @param status
     * @param type
     */

    constructor(
        id, offer, conditions, period, status, type,
    ) {
        this.id = id;
        this.offer = offer;
        this.conditions = conditions;
        this.period = period;
        this.status = status;
        this.type = type;
    }

    get statusCode() {
        return CODE[this.status.value] || null;
    }
}
