<template>
    <div class="clnt-banner-tasks">
        <img src="@/assets/images/banners/banner-task.png"
             alt="img">
        <div v-if="easterEgg && easterEgg.needShow"
             class="clnt-banner-tasks__tour3-egg3"
             @click="collectEasterEgg" />
        <router-link :to="{name: 'MarketCatalog'}"
                     class="clnt-banner-tasks__btn-block clnt-banner-tasks__btn clnt-banner-tasks__btn_fill -red">
            Узнать подробнее
        </router-link>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {tour3egg3} from '@/models/EasterEggs/EasterEgg.js';

export default {
    name: 'BannerTasks',
    computed: {
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        easterEgg() {
            return this.getAvailable(tour3egg3);
        },
    },
    created() {
        this.check(tour3egg3);
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        collectEasterEgg() {
            this.collect(this.easterEgg);
        },
    },
};
</script>

<style scoped>

</style>
