import axios from 'axios';

const mutations = {
    setVote: (state, data) => {
        state.vote = data;
    },
};

const actions = {
    checkVote: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/stubs/poll?page=${data}`, config)
            .then(response => {
                const result = response.data;
                commit('setVote', result.vote);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    saveVote: ({ commit, rootState }, data) => {
        commit('setVote', data.vote);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .post(
                '/rest/stubs/vote', data, config,
            )
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const state = () => ({
    vote: '',
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
