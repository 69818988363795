const seconds = 60;
const minutes = 60;
const hours = 24;
const days = 7;
const milliseconds = 1000;
const weekMilliseconds = seconds * minutes * hours * days * milliseconds;
const beforeOneWeek = new Date(new Date().getTime() - weekMilliseconds);
const daysWeek = 6;

export default class getLastWeek {
    static diffToMonday() {
        const day = beforeOneWeek.getDay();
        return beforeOneWeek.getDate() - day + (day === 0 ? -daysWeek : 1);
    }

    static getLastMonday() {
        return new Date(beforeOneWeek.setDate(this.diffToMonday()));
    }

    static getLastSunday() {
        return new Date(beforeOneWeek.setDate(this.diffToMonday() + daysWeek));
    }
}
