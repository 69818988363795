const LEAGUE_UNKNOWN = '0';
const LEAGUE_GOLD = '1';
const LEAGUE_SILVER = '2';
const LEAGUE_BRONZE = '3';

export const LEAGUES_CODE = {
    [LEAGUE_UNKNOWN]: 'unknown',
    [LEAGUE_GOLD]: 'gold',
    [LEAGUE_SILVER]: 'silver',
    [LEAGUE_BRONZE]: 'bronze',
};

export const LEAGUES_TITLE = {
    [LEAGUE_UNKNOWN]: '',
    [LEAGUE_GOLD]: 'Золотая лига',
    [LEAGUE_SILVER]: 'Серебряная лига',
    [LEAGUE_BRONZE]: 'Бронзовая лига',
};
