<template>
    <div class="clnt-leaders-page lds-container">
        <template v-if="detailed">
            <div class="clnt-tabs">
                <router-link :to="{name: 'LeadersGold'}"
                             class="clnt-tabs__item">
                    Золотая лига
                </router-link>
                <router-link :to="{name: 'LeadersCommon'}"
                             class="clnt-tabs__item">
                    Общий зачет
                </router-link>
            </div>
            <router-view />
        </template>
        <NotFound v-else />
    </div>
</template>

<script>
import NotFound from '@/components/common/NotFound.vue';

export default {
    name: 'Leaders',
    components: {
        NotFound,
    },
    computed: {
        detailed() {
            return this.$route.name !== 'Leaders';
        },
    },
};
</script>

<style lang="scss" scoped>
.clnt-leaders-page {
    margin-top: 32px;
    margin-bottom: 64px;

    @media (min-width: $screen-sm) {
        margin-top: 24px;
    }
}
</style>
