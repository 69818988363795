<template>
    <div class="clnt-centrmama-goals">
        <div class="clnt-centrmama-goals__grid">
            <div class="clnt-centrmama-goals__item"
                 v-for="(item, index) in list"
                 :key="index">
                <img v-if="item.coverImagePath"
                     :src="item.imageSrc"
                     class="clnt-centrmama-goals__img"
                     :alt="item.title"
                     @click="openModalTargetCurrent(item)">
                <img v-else
                     src="@/assets/images/charity/default.png"
                     class="clnt-centrmama-goals__img"
                     alt=""
                     @click="openModalTargetCurrent(item)">
                <div class="clnt-centrmama-goals__body">
                    <div class="clnt-centrmama-goals__top">
                        <h3 class="clnt-centrmama-goals__title"
                            @click="openModalTargetCurrent(item)">
                            {{ item.title }}
                        </h3>
                        <div class="clnt-centrmama-goals__progress">
                            <progress :max="item.amount"
                                      :value="item.progress" />
                        </div>
                        <div class="clnt-centrmama-goals__amount-block">
                            <div class="clnt-centrmama-goals__progress-amount">
                                {{ item.progressFormatted }}
                            </div>
                            <div class="clnt-centrmama-goals__amount">
                                из {{ item.amountFormatted }}
                            </div>
                        </div>
                        <router-link v-if="item.hasReport"
                                     :to="{
                                         name: 'MarketCharityReport',
                                         params: { reportId: item.id },
                                     }"
                                     class="clnt-centrmama-goals__report">
                            Отчет готов
                        </router-link>
                    </div>
                    <button v-if="item.statusCode === 'progress'"
                            class="clnt-centrmama-goals__button lds-btn lds-btn_fill lds-btn_full -green"
                            @click="openModalTargetCurrent(item)">
                        Помочь
                    </button>
                    <button v-if="item.statusCode === 'completed'"
                            class="clnt-centrmama-goals__button lds-btn lds-btn_fill lds-btn_full -green"
                            disabled>
                        Сбор завершен
                    </button>
                </div>
            </div>
        </div>
        <v-easy-dialog v-model="visibleModalTargetCurrent">
            <div>
                <CentrmamaModalTarget :info="targetCurrent"
                                      @close="closeModalTargetCurrent()" />
            </div>
        </v-easy-dialog>
    </div>
</template>

<script>
import VEasyDialog from 'v-easy-dialog';
import CentrmamaModalTarget from '@/components/centrmama/modalTarget';
import formattingCoins from '@/mixins/formattingCoins';

export default {
    name: 'Targets',
    components: {
        CentrmamaModalTarget,
        VEasyDialog,
    },
    mixins: [
        formattingCoins,
    ],
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            visibleModalTargetCurrent: false,
            targetCurrent: {},
        };
    },
    methods: {
        openModalTargetCurrent(data) {
            this.targetCurrent = data;
            this.visibleModalTargetCurrent = true;
        },
        closeModalTargetCurrent() {
            this.visibleModalTargetCurrent = false;
        },
    },
};
</script>
<style lang="scss">
@import 'index.scss';
</style>
