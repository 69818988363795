<template>
    <section class="clnt-article">
        <div class="clnt-article__grid">
            <div class="clnt-article__info">
                <h1 class="clnt-article__h1 lds-h1">
                    Размещение в Leads.Market
                </h1>
                <p>
                    Вы можете разместить свои брендированные товары в нашем специальном магазине Leads.Market.
                </p>
                <p>
                    Что вы получаете?
                </p>
                <ul>
                    <li>Бесплатное размещение бренда на площадке, где работают Вебмастеры</li>
                    <li>Около 2300 показов в месяц!</li>
                    <li>Внимание релевантной аудитории - в Leads.Market покупают успешные профессиональные вебмастеры</li>
                    <li>Потенциальную прибыль от продажи через новый канал</li>
                </ul>
                <p>Обгоните конкурентов, оставьте заявку на канале прямо сейчас!</p>
                <div class="clnt-article__action">
                    <div class="lds-popover lds-popover_size-sm"
                         :class="{'lds-popover_active': isVisiblePopover(key)}">
                        <button class="lds-btn lds-btn_fill -blue"
                                @click="sendRequest"
                                :disabled="isDisabledBtn(key)">
                            Отправить заявку
                        </button>
                        <div class="lds-popover__info">
                            Спасибо. Заявка принята. В ближайшее время с Вами свяжется наш менеджер.
                        </div>
                    </div>
                </div>
            </div>
            <div class="clnt-article__img">
                <img class="clnt-article__img-size"
                     src="@/assets/images/promotion/deployment.png"
                     alt="Размещение в Leads.Market">
                <img class="clnt-article__img-size"
                     src="@/assets/images/promotion/deployment_1.png"
                     alt="Размещение в Leads.Market">
            </div>
        </div>
    </section>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    name: 'Deployment',
    data() {
        return {
            key: 'deployment',
        };
    },
    computed: {
        ...mapState('promotion', [
            'sentPromotion',
        ]),
        ...mapGetters('promotion', [
            'isDisabledBtn',
            'isVisiblePopover',
        ]),
    },
    methods: {
        ...mapActions('promotion', [
            'featureRequest',
        ]),
        sendRequest() {
            const data = {
                key: this.key,
                feature: 'Размещение в Leads.Market',
            };
            this.featureRequest(data);
        },
    },
};
</script>

<style scoped>

</style>
