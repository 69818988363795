import axios from 'axios';
import Task from '@/models/Task/Task';
import TrafficContracts from '@/models/TrafficContracts/TrafficContracts';

const mutations = {
    setTasksList: (state, data) => {
        state.tasksList = data;
    },
    setLoading: (state, data) => {
        state.loading = data;
    },
    changeTaskStatus: (state, data) => {
        const task = state.tasksList.find(elem => elem.id === data.id);
        task.status = data.statusNew.status;
        task.statusCode = data.statusNew.statusCode;
    },
    setTrafficContracts: (state, data) => {
        state.trafficContracts = data;
    },
};

const actions = {
    loadTasks: ({ commit, rootState }) => {
        commit('setLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/tasks/tasks/get', config)
            .then(response => {
                const result = response.data.data;
                const tasks = result.map(item => new Task(
                    item.id,
                    item.type,
                    item.description,
                    item.status,
                    item.statusCode,
                    item.amount,
                    item.dueDate,
                    item.offerId,
                    item.offerTitle,
                    item.complicacy,
                    item.complicacyCode,
                    item.isCheckingRequired,
                ));
                commit('setTasksList', tasks);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    setStatusRun: ({ commit, rootState }, id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/tasks/status/setRun?id=${id}`, config)
            .then(response => {
                const result = response.data.data;
                if (result.status) {
                    const data = {
                        id: id,
                        statusNew: result,
                    };
                    commit('changeTaskStatus', data);
                }
                if (result.message) {
                    console.error(result.message);
                }
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    setStatusCompleted: ({
        commit,
        dispatch,
        rootState,
    }, id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/tasks/status/setCompleted?id=${id}`, config)
            .then(response => {
                const result = response.data.data;
                if (result.status) {
                    const data = {
                        id: id,
                        statusNew: result,
                    };
                    commit('changeTaskStatus', data);
                    dispatch(
                        'authorization/loadBalance', null, { root: true },
                    );
                }
                if (result.message) {
                    console.error(result.message);
                }
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    setStatusChecking: ({ commit, rootState }, id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/tasks/status/setChecking?id=${id}`, config)
            .then(response => {
                const result = response.data.data;
                if (result.status) {
                    const data = {
                        id: id,
                        statusNew: result,
                    };
                    commit('changeTaskStatus', data);
                }
                if (result.message) {
                    console.error(result.message);
                }
            })
            .catch(error => {
                console.error(error);
            });
    },
    getTrafficContracts: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/tasks/TrafficContracts/get', config)
            .then(response => {
                const result = response.data.data.map(item => new TrafficContracts(
                    item.id,
                    item.offer,
                    item.conditions,
                    item.period,
                    item.status,
                    item.type,
                ));
                commit('setTrafficContracts', result);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const getters = {
    getSortStatuses: state => statuses => state.tasksList
        .filter(elem => statuses.includes(elem.statusName)),
    isEmptyTasksList: state => !Object.values(state.tasksList).length,
    getTasksOffer: state => id => {
        const list = state.tasksList.filter(elem => elem.offerId === id);
        if (list) {
            const statuses = ['new', 'teaser', 'run', 'run-checking', 'done', 'checking'];
            const filter = list.filter(elem => statuses.includes(elem.statusName));
            return filter.sort((a, b) => {
                if (a.statusCode < b.statusCode) {
                    return -1;
                }
                if (a.statusCode > b.statusCode) {
                    return 1;
                }
                return 0;
            });
        }
        return [];
    },
    getTrafficContractsStatus: state => statusValue => state.trafficContracts
        .filter(elem => statusValue === elem.status.value),
};

const state = () => ({
    tasksList: [],
    loading: false,
    tasksOffer: [],
    trafficContracts: [],
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
