export default class Cart {
    constructor() {
        this.cartPositions = {};
        this.contact = null;
    }

    get isEmpty() {
        return !Object.values(this.cartPositions).length;
    }

    get totalCount() {
        return Object.values(this.cartPositions).reduce((accumulator, position) => accumulator + position.count, 0);
    }

    get totalAmount() {
        return Object.values(this.cartPositions).reduce((accumulator, position) => accumulator + position.sumPrice, 0);
    }

    get hasDiscount() {
        return this.totalDiscount > 0;
    }

    get totalOldAmount() {
        return Object.values(this.cartPositions).reduce((accumulator, position) => accumulator + position.sumOldPrice, 0);
    }

    get totalDiscount() {
        return Object.values(this.cartPositions).reduce((accumulator, position) => accumulator + position.sumDiscount, 0);
    }
}
