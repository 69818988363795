<template>
    <RegistrationForm />
</template>

<script>
import RegistrationForm from '@/components/auth/RegistrationForm.vue';

export default {
    name: 'Registration',
    components: {
        RegistrationForm,
    },
};
</script>
