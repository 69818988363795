const STATUS_ACTIVE = 'active';
const STATUS_PAUSED = 'paused';
const STATUS_PENDING = 'pending';

const TITLES = {
    [STATUS_ACTIVE]: 'Активен',
    [STATUS_PAUSED]: 'На паузе',
    [STATUS_PENDING]: 'Ожидает подтверждения',
};

const CLASSES = {
    [STATUS_ACTIVE]: 'active',
    [STATUS_PAUSED]: 'paused',
    [STATUS_PENDING]: 'pending',
};

export default class OfferStatus {
    /**
     *
     * @param status
     */

    constructor(status) {
        this.status = status;
    }

    get title() {
        return TITLES[this.status] || null;
    }

    get class() {
        return CLASSES[this.status] || null;
    }
}
