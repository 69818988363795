<template>
    <div class="lds-container">
        <div class="easter-eggs-rules">
            <h1 class="easter-eggs-rules__header">
                Подробности
            </h1>
            <p class="easter-eggs-rules__description">
                Tour de LEADS - розыгрыш для спонсоров акции "Moneyfest", который проходит с 1 июля по 15 декабря 2022
                года и состоит из 3 туров, в каждом из которых вы сможете забрать один из 10 призов.
                "Топ-5" победителей каждого тура становятся участниками “Гран-при”, где будет разыгран финальный приз.
            </p>
            <p class="easter-eggs-rules__description">
                <b>Tour 1: Марокко</b>
            </p>
            <p class="easter-eggs-rules__description">
                Сроки проведения: с 1 июля по 15 августа 2022 года
                Итоги и вручение наград: 20-25 августа 2022 год
            </p>

            <p class="easter-eggs-rules__description">
                <b>Tour 2: ОАЭ</b>
            </p>
            <p class="easter-eggs-rules__description">
                Сроки проведения: с 1 сентября по 15 октября 2022 года
                Итоги и вручение наград: 20-25 октября 2022 года
            </p>

            <p class="easter-eggs-rules__description">
                <b>Tour 3: Доминикана</b>
            </p>
            <p class="easter-eggs-rules__description">
                Сроки проведения: с 1 ноября по 15 декабря 2022 года
                Итоги и вручение наград: 20-25 декабря 2022 года
            </p>

            <p class="easter-eggs-rules__description">
                <b>Grand Prix</b>
            </p>
            <p class="easter-eggs-rules__description">
                На одном из наших классных митапов в 2023 году
            </p>

            <h2 class="easter-eggs-rules__header">
                Что нужно делать
            </h2>
            <p class="easter-eggs-rules__description">
                В каждом туре вас будут ждать несколько "пасхалок", которые мы бережно запрятали по всему личному
                кабинету. Все, что вам остается - найти их. Для этого к каждой "пасхалке" мы любезно приготовили
                подсказку, которая вам может помочь в ответах на вопросы "где спрятана?" и "как выглядит?".

                Да, и не стоит забывать про наших менеджеров, которые могут попросить для вас дополнительную подсказку.
            </p>

            <h2 class="easter-eggs-rules__header">
                Что нужно делать, чтобы выиграть
            </h2>
            <p class="easter-eggs-rules__description">
                Все просто....найти все "пасхалки". Сделать это быстрее остальных участников.

                Чтобы забрать один из 10 призов в каждом туре вам нужно найти все "пасхалки". Главный приз тура
                достанется участнику, который потратил меньше всего времени на поиск всех "пасхалок". Остальные места мы
                также распределим, согласно суммарному общему времени участника, которое он потратил на поиск, начиная с
                момента активации "пасхалки" до момента ее открытия.

                Меньше времени - больше награда. Поэтому внимательно следите за новостями)
            </p>

            <h2 class="easter-eggs-rules__header">
                Правила и механика Tour 2: ОАЭ
            </h2>

            <div class="easter-eggs-rules__description">
                <ol>
                    <li>"Пасхалки" активируются последовательно, начиная с 1 сентября 2022 года</li>
                    <li>Все подсказки будут размещены на странице розыгрыша в личном кабинете</li>
                    <li>
                        Каждая новая "пасхалка" и подсказка к ней будут появляться каждую неделю, вне зависимости от
                        статуса предыдущей
                    </li>
                    <li>
                        Лучше начинать разгадку и поиск "пасхалки" сразу после ее появления, потому что мы будем
                        учитывать время с момента ее активации до момента, когда вы ее найдете
                    </li>
                    <li>
                        Если у вас накопилось несколько "пасхалок", то можете начинать искать любую из них. Важна не
                        очередность, а общее время
                    </li>
                    <li>
                        Если вы правильно определили "пасхалку", то при клике на нее, вы увидите соответствующее
                        уведомление.
                    </li>
                    <li>Дата появления следующей "пасхалки" указана на странице розыгрыша.</li>
                    <li>Все активные "пасхалки" будут деактивированы 15 октября 2022 года в 24:00</li>
                    <li>
                        Подведение итогов и определение победителей произойдет на вебинаре в период 20-25 октября 2022 года
                    </li>
                </ol>
            </div>

            <h2 class="easter-eggs-rules__header">
                Призовой фонд тура
            </h2>
            <p class="easter-eggs-rules__description">
                1 место: тур в ОАЭ или любую точку мира за счет LEADS стоимостью 150 000 рублей
                (вы выбираете любой тур, в любое удобное для вас время, в любом агентстве, которому мы переведем
                оплату/часть оплаты в размере 150000 (сто пятьдесят тысяч) рублей

                2-10 место: электронные сертификаты или виртуальные карты
                2 место - 30 000 рублей
                3 место - 20 000 рублей
                4 место - 10 000 рублей
                5 место - 5 000 рублей
                6-10 место - 2 000 рублей
            </p>

            <h2 class="easter-eggs-rules__header">
                Розыгрыш главного приза (Grand Prix)
            </h2>
            <p class="easter-eggs-rules__description">
                Участникам, занявшим 1-5 место в рамках каждого из туров, начисляются тикеты в формате 1 место - 5
                тикетов, 5 место - 1 тикет, с шагом в 1 тикет от большего к меньшему.
                На митапе по итогам акции Moneyfest, среди обладателей тикетов мы разыграем главный приз - тур в
                любую точку мира за счет LEADS стоимостью 300 000 рублей.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EasterEggsRulesArticle',
};
</script>
