<template>
    <div class="lds-container">
        <Loader v-if="loading" />
        <template v-else>
            <div class="lm-orders"
                 v-if="ordersSource.length">
                <div class="lm-orders__list">
                    <OrderCreated v-for="order in ordersSource"
                                  :order="order"
                                  :key="`order${order.id}`" />
                </div>
            </div>
            <div class="clnt-empty"
                 v-else>
                У Вас пока нет заказов
                <div class="clnt-empty__action">
                    <router-link class="lds-btn lds-btn_border -dark"
                                 :to="{name: 'MarketCatalog'}">
                        Перейти&nbsp;в&nbsp;каталог
                    </router-link>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '@/components/common/Loader';
import OrderCreated from '@/components/market/MarketOrderCreated.vue';

export default {
    name: 'Orders',
    components: {
        Loader,
        OrderCreated,
    },
    computed: {
        ...mapState('market', [
            'loading',
            'account',
            'orders',
        ]),
        ordersSource() {
            return Object.values(this.orders)
                .sort((a, b) => b.id - a.id);
        },
    },
};
</script>
