<template>
    <div class="lds-container">
        <Loader v-if="loading" />
        <template v-else>
            <div class="lm-catalog">
                <div class="lm-catalog__sort">
                    <Categories />
                    <Filters />
                </div>
                <div class="lm-catalog__grid"
                     v-if="availableProducts.length">
                    <CatalogCard class="lm-catalog__item"
                                 :product="item"
                                 v-for="item in availableProducts"
                                 :key="'product_' + item.id" />
                </div>
                <div class="clnt-empty"
                     v-else>
                    Нет товаров
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Categories from '@/components/market/MarketCategories';
import Filters from '@/components/market/MarketFilters';
import CatalogCard from '@/components/market/MarketCatalogCard';
import Loader from '@/components/common/Loader';

export default {
    name: 'MarketCatalog',
    components: {
        Categories,
        Filters,
        Loader,
        CatalogCard,
    },
    data() {
        return {
            littleBitLimit: 1.2,
        };
    },
    computed: {
        ...mapState('account', [
            'accountBalance',
        ]),
        ...mapState('market', [
            'loading',
            'products',
            'priceFrom',
            'priceTo',
        ]),
        categoryId() {
            return this.$route.params.categoryId;
        },
        isAllCategories() {
            return !this.categoryId;
        },
        priceDescSorter() {
            return (a, b) => parseFloat(b.salePrice.amount) - parseFloat(a.salePrice.amount);
        },
        availableProducts() {
            const availableProducts = Object.values(this.products)
                .filter(item => this.isAllCategories || item.inCategory(this.categoryId))
                .sort(this.priceDescSorter);

            const littleBitLeft = availableProducts.filter(item => {
                const price = this.accountBalance * this.littleBitLimit;
                return item.isPriceBetween(this.accountBalance, price);
            });

            const canBuy = availableProducts
                .filter(item => item.isPriceBetween(1, this.accountBalance));

            const other = availableProducts
                .filter(item => {
                    const price = this.accountBalance * this.littleBitLimit;
                    return item.isGreaterThan(price);
                });

            const filteredItems = [
                ...littleBitLeft,
                ...canBuy,
                ...other,
            ];

            if (this.priceFrom === '' && this.priceTo === '') {
                return filteredItems;
            }

            return filteredItems
                .filter(item => {
                    const minPrice = this.priceFrom === 0 ? 0 : this.priceFrom * 1000;
                    if (this.priceTo === '') {
                        return item.isGreaterThan(minPrice);
                    }
                    const maxPrice = this.priceTo === 0 ? 0 : this.priceTo * 1000;
                    return item.isPriceBetween(minPrice, maxPrice);
                });
        },
    },
};
</script>
