<template>
    <div class="lds-container">
        <template v-if="!loading">
            <CentrmamaReport v-if="info"
                             :info="info" />
            <NotFound v-else />
        </template>
        <Loader v-else />
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import metaMixin from '@/mixins/metaMixin';
import CentrmamaReport from '@/components/centrmama/report';
import NotFound from '@/components/common/NotFound.vue';
import Loader from '@/components/common/Loader';

export default {
    name: 'CharityReport',
    components: {
        CentrmamaReport,
        NotFound,
        Loader,
    },
    mixins: [
        metaMixin,
    ],
    data() {
        return {
            info: null,
            loading: true,
        };
    },
    created() {
        this.getReport();
    },
    methods: {
        ...mapActions('centrmama', [
            'getTarget',
        ]),
        ...mapMutations('common', [
            'replaceBreadcrumbs',
            'setHeading',
        ]),
        async getReport() {
            const result = await this.getTarget(this.$route.params.reportId);
            this.loading = false;

            if (result) {
                this.info = result;
                this.updateMeta();
            }
        },
        updateMeta() {
            const name = this.info ? this.info.title : '';
            const data = {
                id: ':reportId',
                name: name,
            };
            this.replaceBreadcrumbs(data);
            this.setHeading(name);
            this.setTitlePage('Отчет - ' + name);
        },
    },
};
</script>
