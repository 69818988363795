<template>
    <router-link class="lm-card"
                 :to="{
                     name: 'MarketProduct',
                     params: {
                         productId: product.id,
                     },
                 }">
        <div class="lm-card__img">
            <img :src="product.imageSrc"
                 :alt="product.name"
                 v-if="product.mainImage.path">
        </div>
        <div class="lm-card__info">
            <div class="lm-card__category"
                 v-if="product.category">
                {{ product.category.name }}
            </div>
            <div class="lm-card__title">
                {{ product.name }}
            </div>
            <div class="lm-card__description">
                {{ product.shortDescription }}
            </div>
            <div class="lm-card__price">
                {{ product.formattedPrice }}
                <SvgIcon name="leads-coins" />
            </div>
            <div class="lm-card__price -old"
                 v-if="product.hasOldPrice">
                {{ product.formattedOldPrice }}
            </div>
        </div>
    </router-link>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'MarketCatalogCard',
    components: {
        SvgIcon,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
};
</script>
