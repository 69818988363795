<template>
    <div class="clnt-report"
         v-html="info.report" />
</template>

<script>
export default {
    name: 'Report',
    props: {
        info: {
            type: Object,
            default() {
                return null;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
