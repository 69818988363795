<template>
    <TrafficContracts />
</template>

<script>
import TrafficContracts from '@/components/trafficContracts/TrafficContracts/index';

export default {
    name: 'TrafficsContractsPage',
    components: {
        TrafficContracts,
    },
};
</script>
