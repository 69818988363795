<template>
    <div class="lds-dialog">
        <div class="lds-dialog__wrap">
            <div class="lds-dialog__overlay"
                 @click="close" />
            <div class="lds-dialog__content">
                <button class="lds-dialog__back"
                        @click="close">
                    <SvgIcon name="arrow-left" />
                    Назад к заказу
                </button>
                <div class="lds-dialog__title">
                    Новый адрес доставки
                </div>
                <div class="lm-form">
                    <div class="lds-control"
                         :class="{'lds-control_error': $v.deliveryType.$error}">
                        <label class="lds-control__label">Тип доставки</label>
                        <vSelect class="lds-select"
                                 placeholder="Выберите тип"
                                 v-model="deliveryType"
                                 :options="availableDeliveryTypes"
                                 :reduce="type => type.value"
                                 label="title">
                            <template #no-options="{ search, searching }">
                                <template v-if="searching">
                                    Нет результатов <em>{{ search }}</em>.
                                </template>
                            </template>
                        </vSelect>
                        <div class="lds-control__message"
                             v-if="$v.deliveryType.$error">
                            Выберите тип доставки
                        </div>
                    </div>

                    <div class="lds-control"
                         :class="{'lds-control_error': $v.addressCountry.$error}">
                        <label class="lds-control__label">Страна</label>
                        <vSelect class="lds-select"
                                 placeholder="Выберите страну"
                                 id="addressCountry"
                                 v-model="addressCountry"
                                 :options="countries"
                                 label="title">
                            <template #no-options="{ search, searching }">
                                <template v-if="searching">
                                    Нет результатов <em>{{ search }}</em>.
                                </template>
                            </template>
                            <template slot="option"
                                      slot-scope="option">
                                <div class="d-center">
                                    {{ option.title }}
                                </div>
                            </template>
                            <template slot="selected-option"
                                      slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.title }}
                                </div>
                            </template>
                        </vSelect>
                        <div class="lds-control__message"
                             v-if="$v.addressCountry.$error">
                            Выберите страну
                        </div>
                    </div>
                    <div class="lds-control"
                         :class="{'lds-control_error': $v.addressRegion.$error}"
                         v-if="!!addressCountry">
                        <label class="lds-control__label">Регион</label>
                        <vSelect class="lds-select"
                                 placeholder="Выберите регион"
                                 v-model="addressRegion"
                                 id="addressRegion"
                                 :options="regions"
                                 autocomplete="off"
                                 label="title">
                            <template #no-options="{ search, searching }">
                                <template v-if="searching">
                                    Нет результатов <em>{{ search }}</em>.
                                </template>
                            </template>
                            <template slot="option"
                                      slot-scope="option">
                                <div class="d-center">
                                    {{ option.title }}
                                </div>
                            </template>
                            <template slot="selected-option"
                                      slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.title }}
                                </div>
                            </template>
                        </vSelect>
                        <div class="lds-control__message"
                             v-if="$v.addressRegion.$error">
                            Выберите регион
                        </div>
                    </div>

                    <template v-if="addressRegion">
                        <div class="lds-control"
                             :class="{'lds-control_error': $v.addressCity.$error}">
                            <label class="lds-control__label"
                                   for="addressCity">Населенный пункт</label>
                            <input type="text"
                                   id="addressCity"
                                   name="addressCity"
                                   class="lds-input"
                                   v-model.trim="addressCity"
                                   @change="$v.addressCity.$touch()"
                                   autocomplete="off">
                            <div class="lds-control__message"
                                 v-if="$v.addressCity.$error">
                                Укажите населенный пункт
                            </div>
                        </div>

                        <div class="lds-control"
                             :class="{'lds-control_error': $v.addressCityAddress.$error}">
                            <label class="lds-control__label"
                                   for="addressCityAddress">Адрес</label>
                            <input type="text"
                                   id="addressCityAddress"
                                   name="addressCityAddress"
                                   class="lds-input"
                                   placeholder="Улица, дом, квартира / офис"
                                   v-model.trim="addressCityAddress"
                                   @change="$v.addressCityAddress.$touch()"
                                   autocomplete="off">
                            <div class="lds-control__message"
                                 v-if="$v.addressCityAddress.$error">
                                Укажите адрес
                            </div>
                        </div>

                        <div class="lds-control"
                             :class="{'lds-control_error': $v.fullName.$error}">
                            <label class="lds-control__label"
                                   for="name">ФИО получателя</label>
                            <input type="text"
                                   id="name"
                                   name="name"
                                   class="lds-input"
                                   v-model.trim="fullName"
                                   @change="$v.fullName.$touch()"
                                   autocomplete="off">
                            <div class="lds-control__message"
                                 v-if="$v.fullName.$error">
                                Укажите ФИО
                            </div>
                        </div>

                        <div class="lds-control"
                             :class="{'lds-control_error': $v.phone.$error}">
                            <label class="lds-control__label"
                                   for="phone">Телефон</label>
                            <input type="tel"
                                   id="phone"
                                   name="phone"
                                   class="lds-input"
                                   v-model="phone"
                                   @input="inputPhone()"
                                   @change="$v.phone.$touch()">
                            <div class="lds-control__message"
                                 v-if="$v.phone.$error && $v.phone.$model === ''">
                                Укажите телефон для связи
                            </div>
                            <div class="lds-control__message"
                                 v-if="$v.phone.$error && $v.phone.$model !== ''">
                                Некорректный номер
                            </div>
                        </div>

                        <div class="lds-control"
                             :class="{'lds-control_error': $v.email.$error}">
                            <label class="lds-control__label"
                                   for="email">
                                Email
                            </label>
                            <input type="email"
                                   id="email"
                                   name="email"
                                   class="lds-input"
                                   v-model="email"
                                   @change="$v.email.$touch()">
                            <div class="lds-control__message"
                                 v-if="$v.email.$error && $v.email.$model === ''">
                                Поле обязательно для заполнения
                            </div>
                            <div class="lds-control__message"
                                 v-if="$v.email.$error && $v.email.$model !== ''">
                                Некорректный ввод. Заполните поле по шаблону example@domain.com
                            </div>
                        </div>

                        <div class="lds-control">
                            <label class="lds-control__label">
                                Доп. информация
                            </label>
                            <textarea-autosize id="comment"
                                               name="comment"
                                               class="lds-input"
                                               placeholder="Код от домофона, как найти офис и т.д."
                                               v-model.trim="comment"
                                               rows="1" />
                        </div>

                        <div class="lds-control"
                             :class="{'lds-control_error': $v.agreement.$error}">
                            <div class="lds-checkbox">
                                <input type="checkbox"
                                       id="agreement"
                                       name="agreement"
                                       class="lds-checkbox__checkbox"
                                       v-model="agreement">
                                <label class="lds-checkbox__label"
                                       for="agreement">
                                    Я согласен с&nbsp;
                                    <router-link :to="{name: 'Conditions'}">условиями работы&nbsp;</router-link>
                                    с сетью Leads.su
                                </label>
                            </div>
                            <div class="lds-control__message"
                                 v-if="$v.agreement.$error">
                                Поле обязательно для заполнения
                            </div>
                        </div>

                        <div class="lds-control">
                            <button class="lds-btn lds-btn_fill -dark"
                                    @click="addCurrentContact">
                                Добавить
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import vSelect from 'vue-select';
import TextareaAutosize from 'vue-textarea-autosize';
import {
    required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';
import SvgIcon from '@/components/common/SvgIcon';
import { DeliveryType, typeToTheDoor } from '@/models/Market/DeliveryType';

Vue.use(TextareaAutosize);

export default {
    name: 'ContactCardForm',
    components: {
        SvgIcon,
        vSelect,
    },
    data: () => ({
        addressCountry: null,
        addressRegion: null,
        addressCity: '',
        addressCityAddress: '',
        fullName: '',
        phone: '',
        email: '',
        comment: '',
        deliveryType: typeToTheDoor,
        agreement: false,
    }),
    validations: {
        deliveryType: {
            required,
        },
        addressCountry: {
            required,
        },
        addressRegion: {
            required,
        },
        addressCity: {
            required,
        },
        addressCityAddress: {
            required,
        },
        fullName: {
            required,
        },
        phone: {
            required,
            minLength: minLength(10),
        },
        email: {
            required,
            email,
        },
        agreement: {
            sameAs: sameAs(() => true),
        },
    },
    computed: {
        ...mapState('market', [
            'countries',
            'regions',
        ]),
        availableDeliveryTypes() {
            return DeliveryType.options();
        },
    },
    watch: {
        addressCountry: function (after, before) {
            if (before !== after) {
                this.addressRegion = null;
                this.addressCity = null;
                this.loadGeoRegions(this.addressCountry.id);
            }
        },
        addressRegion: function (after, before) {
            if (before !== after) {
                this.addressCity = null;
            }
        },
    },
    beforeMount() {
        document.documentElement.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.documentElement.style.overflow = '';
    },
    created() {
        this.loadGeoCountries();
    },
    methods: {
        ...mapActions('market', [
            'addNewContact',
            'loadGeoCountries',
            'loadGeoRegions',
        ]),
        ...mapMutations('common', [
            'setNotification',
        ]),
        addCurrentContact() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = {
                fullName: this.fullName,
                phones: [this.phone],
                email: this.email,
                addressCountry: this.addressCountry.title,
                addressRegion: this.addressRegion.title,
                addressCity: this.addressCity,
                addressCityAddress: this.addressCityAddress,
                deliveryType: this.deliveryType,
                comment: this.comment,
                agreement: this.agreement,
            };
            this.addNewContact(data)
                .then(() => this.close())
                .catch(error => {
                    this.setNotification({
                        value: error,
                        type: 'error',
                    });
                });
        },
        close() {
            this.$emit('close');
        },
        inputPhone() {
            const prefix = '+';
            const value = this.phone.replace(/[^0-9]/g, '');
            this.phone = prefix + value;
        },
    },
};
</script>
