<template>
    <div class="lds-container">
        <Loader v-if="loading" />
        <template v-else>
            <div class="lm-product"
                 v-if="product">
                <div class="lm-product__img"
                     v-if="product.mainImage">
                    <img
                        :src="product.imageSrc"
                        :alt="product.name">
                    <div v-if="!tour3Egg6RiddleSolved"
                         class="lm-product__easter-tour3-egg6"
                         @click="runEasterEggRiddle" />
                </div>
                <div class="lm-product__content">
                    <div class="lm-product__category"
                         v-if="product.category">
                        {{ product.category.name }}
                    </div>
                    <div class="lm-product__title">
                        {{ product.name }}
                    </div>
                    <div class="lm-product__price">
                        {{ product.formattedPrice }}
                        <SvgIcon name="leads-coins" />
                    </div>

                    <div class="lm-product__price -old"
                         v-if="product.hasOldPrice">
                        {{ product.formattedOldPrice }}
                    </div>
                    <div class="lm-product__actions">
                        <div class="lm-product__actions-item -lg"
                             v-if="productInCart">
                            <div class="lm-count">
                                <button class="lm-count__btn lm-count__btn_minus"
                                        @click="decrementCountCurrent()">
                                    -
                                </button>
                                <input
                                    class="lm-count__input"
                                    type="number"
                                    @change="changeCountCurrent()"
                                    v-model="count">
                                <button class="lm-count__btn lm-count__btn_plus"
                                        @click="incrementCountCurrent()">
                                    +
                                </button>
                            </div>
                        </div>
                        <div class="lm-product__actions-item -lg"
                             v-if="productInCart">
                            <router-link class="lds-btn lds-btn_size-sm lds-btn_border -dark"
                                         :to="{name: 'MarketOrder'}">
                                Перейти в корзину
                            </router-link>
                        </div>
                        <div class="lm-product__actions-item"
                             v-if="!productInCart">
                            <button class="lm-product__buy lds-btn lds-btn_size-sm lds-btn_fill -dark"
                                    @click="addToCart(product)">
                                Купить
                            </button>
                        </div>
                    </div>
                    <div class="lm-product__description"
                         v-html="product.shortDescription" />

                    <div class="lm-product__description"
                         v-html="product.description" />

                    <div class="lm-product__description"
                         v-if="isTour1Egg4Product && !!tour1EasterEgg4 && tour1EasterEgg4.needShow">
                        А тут, кстати, та самая <span class="lm-product__easter-egg-text"
                                                      @click="collect(tour1EasterEgg4)">пасхалка</span>, на которую
                        нужно кликнуть.
                    </div>

                    <div class="lm-product__description"
                         v-if="isTour2Egg6Product && tour2EasterEgg6ConditionsSolved && !!tour2EasterEgg6 && tour2EasterEgg6.needShow">
                        А тут, кстати, та самая <span class="lm-product__easter-egg-text"
                                                      @click="collect(tour2EasterEgg6)">пасхалка</span>, на которую
                        нужно кликнуть.
                    </div>
                </div>
            </div>
            <NotFound v-else />
        </template>
        <RiddleModalTour3 />
    </div>
</template>

<script>
import {
    mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import metaMixin from '@/mixins/metaMixin';
import SvgIcon from '@/components/common/SvgIcon.vue';
import Loader from '@/components/common/Loader';
import NotFound from '@/components/common/NotFound.vue';
import {tour1egg4, tour2egg6, tour3egg6} from '@/models/EasterEggs/EasterEgg';
import EasterEggCookies, {
    tour2Egg6RiddleSolved,
    tour3Egg6RiddleSolved,
} from '@/models/EasterEggs/EasterEggCookies';
import RiddleModalTour3 from '@/components/easterEggs/easterEggsRiddle/tour3egg6.vue';

export default {
    name: 'MarketProduct',
    components: {
        RiddleModalTour3,
        SvgIcon,
        Loader,
        NotFound,
    },
    mixins: [
        metaMixin,
    ],
    data() {
        return {
            count: 1,
        };
    },
    computed: {
        ...mapState('market', [
            'cart',
            'loading',
            'products',
        ]),
        ...mapState('easterEggs', [
            'tour3egg6RiddleModal',
            'tour3Egg6RiddleSolved',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        tour1EasterEgg4() {
            return this.getAvailable(tour1egg4);
        },
        tour2EasterEgg6() {
            return this.getAvailable(tour2egg6);
        },
        tour3EasterEgg6() {
            return this.getAvailable(tour3egg6);
        },
        tour2EasterEgg6ConditionsSolved() {
            return EasterEggCookies.getState(tour2Egg6RiddleSolved);
        },
        productId() {
            return this.$route.params.productId;
        },
        product() {
            return this.products[this.productId];
        },
        productInCart() {
            return this.productId in this.cart.cartPositions;
        },
        cartPositionCurrent() {
            return this.cart.cartPositions[this.productId];
        },
        isTour1Egg4Product() {
            return this.product.name === 'Виртуальная карта Visa MyGift 15 000 рублей';
        },
        isTour2Egg6Product() {
            return this.product.name === 'Сертификат Lamoda 25 000 рублей'
                || this.product.name === 'Тестовый товар';
        },
        isTour3Egg6Product() {
            return this.product.name === 'ИКЕА'
                || this.product.name === 'Тестовый товар';
        },
        isTour3Egg6Step() {
            const riddleSolved = EasterEggCookies.getState(tour3Egg6RiddleSolved);
            return this.tour3EasterEgg6 && this.tour3EasterEgg6.needShow && !riddleSolved
                && (this.product.name === 'ИКЕА на 30 000 рублей' || this.product.name === 'Тестовый товар');
        },
    },
    watch: {
        cart: {
            handler() {
                this.updateCount();
            },
            deep: true,
        },
        product() {
            this.updateMeta();
        },
    },
    mounted() {
        if (this.product) {
            this.updateCount();
            this.updateMeta();
            this.check(tour1egg4);
        }

        const tourEggCookies = EasterEggCookies.getState(tour3Egg6RiddleSolved);
        if (tourEggCookies) {
            this.setTour3Egg6RiddleSolved(false);
        }
        this.check(tour3egg6);
    },
    methods: {
        ...mapMutations('common', [
            'replaceBreadcrumbs',
            'setHeading',
        ]),
        ...mapActions('market', [
            'addToCart',
            'incrementCount',
            'decrementCount',
            'changeCount',
        ]),
        ...mapMutations('easterEggs', ['setTour3Egg6RiddleSolved', 'setTour3egg6RiddleModal']),
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        runEasterEggRiddle() {
            this.setTour3egg6RiddleModal({show: true});
        },
        incrementCountCurrent() {
            this.incrementCount(this.product);
        },
        decrementCountCurrent() {
            const data = {
                product: this.product,
                remove: true,
            };
            this.decrementCount(data);
        },
        changeCountCurrent() {
            let value = parseInt(this.count);
            if (!value) {
                value = 1;
            }
            if (value < 0) {
                value = value * -1;
            }
            this.count = value;

            const data = {
                product: this.product,
                value: value,
            };
            this.changeCount(data);
        },
        updateCount() {
            this.count = this.productInCart ? this.cartPositionCurrent.count : 1;
        },
        updateMeta() {
            const name = this.product ? this.product.name : '';
            const data = {
                id: ':productId',
                name: name,
            };
            this.replaceBreadcrumbs(data);
            this.setHeading(name);
            this.setTitlePage('Leads.Market - ' + name);
        },
    },
};
</script>
