import Vue from 'vue';
import Vuex from 'vuex';

import common from './common';
import authorization from './authorization';
import account from './account';
import omni from './omni';
import stub from './stub';
import offers from './offers';
import tasks from './tasks';
import market from './market';
import summary from './summary';
import promotion from './promotion';
import statistics from './statistics';
import feedback from './feedback';
import outputLC from './outputLC';
import easterEggs from './easterEggs';
import agreementLC from './agreement';
import leaders from './leaders';
import centrmama from './centrmama';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        common,
        authorization,
        account,
        omni,
        stub,
        offers,
        tasks,
        market,
        summary,
        promotion,
        statistics,
        feedback,
        outputLC,
        agreementLC,
        centrmama,
        leaders,
        easterEggs,
    },
});

export default store;
