<template>
    <div class="lds-container">
        <div class="clnt-output-lc">
            <template v-if="visibleForm">
                <h1 class="clnt-output-lc__title">
                    Вывод средств
                </h1>
                <form @submit.prevent="outputLC()"
                      class="clnt-output-lc__form">
                    <div class="clnt-output-lc__item clnt-output-lc__cols">
                        <div class="clnt-output-lc__col">
                            <div class="lds-control"
                                 :class="{'lds-control_error': $v.cardNumber.$error}">
                                <label for="card-number"
                                       class="lds-control__label lds-control__label_lg">Номер карты</label>
                                <input id="card-number"
                                       type="text"
                                       name="cardNumber"
                                       class="lds-input lds-input_size-md"
                                       v-mask="configCardNumber.mask"
                                       placeholder="0000 0000 0000 0000"
                                       v-model="cardNumber"
                                       @change="$v.cardNumber.$touch()"
                                       autocomplete="off">
                                <div class="lds-control__message"
                                     v-if="$v.cardNumber.$error">
                                    Проверьте введенные данные
                                </div>
                            </div>
                        </div>
                        <div class="clnt-output-lc__col">
                            <div class="lds-control"
                                 :class="{'lds-control_error': $v.cardExpired.$error}">
                                <label for="card-expired"
                                       class="lds-control__label lds-control__label_lg clnt-output-lc__label">Срок действия карты</label>
                                <input id="card-expired"
                                       type="text"
                                       name="cardExpired"
                                       class="lds-input lds-input_size-md clnt-output-lc__input"
                                       v-mask="configCardExpired.mask"
                                       placeholder="00/00"
                                       v-model.trim="cardExpired"
                                       @change="$v.cardExpired.$touch()"
                                       autocomplete="off">
                                <div class="lds-control__message"
                                     v-if="$v.cardExpired.$error">
                                    Проверьте введенные данные
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clnt-output-lc__item clnt-output-lc__control lds-control">
                        <div class="lds-control"
                             :class="{'lds-control_error': $v.cardCountryCode.$error}">
                            <label class="lds-control__label lds-control__label_lg">Страна выпуска карты</label>
                            <VSelect class="lds-select lds-select_size-md"
                                     id="cardCountryCode"
                                     placeholder="Выберите страну"
                                     v-model="cardCountryCode"
                                     @open="selectClick()"
                                     :options="countries"
                                     label="title"
                                     @search="countySelectInput" />
                            <div class="lds-control__message"
                                 v-if="$v.cardCountryCode.$error">
                                Обязательно для заполнения
                            </div>
                        </div>
                    </div>
                    <div class="clnt-output-lc__item clnt-output-lc__control lds-control"
                         :class="{'lds-control_error': $v.cardHolder.$error}">
                        <label for="card-holder"
                               class="lds-control__label lds-control__label_lg">Держатель карты</label>
                        <input id="card-holder"
                               type="text"
                               name="cardHolder"
                               class="lds-input lds-input_size-md"
                               placeholder="IVANOV IVAN"
                               v-model="cardHolder"
                               @change="$v.cardHolder.$touch()"
                               autocomplete="off">
                        <div class="lds-control__message"
                             v-if="$v.cardHolder.$error">
                            Имя и Фамилия должны содержать латинские буквы
                        </div>
                    </div>
                    <div class="clnt-output-lc__item clnt-output-lc__cols-sm">
                        <div class="clnt-output-lc__col">
                            <div class="lds-control"
                                 :class="{'lds-control_error': $v.leadsCoins.$error}">
                                <label for="amount-lc"
                                       class="lds-control__label lds-control__label_lg">Сумма в Lead.Coins</label>
                                <div class="lds-control__input lds-control__input_size-md">
                                    <input id="amount-lc"
                                           type="text"
                                           name="leadsCoins"
                                           class="lds-input lds-input_size-md"
                                           placeholder="1 520 000"
                                           v-money="maskCurrency"
                                           v-model.lazy="leadsCoins">
                                    <span class="lds-control__input-symbol">LC</span>
                                </div>
                                <div class="lds-control__message"
                                     v-if="$v.leadsCoins.$error && !$v.leadsCoins.minValue">
                                    Минимальная сумма вывода 1 520 000 LC
                                </div>
                                <div class="lds-control__message"
                                     v-if="$v.leadsCoins.$error && !$v.leadsCoins.maxValue">
                                    Максимальная сумма вывода {{ formatCoins(accountBalance) }} LC
                                </div>
                                <div class="lds-control__message"
                                     v-if="$v.leadsCoins.$error && !$v.leadsCoins.required">
                                    Обязательно для заполнения
                                </div>
                            </div>
                        </div>
                        <div class="clnt-output-lc__col">
                            <div class="lds-control">
                                <label for="amout-rub"
                                       class="lds-control__label lds-control__label_lg">Сумма в рублях</label>
                                <div class="lds-control__input lds-control__input_size-md">
                                    <input id="amout-rub"
                                           type="text"
                                           name="sumRub"
                                           class="lds-input lds-input_size-md"
                                           v-model.lazy="convertedRub"
                                           v-money="maskCurrencyDecimal"
                                           disabled>
                                    <SvgIcon name="leads-coins"
                                             v-if="!!currentEasterEgg && currentEasterEgg.needShow && showEgg"
                                             @click.native="collectEasterEgg"
                                             class="clnt-output-lc__easter-icon" />
                                    <span class="lds-control__input-symbol">₽</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clnt-output-lc__checkbox">
                        <div class="lds-control"
                             :class="{'lds-control_error': $v.agreement.$error}">
                            <div class="lds-checkbox lds-checkbox_sm">
                                <input type="checkbox"
                                       id="agreement"
                                       v-model="agreement"
                                       name="agreement"
                                       class="lds-checkbox__checkbox">
                                <label class="lds-checkbox__label"
                                       for="agreement">
                                    Я подтверждаю, что ознакомлен с правилами и ограничениями получения выплат и согласен с этими условиями.
                                </label>
                            </div>
                            <div class="lds-control__message"
                                 v-if="$v.agreement.$error">
                                Обязательно для заполнения
                            </div>
                        </div>
                        <div class="lds-control"
                             :class="{'lds-control_error': $v.agreementPersonal.$error}">
                            <div class="lds-checkbox lds-checkbox_sm">
                                <input type="checkbox"
                                       id="agreement-two"
                                       v-model="agreementPersonal"
                                       name="agreement"
                                       class="lds-checkbox__checkbox">
                                <label class="lds-checkbox__label"
                                       for="agreement-two">
                                    С правилами обработки персональных данных в
                                    <router-link :to="{name: 'Agreement'}">условиях работы</router-link>
                                    ознакомлен и согласен.*
                                </label>
                            </div>
                            <div class="lds-control__message"
                                 v-if="$v.agreementPersonal.$error">
                                Обязательно для заполнения
                            </div>
                        </div>
                    </div>

                    <div class="clnt-output-lc__button">
                        <button class="lds-btn lds-btn_fill lds-btn_size-xs -blue"
                                type="submit"
                                :disabled="disableSubmit">
                            Отправить заявку
                        </button>
                    </div>
                </form>
            </template>
            <div v-else
                 class="clnt-output-lc__success">
                <span class="clnt-output-lc__success-ico" />
                <div class="clnt-output-lc__title">
                    Готово!
                </div>
                <p class="clnt-output-lc__success-text">
                    Запрос на выплату {{ convertedRub }} ₽ на карту {{ cardNumberLastNumbers }} принят
                </p>
                <button class="lds-btn lds-btn_size-xs lds-btn_fill -gray"
                        @click="showForm()">
                    Отправить новую заявку
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState,
} from 'vuex';
import {required, minValue, maxValue} from 'vuelidate/lib/validators';
import {mask} from 'vue-the-mask';
import {VMoney} from 'v-money';
import _debounce from 'lodash/debounce';
import vSelect from 'vue-select';
import formattingCoins from '@/mixins/formattingCoins';
import {tour2egg2} from '@/models/EasterEggs/EasterEgg';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'OutputLC',
    components: {
        SvgIcon,
        vSelect,
    },
    directives: {
        mask,
        money: VMoney,
    },
    mixins: [
        formattingCoins,
    ],
    data: () => ({
        configCardNumber: {
            type: 'text',
            mask: '#### #### #### #### ##',
        },
        configCardExpired: {
            type: 'text',
            mask: '##/##',
        },
        maskCurrency: {
            thousands: ' ',
            precision: 0,
        },
        maskCurrencyDecimal: {
            decimal: '.',
            thousands: ' ',
            precision: 2,
        },
        cardNumber: '',
        cardExpired: '',
        cardHolder: '',
        leadsCoins: 0,
        convertedRub: 0,
        agreement: false,
        agreementPersonal: false,
        availableMonth: {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December',
        },
        cardCountryCode: null,
        minValueLC: 1520000,
        visibleForm: true,
        showEgg: false,
    }),
    computed: {
        ...mapState('account', [
            'accountBalance',
        ]),
        ...mapState('outputLC', [
            'countries',
            'rubAmount',
            'processing',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        disableSubmit() {
            return this.processing;
        },
        cardNumberLastNumbers() {
            const number = this.cardNumber;
            if (number) {
                const lastNumbers = this.cardNumber.replace(/\s+/g, '').substr(-4);
                return `····${lastNumbers}`;
            }
            return '';
        },
        currentEasterEgg() {
            return this.getAvailable(tour2egg2);
        },
    },
    watch: {
        countrySelected(value) {
            if (!value) {
                this.getCountries();
            }
        },
        cardHolder(value) {
            if (!value) {
                return '';
            }
            this.cardHolder = value.toString().toUpperCase();
        },
        rubAmount(value) {
            this.convertedRub = value;

            if (Number(value) > 0) {
                this.debounce_checkEgg(tour2egg2);
            }
        },
        leadsCoins(newValue, oldValue) {
            const number = newValue.toString().replace(/\s+/g, '');
            if (Number(number) < 2 ** 32) {
                this.debounce_inputLC(number);
            } else {
                this.leadsCoins = oldValue;
            }
        },
    },
    created() {
        this.getCountries();
    },
    validations: {
        cardNumber: {
            required,
            isValidNumber(cardNumber) {
                const regex = /(\d{4}[ ]?){3}\d(\d{3}[ ]?)?(\d{3})?/;
                return regex.test(cardNumber);
            },
        },
        cardExpired: {
            required,
            isValidMonthAndYear(value, me) {
                const regex = /^(\d{2}).(\d{2})/;
                const matched = regex.test(value);

                if (!matched) {
                    return false;
                }

                const dateAsArray = value.split('/');
                const currentDate = new Date();
                const currentMonth = currentDate.toLocaleString('en', {
                    month: 'long',
                });

                const availableMonth = me.availableMonth;
                const inputMonth = parseInt(dateAsArray[0]);
                const inputYear = 2000 + parseInt(dateAsArray[1]);

                if (inputMonth < 1 || inputMonth > 12) {
                    return false;
                }

                if (inputYear > currentDate.getFullYear()) {
                    return true;
                } if (inputYear < currentDate.getFullYear()) {
                    return false;
                } if (inputYear === currentDate.getFullYear()) {
                    let findMonthIndex;
                    for (const month in availableMonth) {
                        if (availableMonth[month] === currentMonth) {
                            findMonthIndex = month;
                        }
                    }
                    return inputMonth > findMonthIndex;
                }
                return true;
            },
        },
        cardHolder: {
            isValidHolder(cardHolder) {
                if (cardHolder === null || !cardHolder) {
                    return true;
                }
                const regex = /^([A-Za-z]+[-]?)\w+ ([A-Za-z]+[-]?)\w+$/;
                if (!regex.test(cardHolder)) {
                    this.$v.cardHolder.$reset();
                }
                return regex.test(cardHolder);
            },
        },
        cardCountryCode: {
            required,
        },
        leadsCoins: {
            required,
            minValue(value) {
                const number = value ? value.replace(/\s+/g, '') : 0;
                return minValue(this.minValueLC)(number);
            },
            maxValue(value) {
                const number = value ? value.replace(/\s+/g, '') : 0;
                return maxValue(this.accountBalance)(number);
            },
        },
        agreement: {
            checkAgreement: value => value === true,
        },
        agreementPersonal: {
            checkAgreementPersonal: value => value === true,
        },
    },
    beforeMount() {
        const context = this;
        this.debounce_inputLC = _debounce(this.convertLeadsCoins, 500);
        this.debounce_checkEgg = _debounce(code => context.check(code).then(() => { context.showEgg = true; }), 500);
    },
    destroyed() {
        this.$v.$reset();
    },
    methods: {
        ...mapActions('account', [
            'loadBalance',
        ]),
        ...mapActions('outputLC', [
            'getCountries',
            'getConvertSum',
            'outputSum',
        ]),
        ...mapMutations('outputLC', [
            'setRubAmount',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        async outputLC() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const data = {
                cardNumber: this.cardNumber.replace(/\s+/g, ''),
                cardCountryCode: this.cardCountryCode.id,
                cardHolderName: this.cardHolder,
                cardExpireMonth: this.cardExpired.split('/')[0],
                cardExpireYear: this.cardExpired.split('/')[1],
                leadsCoins: Number(this.leadsCoins.replace(/\s+/g, '')),
                agreement: this.agreement,
                agreementPersonal: this.agreementPersonal,
            };
            const result = await this.outputSum(data);
            if (result) {
                this.loadBalance();
                this.visibleForm = false;
            }
        },
        countySelectInput(value) {
            this.getCountries(value);
        },
        selectClick() {
            this.cardCountryCode = null;
        },
        formatFixed(value) {
            return parseFloat(value).toFixed(2);
        },
        showForm() {
            this.visibleForm = true;

            this.cardNumber = '';
            this.cardCountryCode = '';
            this.cardHolder = '';
            this.cardExpired = '';
            this.leadsCoins = 0;
            this.setRubAmount(0);
            this.agreement = false;
            this.agreementPersonal = false;
            this.$v.$reset();
        },
        convertLeadsCoins(number) {
            this.getConvertSum(number);
        },
        collectEasterEgg() {
            this.collect(this.currentEasterEgg);
        },
    },
};
</script>

<style scoped>

</style>
