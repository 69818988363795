<template>
    <OmniCode />
</template>

<script>
import OmniCode from '@/components/omni/OmniCode.vue';

export default {
    name: 'OmniConnect',
    components: {
        OmniCode,
    },
};
</script>
