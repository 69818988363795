<template>
    <div class="clnt-instruments">
        <h2 class="clnt-instruments__title">
            Инструменты
        </h2>
        <vue-scroll :ops="scrollOptions">
            <div class="clnt-instruments__list">
                <div class="clnt-instruments__item"
                     :class="{'clnt-instruments__item_active': item.active}"
                     v-for="(item, index) in list"
                     :key="index">
                    <button class="clnt-instruments__header"
                            @click="toggleInfo(item)">
                        <span class="clnt-instruments__header-info">
                            <span class="clnt-instruments__header-title">{{ item.name }}</span>
                            <span class="clnt-instruments__header-text">{{ item.text }}</span>
                        </span>
                        <span class="clnt-instruments__header-img">
                            <img :src="require(`@/assets/images/instruments/${item.img}`)"
                                 alt="">
                        </span>
                    </button>
                    <div class="clnt-instruments__detail"
                         v-if="item.active">
                        <div class="clnt-instruments__description"
                             v-html="item.description" />
                        <div class="clnt-instruments__actions"
                             v-if="item.linkBot || item.linkCabinet">
                            <router-link v-if="item.linkCabinet"
                                         :to="item.linkCabinet"
                                         class="clnt-instruments__actions-item lds-btn lds-btn_fill -blue">
                                Смотреть
                            </router-link>
                            <a v-if="item.linkBot"
                               :href="item.linkBot"
                               target="_blank"
                               rel="noopener"
                               class="clnt-instruments__actions-item lds-btn lds-btn_fill -blue-dark">
                                Запустить бот
                            </a>
                            <a v-if="isTour3Egg5(item.name)"
                               @click="tour3Egg5Advance"
                               class="clnt-instruments__actions-item lds-btn lds-btn_fill -blue-dark">
                                Сократить затраты
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </vue-scroll>
    </div>
</template>

<script>
import Vue from 'vue';
import vuescroll from 'vuescroll/dist/vuescroll-native';
import {mapActions, mapGetters, mapState} from 'vuex';
import EasterEggCookies, {tour3Egg5ButtonClicked} from '@/models/EasterEggs/EasterEggCookies';
import {tour3egg5} from '@/models/EasterEggs/EasterEgg';

Vue.use(vuescroll);

export default {
    name: 'Instruments',
    data() {
        return {
            scrollOptions: {
                rail: {
                    background: '#F5F7F9',
                    size: '7px',
                    opacity: 1,
                },
                bar: {
                    background: '#C5C7D0',
                    size: '7px',
                    keepShow: true,
                    minSize: 0.2,
                },
            },
            list: [
                {
                    name: 'Офферы',
                    text: 'Приоритет в категории, размещение баннера в ЛК Вебмастера, "Мерч", размещение в Leads.Market ',
                    img: 'instrument-offers.png',
                    description: null,
                    linkCabinet: '/offers/',
                    linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                },
                {
                    name: 'Бонусы',
                    text: 'Баланс, задания и вознаграждения, Leads.Market',
                    img: 'instrument-bonuses.png',
                    description: '',
                    linkCabinet: '/loyalty/tasks/',
                    linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                },
                {
                    name: 'Leads.Market',
                    text: 'Магазин товаров, которые можно купить за Leads.Coins',
                    img: 'instrument-market.png',
                    description: '',
                    linkCabinet: '/market/',
                    linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                },
                {
                    name: 'Увеличения трафика',
                    text: 'Инструменты увеличения трафика: рейтинг, акции, анализ и разбор оффера',
                    img: 'instrument-traffic.png',
                    description: '',
                    linkCabinet: '',
                    linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                },
                // {
                //     name: 'Продвижение',
                //     text: 'Приоритет в категории, размещение баннера в ЛК Вебмастера, "Космонавт", размещение в Leads.Market ',
                //     img: 'instrument-promotion.png',
                //     description: '',
                //     linkCabinet: '',
                //     linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                // },
                {
                    name: 'Статистика',
                    text: 'CR / AR / EPC / EPL / рейтинг по своим офферам и офферам конкурентов',
                    img: 'instrument-statistics.png',
                    description: '',
                    linkCabinet: '',
                    linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                },
                {
                    name: 'Рейтинг',
                    text: 'Рейтинг, параметры расчета, улучшение рейтинга, "Олимпиада офферов"',
                    img: 'instrument-rating.png',
                    description: '',
                    linkCabinet: '',
                    linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                },
                {
                    name: 'Сокращение затрат',
                    text: 'Монетизация отказного трафика, спец. условия работы с рекламными сетями, TrafficBack',
                    img: 'instrument-reducing-costs.png',
                    description: '',
                    linkCabinet: '',
                    linkBot: 'https://t.me/leads_sales_lab_bot?start=lk',
                },
            ],
            tour3Egg5ButtonClicked: false,
        };
    },
    computed: {
        ...mapState('offers', [
            'offerList',
        ]),
        offerListCut() {
            return Object.values(this.offerList)
                .slice(0, 5);
        },
        tour3egg5() {
            return this.getAvailable(tour3egg5);
        },
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
    },
    mounted() {
        this.loadOffers();
        this.check(tour3egg5);
    },
    methods: {
        ...mapActions('offers', [
            'loadOffers',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'showNextStepModal',
        ]),
        toggleInfo(item) {
            this.list.forEach(item_ => {
                if (item !== item_) {
                    Vue.set(
                        item_, 'active', false,
                    );
                } else {
                    Vue.set(
                        item_, 'active', !item.active,
                    );
                }
            });
        },
        isTour3Egg5(itemName) {
            const buttonClicked = EasterEggCookies.getState(tour3Egg5ButtonClicked, false);
            return itemName === 'Сокращение затрат'
                && !this.tour3Egg5ButtonClicked
                && !buttonClicked
                && this.tour3egg5
                && this.tour3egg5.needShow;
        },
        tour3Egg5Advance() {
            EasterEggCookies.setState(tour3Egg5ButtonClicked, true);
            this.tour3Egg5ButtonClicked = true;
            this.showNextStepModal();
        },
    },
};
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
