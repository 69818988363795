<template>
    <div class="lds-container clnt-statistics-of-webmaster">
        <div class="clnt-statistics-of-webmaster__search-block">
            <h1 class="clnt-statistics-of-webmaster__title">
                Показатели по вебмастерам
            </h1>
            <form class="clnt-statistics-of-webmaster__form"
                  @submit.prevent="submitStatistics()">
                <div class="clnt-statistics-of-webmaster__select-offer-block">
                    <label class="lds-control__label">Выберите оффер</label>
                    <VSelect class="clnt-statistics-of-webmaster__select lds-select lds-select_size-sm"
                             placeholder="Оффер"
                             v-model="offerSelected"
                             :options="offers"
                             label="title"
                             @open="selectClick()"
                             @search="offerSelectInput" />
                </div>
                <div class="clnt-statistics-of-webmaster__date-block">
                    <label class="lds-control__label">Выберите период</label>
                    <div class="clnt-datepicker clnt-datepicker_sm">
                        <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_from"
                                     v-model="dateFrom"
                                     format="DD.MM.YYYY"
                                     :disabled-date="disabledRangeFrom"
                                     :lang="lang" />
                        <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_to"
                                     v-model="dateTo"
                                     format="DD.MM.YYYY"
                                     :disabled-date="disabledRangeTo"
                                     :lang="lang" />
                    </div>
                </div>
                <div class="clnt-statistics-of-webmaster__actions">
                    <div class="clnt-statistics-of-webmaster__action">
                        <button v-if="!statisticLoading"
                                class="lds-btn lds-btn_fill lds-btn_size-xs"
                                type="submit"
                                data-test="submit"
                                :disabled="disabledSubmit">
                            Сформировать
                        </button>
                        <div v-else
                             class="lds-btn lds-btn_fill lds-btn_size-xs lds-btn_loader">
                            <SvgIcon name="loader" />
                            Формируется
                        </div>
                    </div>
                    <div class="clnt-statistics-of-webmaster__action">
                        <button
                            v-if="!statisticExportLoading"
                            class="lds-btn lds-btn_fill -gray lds-btn_size-xs"
                            type="button"
                            :disabled="disabledSubmit"
                            data-test="export"
                            @click.prevent="exportStatistics()">
                            Экспорт
                        </button>
                        <div v-else
                             class="lds-btn lds-btn_fill lds-btn_loader -gray lds-btn_size-xs">
                            <SvgIcon name="loader" />
                            Экспорт
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div
            class="clnt-statistics-of-webmaster__stub"
            v-if="!tableOfWebmastersLoaded">
            <img src="@/assets/images/statistics/table-stat-offer.png"
                 alt>
            <div class="clnt-statistics-of-webmaster__describe">
                Выберите оффер, укажите период и нажмите кнопку сформировать, чтобы получить данные.
            </div>
        </div>
        <div v-else
             class="clnt-statistics-of-webmaster__table">
            <div class="clnt-statistics-of-webmaster__small-info">
                {{ smallInfoAboutOffer }}
            </div>
            <TableStatWebmasters  v-if="$store.state.statistics.statisticsWebInGrid.length > 0" />
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import moment from 'moment';
import {mapActions, mapState} from 'vuex';
import settingsDatePickerLang from '@/services/settingsDatePickerLang';
import getLastWeek from '@/services/getLastWeek';
import TableStatWebmasters from '@/components/statistics/TableStatWebmasters.vue';
import SvgIcon from '@/components/common/SvgIcon.vue';

export default {
    name: 'StatisticsOfWebmasters',
    components: {
        TableStatWebmasters,
        DatePicker,
        vSelect: vSelect,
        SvgIcon,
    },
    data() {
        return {
            dateFrom: getLastWeek.getLastMonday(),
            dateTo: getLastWeek.getLastSunday(),
            lang: settingsDatePickerLang,
            offerSelected: null,
            offer: {},
        };
    },
    computed: {
        ...mapState('statistics', [
            'offers',
            'statisticLoading',
            'tableOfWebmastersLoaded',
            'statisticExportLoading',
        ]),
        ...mapState('common', [
            'fromRout',
        ]),
        dateFromInfo() {
            return moment(this.dateFrom).format('DD-MM-YYYY');
        },
        dateToInfo() {
            return moment(this.dateTo).format('DD-MM-YYYY');
        },
        disabledSubmit() {
            return !this.dateFrom || !this.dateTo || !this.offerSelected;
        },
    },
    watch: {
        offerSelected(value) {
            if (!value) {
                this.getOffers();
            }
        },
    },
    beforeMount() {
        this.$store.state.statistics.tableOfWebmastersLoaded = false;
    },
    async created() {
        if (this.fromRout.meta.offerIdSave) {
            await this.getOffers(this.fromRout.params.offerID);
            this.offerSelected = this.offers[0];
            if (!this.disabledSubmit) {
                this.submitStatistics();
            }
        } else {
            this.getOffers();
        }
    },
    methods: {
        ...mapActions('statistics', [
            'getWebmasterStatisticsInGrid',
            'getOffers',
            'getExportStatisticOfWebmaster',
        ]),
        selectClick() {
            this.offerSelected = null;
        },
        disabledRangeFrom(date) {
            const dateMax = this.dateTo || new Date();
            return date > dateMax;
        },
        disabledRangeTo(date) {
            return date > new Date() || date < this.dateFrom;
        },
        offerSelectInput(value) {
            this.getOffers(value);
        },
        submitStatistics() {
            const data = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                offerId: this.offerSelected.id,
            };
            this.getWebmasterStatisticsInGrid(data);

            this.smallInfoAboutOffer = `Оффер ${this.offerSelected.title} за период ${this.dateFromInfo} по ${this.dateToInfo}`;
        },
        exportStatistics() {
            const data = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                offerId: this.offerSelected.id,
            };
            this.getExportStatisticOfWebmaster(data);
        },
    },
};
</script>

<style scoped>

</style>
