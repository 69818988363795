<template>
    <Product />
</template>

<script>
import Product from '@/components/market/MarketProduct';

export default {
    name: 'ProductDetail',
    components: {
        Product,
    },
};
</script>
