<template>
    <div>
        <template v-if="pagedTableData.length">
            <el-table
                :default-sort="{prop: ['CR', 'AR', 'EPL', 'EPC', 'rating'], order: 'descending'}"
                class="web-table"
                :cell-class-name="tableRowClassName"
                :header-cell-class-name="tableRowClassName"
                :data="pagedTableData">
                <el-table-column
                    prop="dimensionTitle"
                    label="Вебмастер"
                    width="160" />
                <el-table-column
                    prop="CR"
                    label="CR %"
                    sortable
                    width="100">
                    <template slot-scope="scope">
                        <div>
                            {{ formatPercentCR(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="AR"
                    label="AR %"
                    sortable
                    width="100">
                    <template slot-scope="scope">
                        <div>
                            {{ formatPercentAR(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="EPC"
                    label="EPC"
                    sortable
                    width="100">
                    <template slot-scope="scope">
                        <div>
                            {{ formatFixedEPC(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="EPL"
                    label="EPL"
                    sortable
                    width="100">
                    <template slot-scope="scope">
                        <div>
                            {{ formatFixedEPL(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="proportion"
                    label="Доля %"
                    sortable
                    width="120">
                    <template slot-scope="scope">
                        {{ formatPercentProportion(scope.row) }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="Действия"
                    width="200">
                    <template slot-scope="scope">
                        <button class="clnt-statistics-of-webmaster__cell-action"
                                @click="openHoldModal()">
                            Увеличить трафик
                        </button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination background
                               :page-sizes="countOfWebmastersSizes"
                               layout="prev, pager, next, sizes"
                               :total="statisticsWebInGrid.length"
                               :page-size="pageSize"
                               :current-page="currentPage"
                               @size-change="size_change"
                               @current-change="current_change" />
            </div>
        </template>
        <p v-else>
            Нет данных
        </p>
        <v-easy-dialog v-model="modalGradeHold">
            <div>
                <div class="clnt-statistics-of-webmaster__dialog">
                    <stub :mobile="true" />
                </div>
            </div>
        </v-easy-dialog>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import VEasyDialog from 'v-easy-dialog';
import Stub from '@/components/common/Stub';

export default {
    name: 'TableStatWebmasters',
    components: {
        Stub,
        VEasyDialog,
    },
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            total: 0,
            pageSize: 10,
            currentPage: 1,
            countOfWeb: null,
            countOfWebmastersSizes: [
                5, 10, 15, 20, 50, 100,
            ],
            modalGradeHold: false,
        };
    },
    computed: {
        ...mapState('statistics', [
            'statisticsWebInGrid',
            'statisticsWebInGridSort',
        ]),

        pagedTableData() {
            return this.statisticsWebInGrid.slice((this.currentPage-1) * this.pageSize, this.currentPage * this.pageSize);
        },
    },
    watch: {
        countOfWeb(val) {
            const currentVal = val === 'Более 20' ? this.statisticsWebInGrid.length : val;
            if (this.statisticsWebInGrid.length / (this.currentPage * this.pageSize) >= parseInt(currentVal)) {
                // eslint-disable-next-line no-return-assign
                return this.pageSize = parseInt(currentVal);
            }
            // eslint-disable-next-line no-return-assign
            if (parseInt(currentVal) < this.statisticsWebInGrid.length) {
                this.currentPage = this.statisticsWebInGrid.length / parseInt(currentVal);
            } else {
                this.currentPage = 1;
            }
            // eslint-disable-next-line no-return-assign
            return this.pageSize = parseInt(currentVal) > this.statisticsWebInGrid.length ? this.statisticsWebInGrid.length : parseInt(currentVal);
        },

    },
    methods: {
        ...mapMutations('statistics', [
            'setStatisticsWebInGrid',
        ]),
        tableRowClassName({columnIndex}) {
            if (columnIndex === 0) {
                return 'el-table__cell-bold';
            }
            if (columnIndex !== 0) {
                return 'el-table__cell-right';
            }
        },
        current_change: function (currentPage) {
            this.currentPage = currentPage;
        },
        size_change: function (pageSize) {
            this.pageSize = pageSize;
        },
        formatFixedEPL(value) {
            return parseFloat(Number(value.EPL)).toFixed(2);
        },
        formatFixedEPC(value) {
            return parseFloat(Number(value.EPC)).toFixed(2);
        },
        formatPercentAR(value) {
            return ((parseFloat(Number(value.AR)).toFixed(2)) * 100).toFixed(2);
        },
        formatPercentCR(value) {
            return ((parseFloat(Number(value.CR)).toFixed(2)) * 100).toFixed(2);
        },
        formatPercentProportion(value) {
            return ((parseFloat(Number(value.proportion)).toFixed(2)) * 100).toFixed(2);
        },
        openHoldModal() {
            this.modalGradeHold = true;
        },
    },
};
</script>

<style scoped>

</style>
