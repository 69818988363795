import Vue from 'vue';
import axios from 'axios';
import Offer from '@/models/Offer/Offer';
import OfferStatus from '@/models/Offer/OfferStatus';
import OfferCategory from '@/models/Offer/OfferCategory';
import OfferCategoryGrade from '@/models/Offer/OfferCategoryGrade';

const mutations = {
    setOfferList: (state, data) => {
        state.offerList = data;
    },
    setOffer: (state, data) => {
        state.offer = data;
    },
    setLoading: (state, data) => {
        state.loading = data;
    },
    setOfferNotFound: (state, data) => {
        state.offerNotFound = data;
    },
    setSentRatingRequest: (state, data) => {
        if (state.sentRatingRequest[data.offerId]) {
            if (state.sentRatingRequest[data.offerId][data.categoryId]) {
                Vue.set(
                    state.sentRatingRequest[data.offerId][data.categoryId], data.key, data,
                );
            } else {
                const dataCategory = {
                    [data.key]: data,
                };
                Vue.set(
                    state.sentRatingRequest[data.offerId], data.categoryId, dataCategory,
                );
            }
        } else {
            const dataOffer = {
                [data.categoryId]: {
                    [data.key]: data,
                },
            };
            Vue.set(
                state.sentRatingRequest, data.offerId, dataOffer,
            );
        }
    },
};

const getters = {
    isEmptyOfferList(state) {
        return !Object.values(state.offerList).length;
    },
};

const actions = {
    loadOffers: ({ commit, rootState }) => {
        commit('setLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/offers/offers/get', config)
            .then(response => {
                const result = response.data.data;
                const itemData = [];

                const filterResult = result.filter(item => item.status === 'active')
                    .concat(result.filter(item => item.status === 'pending'))
                    .concat(result.filter(item => item.status === 'paused'))
                    .concat(result.filter(item => item.status === 'deleted'));
                for (let i = 0; i < filterResult.length; i++) {
                    const item = filterResult[i];
                    itemData.push({
                        id: item.id,
                        name: item.name,
                        status: new OfferStatus(item.status),
                        categories: item.categories.map(category => new OfferCategory(
                            category.id,
                            category.name,
                            category.rating,
                            category.league,
                            category.grades,
                        )),
                    });
                }
                commit('setOfferList', itemData);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    loadOfferId: ({ commit, rootState }, id) => {
        commit('setLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/offers/offers/getOffer?id=${id}`, config)
            .then(response => {
                const result = response.data.data;
                commit('setOffer', new Offer(
                    result.id,
                    result.name,
                    new OfferStatus(result.status),
                    result.categories.map(item => new OfferCategory(
                        item.id,
                        item.name,
                        item.rating,
                        item.league,
                        item.grades.map(itemGrade => new OfferCategoryGrade(
                            itemGrade.key,
                            itemGrade.title,
                            itemGrade.value,
                            itemGrade.weight,
                        )),
                    )),
                ));
                commit('setOfferNotFound', false);
            })
            .catch(error => {
                if (error.response.status === 404) {
                    commit('setOfferNotFound', true);
                } else {
                    const dataNotification = {
                        value: error,
                        type: 'error',
                    };
                    commit(
                        'common/setNotification', dataNotification, { root: true },
                    );
                }
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    sendRatingRequest: ({ commit, rootState }, data) => {
        const dataHelpRequest = {
            type: data.type,
            subject: data.subject,
            offerId: data.offerId,
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .post(
                '/rest/offers/rating/helpRequest', dataHelpRequest, config,
            ).then(() => {
                const dataSuccess = {
                    key: data.type,
                    offerId: data.offerId,
                    categoryId: data.categoryId,
                    sent: true,
                    visible: true,
                };
                commit('setSentRatingRequest', dataSuccess);
                setTimeout(() => {
                    dataSuccess.visible = false;
                    commit('setSentRatingRequest', dataSuccess);
                }, 3000);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const state = () => ({
    offerList: {},
    offer: {},
    loading: false,
    offerNotFound: false,
    sentRatingRequest: {},
});

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
