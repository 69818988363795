<template>
    <div class="clnt-summary__table">
        <vue-scroll :ops="scrollOptions">
            <table>
                <thead>
                    <tr>
                        <th class="clnt-summary__table-cell-left" />
                        <th>CR %:</th>
                        <th>AR %:</th>
                        <th>EPL:</th>
                        <th>EPC:</th>
                        <th>Рейтинг:</th>
                        <th>Лига:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in summary"
                        :key="index"
                        :ref="`summary-item-${item.offerId}`"
                        class="clnt-summary__tr">
                        <td class="clnt-summary__table-cell-left">
                            <router-link :to="{
                                             name: 'Offer',
                                             params: {
                                                 offerID: item.offerId,
                                             },
                                         }"
                                         class="clnt-summary__link">
                                {{ item.offerTitle }}
                            </router-link>
                        </td>
                        <td>{{ formatPercent(item.CR) }}</td>
                        <td>{{ formatPercent(item.AR) }}</td>
                        <td>{{ formatFixed(item.EPL) }}</td>
                        <td>{{ formatFixed(item.EPC) }}</td>
                        <td class="clnt-summary__table-rating-cell">
                            {{ item.rating }}
                        </td>
                        <td>
                            <div class="clnt-summary__league"
                                 v-if="item.league.value">
                                <League :league="item.league.value"
                                        :ref="`summary-item-league-${item.offerId}`"
                                        @mouseenter="setClassLeaguePopover(item.offerId)" />
                            </div>
                            <template v-else>
                                -
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </vue-scroll>
    </div>
</template>

<script>
import Vue from 'vue';
import vuescroll from 'vuescroll/dist/vuescroll-native';
import League from '@/components/common/League';

Vue.use(vuescroll);

export default {
    name: 'TableSummary',
    components: {
        League,
    },
    props: {
        summary: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            scrollOptions: {
                rail: {
                    background: '#F5F7F9',
                    size: '7px',
                    opacity: 1,
                },
                bar: {
                    background: '#C5C7D0',
                    size: '7px',
                    keepShow: true,
                    minSize: 0.2,
                },
            },
        };
    },
    methods: {
        formatFixed(value) {
            return parseFloat(value).toFixed(2);
        },
        formatPercent(value) {
            return this.formatFixed(value * 100);
        },
        setClassLeaguePopover(ref) {
            const currentRefItem = this.$refs[`summary-item-${ref}`];
            const currentRefLeague = this.$refs[`summary-item-league-${ref}`];

            if (currentRefItem && currentRefLeague) {
                const elemTr = currentRefItem[0];
                const elemParent = elemTr.parentElement.clientHeight;
                const elemLeague = currentRefLeague[0].$el;
                const posY = elemTr.offsetTop;
                const heightPopover = elemLeague.lastChild.clientHeight;

                const className = posY + heightPopover >= elemParent
                    ? 'lds-popover_right-bottom'
                    : 'lds-popover_right-top';

                elemLeague.classList.add(className);
            }
            return 'lds-popover_right-top';
        },
    },
};
</script>

<style lang="scss">
.hasHBar .__panel {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hasHBar .__panel::-webkit-scrollbar {
    display: none;
    width: 0;
}
</style>
