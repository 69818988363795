<template>
    <ChangePasswordForm />
</template>

<script>
import ChangePasswordForm from '@/components/auth/ChangePasswordForm.vue';

export default {
    name: 'ChangePassword',
    components: {
        ChangePasswordForm,
    },
};
</script>
