const STATUS_CREATED = 'created';
const STATUS_PENDING = 'pending';
const STATUS_IN_DELIVERY = 'inProgress';
const STATUS_COMPLETED = 'completed';
const STATUS_CANCELLED = 'cancelled';

const TITLES = {
    [STATUS_CREATED]: 'Новый',
    [STATUS_PENDING]: 'В обработке',
    [STATUS_IN_DELIVERY]: 'В доставке',
    [STATUS_COMPLETED]: 'Выполнен',
    [STATUS_CANCELLED]: 'Отменен',
};

const CLASSES = {
    [STATUS_CREATED]: 'created',
    [STATUS_PENDING]: 'pending',
    [STATUS_IN_DELIVERY]: 'inProgress',
    [STATUS_COMPLETED]: 'completed',
    [STATUS_CANCELLED]: 'cancelled',
};

export default class OrderStatus {
    constructor(value) {
        this.value = value;
    }

    get title() {
        return TITLES[this.value] || null;
    }

    get class() {
        return CLASSES[this.value] || null;
    }
}
