<template>
    <div>
        <router-view v-if="detailed" />
        <NotFound v-else />
    </div>
</template>

<script>
import NotFound from '@/components/common/NotFound';

export default {
    name: 'Statistics',
    components: {
        NotFound,
    },
    computed: {
        detailed() {
            return this.$route.name !== 'Statistics';
        },
    },
};
</script>
