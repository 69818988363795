<template>
    <section class="clnt-article">
        <div class="clnt-article__grid">
            <div class="clnt-article__info">
                <h1 class="clnt-article__h1 lds-h1">
                    Реклама в Telegram-канале
                </h1>
                <p>
                    У Leads есть специальный Telegram-канал
                    <a href="https://t.me/leadssunews"
                       target="_blank"
                       rel="noopener">
                        @leadssunews
                    </a>
                    для Вебмастеров, где они следят за обновлениями офферов, новинками рынка, изменениями условий, акциях и т.д.
                    На данный момент в нем 800 подписчиков - PRO-вебмастеров.
                </p>
                <p>
                    Мы предлагаем размещение постов, продвигающих Ваш оффер.
                    Оставить заявку можно в чат-боте или в личном кабинете.
                </p>
                <div class="clnt-article__action">
                    <div class="lds-popover lds-popover_size-sm"
                         :class="{'lds-popover_active': isVisiblePopover(key)}">
                        <button class="lds-btn lds-btn_fill -blue"
                                @click="sendRequest"
                                :disabled="isDisabledBtn(key)">
                            Отправить заявку
                        </button>
                        <div class="lds-popover__info">
                            Спасибо. Заявка принята. В ближайшее время с Вами свяжется наш менеджер.
                        </div>
                    </div>
                </div>
            </div>
            <div class="clnt-article__img">
                <img src="@/assets/images/promotion/promo_tlgrm.png"
                     alt="Реклама в Telegram-канале">
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'Telegram',
    data() {
        return {
            key: 'telegram',
        };
    },
    computed: {
        ...mapState('promotion', [
            'sentPromotion',
        ]),
        ...mapGetters('promotion', [
            'isDisabledBtn',
            'isVisiblePopover',
        ]),
    },
    methods: {
        ...mapActions('promotion', [
            'featureRequest',
        ]),
        sendRequest() {
            const data = {
                key: this.key,
                feature: 'Заявка на подключение к Telegram-каналу',
            };
            this.featureRequest(data);
        },
    },
};
</script>
