<template>
    <section class="clnt-history">
        <div class="lds-container clnt-history__container">
            <form class="clnt-history__form"
                  @submit.prevent="loadData()">
                <h1 class="clnt-history__title">
                    История баланса
                </h1>
                <div class="clnt-history__control">
                    <label class="clnt-history__control-label"
                           for="id">
                        Введите ID операции
                    </label>
                    <input id="id"
                           type="number"
                           class="lds-input lds-input_size-sm"
                           placeholder="ID операции"
                           v-model="id">
                </div>
                <div class="clnt-history__control">
                    <label class="clnt-history__control-label">Тип операции</label>
                    <VSelect class="lds-select lds-select_size-sm"
                             v-model="type"
                             placeholder="Тип"
                             :options="operationsTypes"
                             :filterable="false">
                        <template slot="no-options">
                            Поиск...
                        </template>
                        <template slot="option"
                                  slot-scope="option">
                            <div>
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option"
                                  slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </VSelect>
                </div>
                <div class="clnt-history__control">
                    <label class="clnt-history__control-label">Выберите период</label>
                    <div class="clnt-history__period clnt-datepicker clnt-datepicker_full clnt-datepicker_without-clean">
                        <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_from"
                                     v-model="dateFrom"
                                     format="DD.MM.YYYY"
                                     :disabled-date="disabledRangeFrom"
                                     :lang="lang" />
                        <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_to"
                                     v-model="dateTo"
                                     format="DD.MM.YYYY"
                                     :disabled-date="disabledRangeTo"
                                     :lang="lang" />
                    </div>
                </div>
                <div class="clnt-history__actions">
                    <button v-if="!accrualHistoryLoading"
                            class="lds-btn lds-btn_fill lds-btn_size-xs"
                            :disabled="disableSubmit"
                            type="submit">
                        Применить
                    </button>
                    <div v-else
                         class="lds-btn lds-btn_fill lds-btn_size-xs lds-btn_loader">
                        <SvgIcon name="loader" />
                        Применить
                    </div>
                </div>
            </form>
            <div v-if="accrualHistory"
                 class="clnt-history__table">
                <AccrualHistoryTable :info="accrualHistory" />
                <div class="clnt-history__footer">
                    <div v-if="visiblePagination"
                         class="clnt-history__pagination lds-pagination">
                        <button
                            class="lds-pagination__btn lds-pagination__btn_prev"
                            aria-label="Предыдущая страница"
                            :disabled="currentPage < 2"
                            @click="prevCurrentPage()" />
                        <div class="lds-pagination__btn lds-pagination__btn_current">
                            {{ currentPage }}
                        </div>
                        <button
                            class="lds-pagination__btn lds-pagination__btn_next"
                            aria-label="Следующая страница"
                            :disabled="!accrualHistory.hasMore"
                            @click="nextCurrentPage()" />
                    </div>
                    <VSelect class="clnt-history__per-page lds-select lds-select_size-sm"
                             v-model="perPage"
                             :options="perPageOptions"
                             :searchable="false"
                             :append-to-body="true" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import SvgIcon from '@/components/common/SvgIcon.vue';
import AccrualHistoryTable from '@/components/market/AccrualHistoryTable.vue';
import getLastWeek from '@/services/getLastWeek';
import settingsDatePickerLang from '@/services/settingsDatePickerLang';

export default {
    name: 'AccrualHistory',
    components: {
        DatePicker,
        vSelect: vSelect,
        SvgIcon,
        AccrualHistoryTable,
    },
    data() {
        return {
            id: '',
            type: '',
            currentPage: 1,
            perPage: {
                label: '10',
            },
            perPageOptions: [
                {
                    label: '10',
                },
                {
                    label: '50',
                },
                {
                    label: '100',
                },
            ],
            dateFrom: getLastWeek.getLastMonday(),
            dateTo: new Date(),
            lang: settingsDatePickerLang,
        };
    },
    computed: {
        ...mapState('market', [
            'accrualHistory',
            'accrualHistoryLoading',
            'operationsTypes',
        ]),
        visiblePagination() {
            return !(!this.accrualHistory.hasMore && this.currentPage === 1);
        },
        disableSubmit() {
            return !(this.dateFrom && this.dateTo);
        },
    },
    watch: {
        currentPage() {
            this.loadData();
        },
        perPage() {
            this.currentPage = 1;
            this.loadData();
        },
    },
    created() {
        this.loadData();
        this.getOperationsTypes();
    },
    methods: {
        ...mapActions('market', [
            'getAccrualHistory',
            'getOperationsTypes',
        ]),
        disabledRangeFrom(date) {
            const dateMax = this.dateTo || new Date();
            return date > dateMax;
        },
        disabledRangeTo(date) {
            return date > new Date() || date < this.dateFrom;
        },
        loadData() {
            if (!this.disableSubmit) {
                const data = {
                    page: this.currentPage,
                    perPage: this.perPage.label,
                    dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                };
                if (this.id) {
                    data.id = this.id;
                }
                if (this.type) {
                    data.type = this.type.id;
                }

                this.getAccrualHistory(data);
            }
        },
        prevCurrentPage() {
            this.currentPage--;
        },
        nextCurrentPage() {
            this.currentPage++;
        },
    },
};
</script>
