<template>
    <v-easy-dialog v-if="tallyRound"
                   v-model="showModal">
        <div>
            <div class="clnt-easter-dialog">
                <button class="clnt-easter-dialog__close"
                        @click="closeModal">
                    <SvgIcon name="close" />
                </button>
                <div class="clnt-easter-dialog__title -top">
                    {{ tallyRound.title }} завершен
                </div>

                <div class="clnt-easter-dialog__cover">
                    <img src="@/assets/images/easterEggs/easter_egg_modal_cover_end_round.png">
                </div>

                <div class="clnt-easter-dialog__subtitle accent">
                    Подведем итоги и вручим награды на вебинаре, который проведем в период с 20 по 25 декабря)
                </div>

                <div class="clnt-easter-dialog__action">
                    <div class="lds-popover lds-popover_size-sm">
                        <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                @click="closeAndGoToEasterPage">
                            Перейти на страницу розыгрыша
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </v-easy-dialog>
</template>

<script>

import {
    mapState,
} from 'vuex';
import VEasyDialog from 'v-easy-dialog';
import metaMixin from '@/mixins/metaMixin';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'EasterEggsEndRoundModal',
    components: {
        SvgIcon,
        VEasyDialog,
    },
    mixins: [
        metaMixin,
    ],
    data() {
        return {
            showModalData: true,
        };
    },
    computed: {
        ...mapState('easterEggs', [
            'tallyRound',
        ]),
        showModal: {
            set() {
            },
            get() {
                return this.showModalData && this.tallyRound && !window.localStorage.getItem(this.tallyRound.id + '_round_end_shown');
            },
        },
    },
    methods: {
        closeModal() {
            this.showModalData = false;
            window.localStorage.setItem(this.tallyRound.id + '_round_end_shown', true);
        },
        closeAndGoToEasterPage() {
            this.closeModal();
            if (this.$router.currentRoute.path !== '/easterEggsChallenge') {
                return this.$router.push('/easterEggsChallenge');
            }
        },
    },
};
</script>
