<template>
    <section class="clnt-article">
        <div class="clnt-article__grid">
            <div class="clnt-article__info">
                <h1 class="clnt-article__h1 lds-h1">
                    Авто-воронка #COSMO
                </h1>
                <p>
                    Запустите специального <b>помощника-космонавта</b>, который появляется в личном кабинете Вебмастера и может дать ему советы по подключению офферов.
                </p>
                <p>
                    Можно запустить скрипт с почти любыми креативами и данными!
                </p>
                <p>
                    <b>CTR авто-воронки по результатам тестов составил 18.5%</b>, это отличный инструмент для привлечения Вебмастеров на ваш оффер.
                </p>
                <div class="clnt-article__action">
                    <div class="lds-popover lds-popover_size-sm"
                         :class="{'lds-popover_active': isVisiblePopover(key)}">
                        <button class="lds-btn lds-btn_fill -blue"
                                @click="sendRequest"
                                :disabled="isDisabledBtn(key)">
                            Отправить заявку
                        </button>
                        <div class="lds-popover__info">
                            Спасибо. Заявка принята. В ближайшее время с Вами свяжется наш менеджер.
                        </div>
                    </div>
                </div>
            </div>
            <div class="clnt-article__img">
                <img src="@/assets/images/promotion/cosmo.png"
                     alt="Авто-воронка #COSMO">
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'Cosmo',
    data() {
        return {
            key: 'cosmo',
        };
    },
    computed: {
        ...mapState('promotion', [
            'sentPromotion',
        ]),
        ...mapGetters('promotion', [
            'isDisabledBtn',
            'isVisiblePopover',
        ]),
    },
    methods: {
        ...mapActions('promotion', [
            'featureRequest',
        ]),
        sendRequest() {
            const data = {
                key: this.key,
                feature: 'Заявка подключение Авто-воронки #COSMO',
            };
            this.featureRequest(data);
        },
    },
};
</script>
