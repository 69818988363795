<template>
    <div>
        <template v-if="dataTable.length">
            <el-table
                :default-sort="{prop: ['CR', 'AR', 'EPL', 'EPC', 'rating'], order: 'descending'}"
                class="web-table"
                :cell-class-name="tableRowClassName"
                :header-cell-class-name="tableRowClassName"
                :data="dataTable"
                ref="table">
                <el-table-column
                    prop="offerTitle"
                    label="Оффер"
                    width="160" />
                <el-table-column
                    prop="categoryName"
                    label="Категория"
                    width="200" />
                <el-table-column
                    prop="CR"
                    label="CR %"
                    sortable
                    width="90">
                    <template slot-scope="scope">
                        <div>
                            {{ formatPercentCR(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="AR"
                    label="AR %"
                    sortable
                    width="90">
                    <template slot-scope="scope">
                        <div>
                            {{ formatPercentAR(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="EPC"
                    label="EPC"
                    sortable
                    width="90">
                    <template slot-scope="scope">
                        <div>
                            {{ formatFixedEPC(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="EPL"
                    label="EPL"
                    sortable
                    width="90">
                    <template slot-scope="scope">
                        <div>
                            {{ formatFixedEPL(scope.row) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="rating"
                    label="Рейтинг"
                    sortable
                    width="100" />
                <el-table-column
                    prop="league"
                    label="Лига"
                    width="60">
                    <template slot-scope="scope">
                        <League v-if="scope.row.league.value"
                                :league="scope.row.league.value"
                                :ref="`item-league-${getOfferId(scope.row.offerTitle)}`"
                                @mouseenter="setClassLeaguePopover(getOfferId(scope.row.offerTitle))" />
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination background
                               :page-sizes="countOfWebmastersSizes"
                               layout="prev, pager, next, sizes"
                               :total="statisticsInGrid.length"
                               :page-size="pageSize"
                               :current-page="currentPage"
                               @size-change="size_change"
                               @current-change="current_change" />
            </div>
        </template>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import League from '@/components/common/League.vue';

export default {
    name: 'TableOfOffers',
    components: {
        League,
    },
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            total: 0,
            pageSize: 10,
            currentPage: 1,
            countOfWebmastersSizes: [
                5, 10, 15, 20, 50, 100,
            ],
        };
    },
    computed: {
        ...mapState('statistics', [
            'statisticsInGrid',
        ]),
        dataTable() {
            return this.statisticsInGrid.slice((this.currentPage-1) * this.pageSize, this.currentPage * this.pageSize);
        },
    },
    methods: {
        tableRowClassName({columnIndex}) {
            if (columnIndex === 0) {
                return 'el-table__cell-bold';
            }
            if (columnIndex !== 0 && columnIndex !== 1) {
                return 'el-table__cell-right';
            }
        },
        current_change: function (currentPage) {
            this.currentPage = currentPage;
        },
        size_change: function (pageSize) {
            this.pageSize = pageSize;
        },
        formatFixedEPL(value) {
            return parseFloat(Number(value.EPL)).toFixed(2);
        },
        formatFixedEPC(value) {
            return parseFloat(Number(value.EPC)).toFixed(2);
        },
        formatPercentAR(value) {
            return ((parseFloat(Number(value.AR)).toFixed(2)) * 100).toFixed(2);
        },
        formatPercentCR(value) {
            return ((parseFloat(Number(value.CR)).toFixed(2)) * 100).toFixed(2);
        },
        getOfferId(title) {
            return title.split(',')[0];
        },
        setClassLeaguePopover(ref) {
            const refTable = this.$refs.table;
            const currentRefLeague = this.$refs[`item-league-${ref}`];

            if (currentRefLeague) {
                const elemTable = refTable.$el;
                const elemTablePosition = elemTable.getBoundingClientRect().top + window.scrollY;
                const elemTableHeight = elemTable.clientHeight;
                const elemTablePositionBottom = elemTablePosition + elemTableHeight;

                const elemLeague = currentRefLeague.$el;
                const elemLeaguePosition = elemLeague.getBoundingClientRect().top + window.scrollY;
                const elemLeagueHeightPopover = elemLeague.lastChild.clientHeight;
                const elemLeaguePositionBottom = elemLeaguePosition + elemLeagueHeightPopover;

                const className = elemTablePositionBottom > elemLeaguePositionBottom
                    ? 'lds-popover_right-top'
                    : 'lds-popover_right-bottom';

                elemLeague.classList.add(className);
            }
        },
    },
};
</script>

<style scoped>

</style>
