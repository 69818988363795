<template>
    <div class="lds-container">
        <div class="clnt-feedback">
            <h1 class="clnt-feedback__title">
                Обратная связь
            </h1>
            <div class="clnt-feedback__text">
                Расскажите о своем опыте использования системы,
                предложите улучшения, задайте вопрос, или поделитесь проблемой
            </div>
            <form class="clnt-feedback__form"
                  @submit.prevent="submitFeedback()">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.theme.$error}">
                    <vSelect class="lds-select"
                             placeholder="Выберите тему"
                             v-model="theme"
                             :options="themeList"
                             :reduce="type => type.value" />
                    <div class="lds-control__message"
                         v-if="$v.theme.$error">
                        Поле обязательно для заполнения
                    </div>
                </div>
                <div class="lds-control round-2-easter-egg-7-container"
                     :class="{'lds-control_error': $v.message.$error}">
                    <div
                        v-if="showingEasterEgg"
                        class="round-2-easter-egg-7">
                        <a
                            href="#"
                            @click.prevent="collectEasterEgg">
                            Ваша пасхалка
                        </a>
                    </div>
                    <textarea-autosize id="comment"
                                       name="comment"
                                       class="lds-input clnt-feedback__textarea"
                                       placeholder="Ваше предложение"
                                       v-model.trim="message"
                                       rows="4"
                                       @click.native="showEasterEgg"
                                       @change="$v.message.$touch()" />
                    <div class="lds-control__message"
                         v-if="$v.message.$error">
                        Поле обязательно для заполнения
                    </div>
                </div>
                <div class="clnt-feedback__actions">
                    <button class="clnt-feedback__action lds-btn lds-btn_fill -blue"
                            :disabled="sending"
                            type="submit">
                        Отправить
                    </button>
                    <router-link class="clnt-feedback__action lds-btn lds-btn_fill lds-btn_ico -gray"
                                 :to="linkBack">
                        <SvgIcon name="arrow-left" />
                        Вернуться
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import vSelect from 'vue-select';
import TextareaAutosize from 'vue-textarea-autosize';
import { required } from 'vuelidate/lib/validators';
import SvgIcon from '@/components/common/SvgIcon';
import { FeedbackThemeList, TYPE_OTHER } from '@/models/Feedback/FeedbackThemeList';
import {tour2egg7} from '@/models/EasterEggs/EasterEgg';

Vue.use(TextareaAutosize);

export default {
    name: 'Feedback',
    components: {
        vSelect,
        SvgIcon,
    },
    data() {
        return {
            theme: null,
            message: '',
            sending: false,
            showingEasterEgg: false,
        };
    },
    computed: {
        ...mapState('common', [
            'fromRout',
        ]),
        themeList() {
            return FeedbackThemeList.options();
        },
        linkBack() {
            return this.fromRout.path;
        },
        easterEgg() {
            return this.getAvailable(tour2egg7);
        },
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
    },
    validations: {
        theme: {
            required,
        },
        message: {
            required,
        },
    },
    created() {
        const routePageCode = this.fromRout && this.fromRout.matched.find(item => item.meta.pageCode);
        this.theme = routePageCode ? routePageCode.meta.pageCode : TYPE_OTHER;

        this.check(tour2egg7);
    },
    methods: {
        ...mapActions('feedback', [
            'sendFeedback',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        async submitFeedback() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const data = {
                    message: this.message,
                    theme: FeedbackThemeList.getTitle(this.theme),
                };
                this.sending = true;
                const result = await this.sendFeedback(data);
                this.sending = false;
                if (result) {
                    this.message = '';
                    this.$v.$reset();
                }
            }
        },
        showEasterEgg() {
            if (this.easterEgg) {
                this.showingEasterEgg = true;
            }
        },
        collectEasterEgg() {
            this.collect(this.easterEgg).then(() => {
                this.showingEasterEgg = false;
            });
        },
    },
};
</script>

<style scoped>
.round-2-easter-egg-7-container {
    position: relative;
}
.round-2-easter-egg-7 {
    position: absolute;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6a6e7e;
    top: 9px;
    left: 12px;
    background: #FFFFFF;
    width: 95%;
}
</style>
