export default class Category {
    constructor(
        id, name, status, description, created, modified,
    ) {
        this.id = id;
        this.name = name;
        this.status = status;
        this.description = description;
        this.created = created;
        this.modified = modified;
    }
}
