<template>
    <div class="lm-cart">
        <Loader v-if="loading" />
        <template v-else>
            <div v-if="!isEmpty">
                <div class="lm-cart__item"
                     v-for="(item, name, index) in cart.cartPositions"
                     :key="item.id">
                    <router-link class="lm-cart__item-img"
                                 :to="{
                                     name: 'MarketProduct',
                                     params: {
                                         productId: item.product.id,
                                     },
                                 }">
                        <img
                            v-if="item.product.mainImage.path"
                            :src="item.product.imageSrc"
                            :alt="item.product.name">
                    </router-link>
                    <div class="lm-cart__item-info">
                        <router-link class="lm-cart__item-title"
                                     :to="{
                                         name: 'MarketProduct',
                                         params: {
                                             productId: item.product.id,
                                         },
                                     }">
                            {{ item.product.name }}
                        </router-link>
                        <div class="lm-cart__item-description">
                            {{ item.product.shortDescription }}
                        </div>
                        <div class="lm-cart__item-cost">
                            <div>
                                <div class="lm-cart__item-price">
                                    {{ formatCoins(item.sumPrice) }}
                                    <SvgIcon name="leads-coins" />
                                </div>

                                <div class="lm-cart__item-price -old"
                                     v-if="item.oldSalePrice">
                                    {{ formatCoins(item.sumOldPrice) }}
                                </div>
                            </div>

                            <div class="lm-cart__item-controls">
                                <div class="lm-cart__item-count">
                                    <div class="lm-count lm-count_sm">
                                        <button class="lm-count__btn lm-count__btn_minus"
                                                @click="decrementCountCurrent(item)">
                                            -
                                        </button>
                                        <input
                                            class="lm-count__input"
                                            type="number"
                                            @change="changeCountCurrent(item, index)"
                                            v-model="cartItemsCount[index]">
                                        <button class="lm-count__btn lm-count__btn_plus"
                                                @click="incrementCountCurrent(item)">
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div class="lm-cart__item-amount">
                                    {{ formatCoins(item.salePrice) }} <SvgIcon name="leads-coins" /> / шт.
                                </div>
                                <button class="lm-cart__item-delete"
                                        @click="removeFromCartCurrent(item.product)">
                                    <SvgIcon name="trash" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lm-cart__message"
                 v-if="isEmpty">
                Корзина пуста
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@/components/common/Loader';
import SvgIcon from '@/components/common/SvgIcon.vue';
import formattingCoins from '@/mixins/formattingCoins';

export default {
    name: 'MarketCart',
    components: {
        Loader,
        SvgIcon,
    },
    mixins: [
        formattingCoins,
    ],
    data() {
        return {
            cartItemsCount: [],
        };
    },
    computed: {
        ...mapState('market', [
            'loading',
            'cart',
        ]),
        isEmpty() {
            return this.cart.isEmpty;
        },
    },
    watch: {
        cart: {
            handler() {
                this.updateCount();
            },
            deep: true,
        },
    },
    mounted() {
        this.updateCount();
    },
    methods: {
        ...mapActions('market', [
            'incrementCount',
            'decrementCount',
            'changeCount',
            'removeFromCart',
        ]),
        incrementCountCurrent(elem) {
            this.incrementCount(elem.product);
        },
        decrementCountCurrent(elem) {
            const data = {
                product: elem.product,
                remove: false,
            };
            this.decrementCount(data);
        },
        changeCountCurrent(elem, index) {
            let value = parseInt(this.cartItemsCount[index]);
            if (!value) {
                value = 1;
            }
            if (value < 0) {
                value = value * -1;
            }

            if (this.cartItemsCount[index] !== value) {
                this.updateCount();
            } else {
                const data = {
                    product: elem.product,
                    value: value,
                };
                this.changeCount(data);
            }
        },
        updateCount() {
            this.cartItemsCount = [];
            const data = Object.values(this.cart.cartPositions);
            for (let i = 0; i < data.length; i++) {
                this.cartItemsCount.push(data[i].count);
            }
        },
        removeFromCartCurrent(elem) {
            this.removeFromCart(elem.id);
        },
    },
};
</script>
