import axios from 'axios';

const mutations = {
    setLoadCountries: (state, data) => {
        state.countries = data;
    },
    setRubAmount: (state, data) => {
        state.rubAmount = data;
    },
    setProcessing: (state, processing) => {
        state.processing = processing;
    },
};

const actions = {
    outputSum: ({ commit, rootState }, data) => {
        commit('setProcessing', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.post(
            '/rest/market/payout/request', data, config,
        )
            .then()
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setProcessing', false);
            });
    },
    getCountries: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        const query = data ? `?q=${data}` : '';
        return axios
            .get(`/rest/market/payout/countries${query}`, config)
            .then(response => {
                commit('setLoadCountries', response.data);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getConvertSum: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };

        return axios
            .get(`/rest/market/payout/convert?coins=${data}`, config)
            .then(response => {
                commit('setRubAmount', parseFloat(response.data.rubAmount).toFixed(2));
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};
const state = {
    countries: [],
    rubAmount: 0,
    processing: false,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
