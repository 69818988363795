<template>
    <div class="clnt-loader">
        <span />
        <span />
        <span />
        <span />
    </div>
</template>

<script>
export default {
    name: 'Loader',
};
</script>
