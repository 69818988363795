const KEY_HOLD = 'hold';
const KEY_PAYMENT = 'payment';
const KEY_REPUTATION = 'reputation';
const KEY_AUTOSYNC = 'auto_sync';
const KEY_ACTIONS = 'actions';
const KEY_EPC = 'epc';

const DESCRIPTION = {
    [KEY_HOLD]: 'Время, через которое одобренная конверсия пополнит баланс (дни)',
    [KEY_PAYMENT]: 'Показывает своевременность оплаты оказанных услуг',
    [KEY_REPUTATION]: 'Показывает надежность и бесперебойность работы оффера',
    [KEY_AUTOSYNC]: 'Показывает насколько быстро и полно поступает данные о конверсиях',
    [KEY_ACTIONS]: 'Участие в Акции "Endorfin" или в Акциях по SubID',
    [KEY_EPC]: 'Показывает сколько в среднем зарабатывают Вебмастеры за 1 клик',
};

const ACTION_DESCRIPTION = {
    [KEY_HOLD]: 'Оставьте заявку на сокращение HOLD',
    [KEY_PAYMENT]: 'Оставьте заявку на повышение платежного рейтинга',
    [KEY_REPUTATION]: 'Оставьте заявку на повышение репутации / надежности оффера',
    [KEY_AUTOSYNC]: 'Оставьте заявку на повышение частоты актуализации статистики',
    [KEY_ACTIONS]: 'Оставьте заявку на участие в акциях Leads',
    [KEY_EPC]: 'Оставьте заявку на увеличение выплаты по офферу',
};

const DESCRIPTION_FULL = {
    [KEY_HOLD]: 'HOLD - это время, за которое одобренная конверсия должна быть проверена, а заработок по ней - доступен Вебмастеру (по-умолчанию HOLD составляет  14 дней, у лучших офферов HOLD -  3 дня)<br>' +
    '<br>' +
    'Соответственно, чем выше HOLD - тем дольше Вебмастер получает заработок, а чем ниже HOLD - тем чаще и быстрее ⚡️ Вебмастер получает заработок, а следовательно оборачивает эти средства и 🚀 увеличивает трафик.<br>' +
    '<br>' +
    'Сокращение холда может привести к кратному увеличению оборачиваемости и, следовательно, увеличению трафика 🤩<br>' +
    '<br>' +
    '👀 HOLD возможно устанавливать' +
    ' персонально ' +
    'для тех Вебмастеров, которые дают проверенный, качественный трафик',
    [KEY_PAYMENT]: '💳 Платежный рейтинг показывает, насколько уверенным можно быть, что заработок, который желает получить Вебмастер будет поступать вовремя и в полном объеме. <br>' +
    '<br>' +
    '⚜️ Более того, ТОП-Вебмастеры рассчитывают на большой объем обеспечения, т.к. льют большой объем трафика. Если у него не уверенности, что большой объем трафика будет надежно оплачен - то Вебмастер может не пойти на такой риск<br>' +
    '<br>' +
    '👔 LEADS, в свою очередь так же получает возможность привлечь ТОП-Вебмастеров, если платежный рейтинг высокий и доступны средства на обеспечение увеличенного объема',
    [KEY_REPUTATION]: '🔰 Вебмастеры дают больший объем на те офферы, в которых уверенны. <br>' +
    '<br>' +
    '👀 Если оффер неожиданно останавливается, или происходят сбои - Вебмастер несет прямые потери и его доверие снижается. И он сокращает объемы.<br>' +
    '<br>' +
    '🚀 Чтобы получить больше трафика - обеспечьте высокий уровень Надежности и Репутации Вашего оффера',
    [KEY_AUTOSYNC]: 'Актуальная статистика помогает Вебмастерам лучше понимать, как работают их площадки и вовремя производить улучшения.<br>' +
    '<br>' +
    '🤔 Если статистика поступает к ним слишком поздно, то их риски увеличиваются и они менее охотно увеличивают объем трафика на оффер, предпочитая держать его на среднем и низком уровнях.<br>' +
    '<br>' +
    '❗️В некоторых случаях ТОП-Вебмастеры вовсе не рассматривают оффер для работы, если статистика доставляется недостаточно оперативно.<br>' +
    '<br>' +
    '🤓 Вывод:<br>' +
    'Чем чаще статистика поступает в систему - тем больше ТОП-Вебмастеров льют на оффер  🚀',
    [KEY_ACTIONS]: 'Участие в Акции "Endorfin" или в Акциях по SubID  это уникальная возможность которая позволит увеличить доверие к вашим офферам и повысить трафик.',
    [KEY_EPC]: 'EPC - один из базовых показателей, на который обращают внимание Вебмастеры. Все просто - чем больше заработка с трафика, тем больше объема хочется дать 🤩<br>' +
    '<br>' +
    'EPC - это результат деления заработка Вебмастера на количество кликов, которые привели к этому заработку.<br>' +
    '<br>' +
    'Это значит, что, чтобы поднять EPC - можно принять как минимум две меры:<br>' +
    '<br>' +
    '📊 Увеличить AR (Approval Rate)<br>' +
    '<br>' +
    '🏷️ Увеличить заработок Вебмастера - подняв выплату',
};

const SUBJECT = {
    [KEY_HOLD]: '💻 Заявка на сокращение холда',
    [KEY_PAYMENT]: '💻 Заявка на повышение платежного рейтинга',
    [KEY_REPUTATION]: '💻 Заявка на повышение репутации',
    [KEY_AUTOSYNC]: '💻 Заявка на актуализацию статистики',
    [KEY_ACTIONS]: '💻 Заявка на участие в акциях',
    [KEY_EPC]: '💻 Заявка на epc',
};

export default class OfferCategoryGrade {
    /**
     *
     * @param key
     * @param title
     * @param value
     * @param weight
     */

    constructor(
        key, title, value, weight,
    ) {
        this.key = key;
        this.title = title;
        this.value = value;
        this.weight = weight;
    }

    get description() {
        return DESCRIPTION[this.key] || null;
    }

    get descriptionFull() {
        return DESCRIPTION_FULL[this.key] || null;
    }

    get descriptionAction() {
        return ACTION_DESCRIPTION[this.key] || null;
    }

    get subject() {
        return SUBJECT[this.key] || null;
    }
}
