<template>
    <div class="lm-price-filter">
        <div class="lm-price-filter__label">
            Цена:
        </div>
        <div class="lm-price-filter__controls">
            <div class="lm-price-filter__item">
                <span class="lm-price-filter__price-label">От</span>
                <input type="text"
                       v-model="priceFromFormat"
                       v-finance-format="priceFromFormat"
                       class="lm-price-filter__input-from"
                       @input="routeQueryChange()">
                <span class="lm-price-filter__price-unit">К</span>
            </div>
            <div class="lm-price-filter__item">
                <span class="lm-price-filter__price-label">До</span>
                <input type="text"
                       v-model="priceToFormat"
                       v-finance-format="priceToFormat"
                       class="lm-price-filter__input-to"
                       @input="routeQueryChange()">
                <span class="lm-price-filter__price-unit">К</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import serializeMixin from '@/mixins/serializeMixin';

export default {
    name: 'MarketFilters',
    directives: {
        financeFormat: {
            update: function (el, binding) {
                const value = binding.value;
                if (value === '') {
                    return;
                }
                const formatter = new Intl.NumberFormat('ru-RU');
                el.value = formatter.format(value);
            },
        },
    },
    mixins: [
        serializeMixin,
    ],
    computed: {
        ...mapState('market', [
            'priceFrom',
            'priceTo',
        ]),
        priceFromFormat: {
            get() {
                return this.priceFrom;
            },
            set(value) {
                value = value.replace(/[^0-9]/g, '');
                if (value === this.priceFrom) {
                    value+= ' ';
                }
                this.setPriceFrom(value);
            },
        },
        priceToFormat: {
            get() {
                const priceTo = this.priceTo;
                if (typeof priceTo === 'string') {
                    return priceTo.trim();
                }
                return priceTo;
            },
            set(value) {
                value = value.replace(/[^0-9]/g, '');
                if (value === this.priceTo) {
                    value += ' ';
                }
                this.setPriceTo(value);
            },
        },
    },
    mounted() {
        this.setPriceFrom('');
        this.setPriceTo('');
        if (this.$route.query.priceTo) {
            this.setPriceTo(Number(this.$route.query.priceTo));
        }
        if (this.$route.query.priceFrom) {
            this.setPriceFrom(Number(this.$route.query.priceFrom));
        }
    },
    methods: {
        ...mapMutations('market', [
            'setPriceFrom',
            'setPriceTo',
        ]),
        routeQueryChange() {
            const query = {};
            if (this.priceFromFormat) {
                query.priceFrom = this.priceFromFormat;
            }
            if (this.priceToFormat) {
                query.priceTo = this.priceToFormat;
            }
            this.$router.push({
                query: query,
            });
        },
    },
};
</script>
