import axios from 'axios';

const mutations = {
    setConnectedAccount: (state, data) => {
        state.connectedAccount = data;
    },
    setConnectAccountErrorMessage: (state, data) => {
        state.connectAccountErrorMessage = data;
    },
    setProcessing: (state, data) => {
        state.processing = data;
    },
    setCodeErrorMessage: (state, data) => {
        state.codeErrorMessage = data;
    },
    setCode: (state, data) => {
        state.code = data.code;
        state.codeLiveTime = data.liveTime;
    },
};

const getters = {
    codeLiveTimeMS(state) {
        return state.codeLiveTime * 1000;
    },
};

const actions = {
    connectAccount: ({ dispatch, commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .post(
                '/rest/omni/profile/connectAccount', data, config,
            )
            .then(() => {
                commit('setConnectedAccount', true);
                commit('setConnectAccountErrorMessage', '');
                dispatch('getCode', data);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
                const result = error.response.data;
                commit('setConnectAccountErrorMessage', result.message);
            });
    },
    getCode: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .post(
                '/rest/omni/profile/startConfirm', data, config,
            )
            .then(response => {
                const result = response.data;
                commit('setCode', result);
                commit('setCodeErrorMessage', '');
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
                const result = error.response.data;
                commit('setCodeErrorMessage', result.message);
            })
            .finally(() => {
                commit('setProcessing', false);
            });
    },
};

const state = () => ({
    connectedAccount: false,
    connectAccountErrorMessage: '',
    processing: true,
    codeErrorMessage: '',
    code: '',
    codeLiveTime: 300,
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
