import axios from 'axios';
import Donation from '@/models/Centrmama/Donation';
import Target from '@/models/Centrmama/Target';

const mutations = {
    setTargetList: (state, data) => {
        state.targetList.push(data);
    },
    clearTargetList: state => {
        state.targetList = [];
    },
    setDonation: (state, data) => {
        state.donations.push(data);
    },
    clearDonation: state => {
        state.donations = [];
    },
    setProcessing: (state, processing) => {
        state.processing = processing;
    },
};

const actions = {
    getTargets: ({commit, rootState}) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/centrmama/targets/get', config)
            .then(response => {
                commit('clearTargetList');
                for (const tagret of response.data.data) {
                    commit('setTargetList', new Target(
                        tagret.id,
                        tagret.title,
                        tagret.description,
                        tagret.amount,
                        tagret.progress,
                        tagret.status,
                        tagret.coverImagePath,
                        tagret.hasReport,
                        tagret.report,
                    ));
                }
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, {root: true},
                );
            });
    },
    getDonations: ({ commit, rootState }, limit = 5) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get(`/rest/centrmama/donate/getMy?limit=${limit}`, config)
            .then(response => {
                commit('clearDonation');
                for (const donationData of response.data.data) {
                    commit('setDonation', new Donation(
                        donationData.id,
                        donationData.amount,
                        donationData.created,
                    ));
                }
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    makeDonation: ({commit, dispatch, rootState}, payload) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        commit('setProcessing', true);
        return axios.post(
            '/rest/centrmama/donate/make', payload, config,
        )
            .then(response => {
                const donationData = response.data.data;
                commit('setDonation', new Donation(
                    donationData.id,
                    donationData.amount,
                    donationData.created,
                ));
                dispatch(
                    'account/loadBalance', null, { root: true },
                );
                dispatch('getTargets');
                const dataNotification = {
                    value: 'Спасибо! Ваш взнос успешно начислен',
                    type: 'success',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
                return true;
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, {root: true},
                );
                return false;
            })
            .finally(() => commit('setProcessing', false));
    },
    getTarget: ({commit, rootState}, id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/centrmama/targets/getOne?id=${id}`, config)
            .then(response => response.data.data)
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, {root: true},
                );
                throw error;
            });
    },
};

const getters = {
    getTargetListAvailable: state => {
        const statuses = ['progress', 'completed'];
        return state.targetList.filter(elem => statuses.includes(elem.statusCode));
    },
};

const state = () => ({
    targetList: [],
    donations: [],
    processing: false,
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
