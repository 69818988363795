<template>
    <v-easy-dialog v-model="showModal">
        <div>
            <div class="clnt-easter-dialog">
                <button class="clnt-easter-dialog__close"
                        @click="closeModal">
                    <SvgIcon name="close" />
                </button>
                <div class="clnt-easter-dialog__title -top">
                    Вы нашли подсказку к "пасхалке"!
                </div>

                <div class="clnt-easter-dialog__cover">
                    <img src="@/assets/images/easterEggs/tour_2_egg_6_riddle.jpg"
                         alt="Ребус">
                </div>

                <div class="clnt-easter-dialog__subtitle">
                    <input class="lds-input"
                           v-model="solution"
                           placeholder="Решите ребус и получите подсказку к следующей &quot;пасхалке&quot;.">
                </div>
                <div class="clnt-easter-dialog__action">
                    <div class="lds-popover lds-popover_size-sm">
                        <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                :class="{shake: isWrong}"
                                @click="solve">
                            <span v-if="isWrong">Неправильно</span>
                            <span v-else>Решить</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </v-easy-dialog>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import VEasyDialog from 'v-easy-dialog';
import EasterEggCookies, {tour2Egg6RiddleSolved} from '@/models/EasterEggs/EasterEggCookies';
import SvgIcon from '@/components/common/SvgIcon';

const STATUS = {
    READY: 'READY',
    WRONG: 'WRONG',
    RIGHT: 'RIGHT',
};

export default {
    name: 'RiddleModal',
    components: {
        VEasyDialog,
        SvgIcon,
    },
    data() {
        return {
            status: STATUS.READY,
            solution: '',
        };
    },
    computed: {
        showModal: {
            get() {
                return this.tour2egg6RiddleModal.show;
            },
            set(e) {
                if (!e) {
                    this.setTour2egg6RiddleModal({show: false});
                }
            },
        },
        isWrong() {
            return this.status === STATUS.WRONG;
        },
        ...mapState('easterEggs', ['tour2egg6RiddleModal']),
    },
    methods: {
        solve() {
            const cleanInput = this.solution.trim().toLowerCase();
            const solved = (cleanInput === 'ламода') || (cleanInput === 'lamoda');
            if (solved) {
                this.status = STATUS.RIGHT;
                EasterEggCookies.setState(tour2Egg6RiddleSolved, true);
                this.setTour2egg6RiddleModal({show: false});
                this.setNextStepModal({show: true});
            } else {
                this.status = STATUS.WRONG;
            }
        },
        closeModal() {
            this.setTour2egg6RiddleModal({show: false});
        },
        ...mapMutations('easterEggs', [
            'setTour2egg6RiddleModal',
            'setNextStepModal',
        ]),
    },
};
</script>

<style scoped>
.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
