var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lm-categories"},[_c('router-link',{staticClass:"lm-categories__btn",class:{'lm-categories__btn_active': _vm.isAllCategories},attrs:{"to":{
            name: 'MarketCatalog',
            query: _vm.query,
        }}},[_vm._v(" Все товары ")]),_vm._l((_vm.categories),function(item){return _c('router-link',{key:("category_" + (item.id)),staticClass:"lm-categories__btn",class:_vm.categoryBtnClass(item.id),attrs:{"data-key":item.id,"to":{
            name: 'MarketCatalogCategories',
            params: {
                categoryId: item.id,
            },
            query: _vm.query,
        }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('router-link',{staticClass:"lm-categories__btn lm-categories__btn_charity",attrs:{"to":{name: 'MarketCharity'}}},[_vm._v(" Centrmama ")]),_c('router-link',{staticClass:"lm-categories__btn lm-categories__btn_output-lc",attrs:{"to":{name: 'OutputLC'}}},[_vm._v(" Вывод средств ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }