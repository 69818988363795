import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';
import EasterEgg from '@/models/EasterEggs/EasterEgg';
import Round from '@/models/EasterEggs/Round';

Vue.use(Vuex);

const mutations = {
    setChallengeAvailable: (state, available) => {
        Vue.set(
            state, 'challengeAvailable', available,
        );
    },
    setCollectedModal: (state, collectedModal) => {
        Vue.set(
            state, 'collectedModal', collectedModal,
        );
    },
    setTour2egg6RiddleModal: (state, tour2egg6RiddleModal) => {
        Vue.set(
            state, 'tour2egg6RiddleModal', tour2egg6RiddleModal,
        );
    },
    setTour3Egg6RiddleSolved: (state, tour3Egg6RiddleSolved) => {
        Vue.set(
            state, 'tour3Egg6RiddleSolved', tour3Egg6RiddleSolved,
        );
    },
    setTour3egg6RiddleModal: (state, tour3egg6RiddleModal) => {
        Vue.set(
            state, 'tour3egg6RiddleModal', tour3egg6RiddleModal,
        );
    },
    setNextStepModal: (state, nextStepModal) => {
        Vue.set(
            state, 'nextStepModal', nextStepModal,
        );
    },
    setShowWelcomeModal: (state, show) => {
        state.currentRound.welcomeShown = !show;
    },
    setUnavailable: (state, code) => {
        const egg = state.eggs[code];
        if (egg) {
            egg.available = false;
            Vue.set(
                state.eggs, egg.code, egg,
            );
            if (state.currentRound) {
                state.currentRound.setEgg(egg);
            }
        }
    },
    setAvailable: (state, code) => {
        const egg = state.eggs[code];
        if (egg) {
            egg.available = true;
            Vue.set(
                state.eggs, egg.code, egg,
            );
            if (state.currentRound) {
                state.currentRound.setEgg(egg);
            }
        }
    },
    setEgg: (state, egg) => {
        Vue.set(
            state.eggs, egg.code, egg,
        );
    },
    setRoundEgg: (state, egg) => {
        state.currentRound.setEgg(egg);
    },
    setRounds: (state, rounds) => {
        Vue.set(
            state, 'rounds', rounds,
        );
    },
    setCurrenRound: (state, round) => {
        Vue.set(
            state, 'currentRound', round,
        );
    },
    setTallyRound: (state, round) => {
        Vue.set(
            state, 'tallyRound', round,
        );
    },
};

const actions = {
    loadParticipantProgress: ({commit, rootState}) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/easterEggChallenge/rounds/getParticipantProgress', config)
            .then(response => {
                const roundsData = response.data.data;

                if (roundsData.length < 1) {
                    commit('setChallengeAvailable', false);
                    return;
                }

                const rounds = [];
                for (const roundData of roundsData) {
                    const eggs = {};
                    for (const eggData of roundData.roundEasterEggs) {
                        eggs[eggData.id] = new EasterEgg(
                            eggData.id,
                            eggData.code,
                            eggData.available,
                            eggData.collected,
                            eggData.hint,
                            eggData.dateStart,
                            eggData.order,
                        );

                        commit('setEgg', eggs[eggData.id]);
                    }

                    const round = new Round(
                        roundData.id,
                        roundData.title,
                        roundData.available,
                        roundData.current,
                        roundData.tallyCurrent,
                        roundData.firstPlaceReward,
                        roundData.otherReward,
                        roundData.welcomeShown,
                        roundData.period.from,
                        roundData.period.to,
                        roundData.tallyPeriod.from,
                        roundData.tallyPeriod.to,
                        eggs,
                    );

                    if (round.isCurrent) {
                        commit('setCurrenRound', round);
                    }

                    if (round.isTallyCurrent) {
                        commit('setTallyRound', round);
                    }

                    rounds.push(round);
                }

                commit('setRounds', rounds);
                commit('setChallengeAvailable', true);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, {root: true},
                );
            });
    },
    check: ({commit, rootState}, code) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/easterEggChallenge/rounds/checkEasterEgg?easterEggCode=' + code, config)
            .then(response => {
                const result = response.data.result;
                if (!result) {
                    commit('setUnavailable', code);
                    return false;
                }

                commit('setAvailable', code);
                return true;
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, {root: true},
                );
            });
    },
    collect: ({commit, rootState}, easterEgg) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };

        const data = {
            easterEggCode: easterEgg.code,
        };

        return axios
            .post(
                '/rest/easterEggChallenge/rounds/collectEasterEgg', data, config,
            )
            .then(() => {
                easterEgg.collect();
                easterEgg.disable();
                commit('setCollectedModal', {
                    easterEgg: easterEgg,
                    show: true,
                });

                commit('setEgg', easterEgg);
                commit('setRoundEgg', easterEgg);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, {root: true},
                );
            });
    },
    closeModal: ({commit}) => {
        commit('setCollectedModal', {
            easterEgg: null,
            show: false,
        });
    },
    showNextStepModal: ({commit}) => {
        commit('setNextStepModal', {
            show: true,
        });
    },
    closeNextStepModal: ({commit}) => {
        commit('setNextStepModal', {
            show: false,
        });
    },
    showWelcomeModal: ({commit}) => {
        commit('setShowWelcomeModal', true);
    },
    closeWelcomeModal: ({commit, rootState}) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };

        return axios
            .post(
                '/rest/easterEggChallenge/rounds/markWelcomeIsShown', {}, config,
            )
            .then(() => {
                commit('setShowWelcomeModal', false);
            })
            .catch(error => {
                commit('setShowWelcomeModal', false);
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, {root: true},
                );
            });
    },
};

const getters = {
    getAvailable: state => code => ((state.eggs[code] && state.eggs[code].available) ? state.eggs[code] : null),
    showWelcomeModal: state => (getters.isWelcomeState(state) && state.currentRound && !state.currentRound.welcomeShown),
    isWelcomeState: state => {
        const currentRound = state.currentRound;
        if (!currentRound) {
            return false;
        }

        const allAvailable = Object.values(currentRound.eggs).filter(egg => egg.available);
        const allCollected = Object.values(currentRound.eggs).filter(egg => egg.collected);
        return (allAvailable.length > 0) && (allCollected.length === 0);
    },
};

const state = () => ({
    rounds: [],
    currentRound: null,
    tallyRound: null,
    challengeAvailable: false,
    eggs: {},
    available: {},
    collectedModal: {
        show: false,
        easterEgg: null,
    },
    nextStepModal: {
        show: false,
    },
    tour2egg6RiddleModal: {
        show: false,
    },
    tour3egg6RiddleModal: {
        show: false,
    },
    tour3Egg6RiddleSolved: false,
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
