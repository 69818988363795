<template>
    <v-easy-dialog v-model="showModal"
                   v-if="currentRound && (initEasterEgg1 || initEasterEgg2 || initEasterEgg3)"
                   :persistent="true">
        <div>
            <div class="clnt-easter-dialog">
                <VueSlickCarousel v-bind="settingsSlide"
                                  ref="carouselModal"
                                  class="clnt-easter-dialog__slider">
                    <template v-if="initEasterEgg1">
                        <div>
                            <div class="clnt-easter-dialog__page">
                                <div class="clnt-easter-dialog__page-start">
                                    <img src="@/assets/images/easterEggs/easter_tour.png"
                                         class="clnt-easter-dialog__page-start__cover">

                                    <div class="clnt-easter-dialog__title">
                                        {{ currentRound.title }}
                                    </div>
                                    <div class="clnt-easter-dialog__date-line">
                                        {{ humanDate(currentRound.dateFrom) }} - {{ humanDate(currentRound.dateTo) }}
                                    </div>
                                    <div class="clnt-easter-dialog__title">
                                        Сезон открыт
                                    </div>
                                    <div class="clnt-easter-dialog__subtitle accent">
                                        Найдите все "пасхалки" быстрее остальных и....пакуйте чемоданы
                                    </div>
                                    <div class="clnt-easter-dialog__subtitle">
                                        Теперь расскажем о том, что вам нужно знать, чтобы выиграть <br>(очень
                                        рекомендуем
                                        прочитать до
                                        конца)
                                    </div>
                                    <div class="clnt-easter-dialog__action">
                                        <div class="lds-popover lds-popover_size-sm">
                                            <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                                    @click="nextPage">
                                                Подробнее
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_1_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_1.svg"
                                             v-else>
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 1 <span class="of">из 3</span>
                                    </div>

                                    <div class="clnt-easter-dialog__title">
                                        Что такое "пасхалки"?
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        "Пасхалки" — скрытые послания или сюрпризы, открывающиеся в ходе намеренных
                                        поисков
                                        (знал,
                                        где искать) или спрятанные в неочевидных, но доступных местах.
                                        В нашем случае- это скрытые активные символы или блоки интерфейса, которые, как
                                        правило, не
                                        видны с первого взгляда и гармонично встроены в основную среду — их и будем
                                        искать!
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <b>Виды "пасхалок"</b>
                                        "Пасхалками" могут быть как графические (напр., иконка), так и текстовые (напр.,
                                        буква в
                                        слове) части интерфейса. Они могут быть "спрятаны" в действующий элемент системы
                                        (напр.,
                                        реквизит в футере сайта) или быть отдельным элементом.
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <b>Как я пойму, что нашел "пасхалку"?</b>
                                        Все просто, при клике на нее вы увидите соответствующее уведомление)
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <b>Где их искать?</b>
                                        На любой странице личного кабинета
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -primary"

                                            @click="nextPage">
                                            Дальше
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_2_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_2.svg"
                                             v-else>
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 2 <span class="of">из 3</span>
                                    </div>

                                    <div class="clnt-easter-dialog__title">
                                        Правила и лайфхаки
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <ol>
                                            <li>
                                                "Пасхалки" будут активироваться последовательно, 1 "пасхалка" в неделю,
                                                так
                                                что у
                                                вас будет время на поиск
                                            </li>
                                            <li>
                                                Лучше начинать разгадку и поиск "пасхалки" сразу после ее появления,
                                                потому
                                                что мы
                                                будем учитывать время с момента ее активации до момента, когда вы ее
                                                найдете
                                            </li>
                                            <li>Если что, то ваш менеджер тоже на связи)</li>
                                            <li>
                                                Помните о 2 основных правилах:
                                                <ol>
                                                    <li>Чтобы попасть в ТОП 10, нужно найти все "пасхалки"</li>
                                                    <li>
                                                        Мы учитываем суммарное время на поиск с момента активации до
                                                        раскрытия.
                                                        Меньше времени - больше награда.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                            v-if="hasNextPage"
                                            @click="nextPage">
                                            Дальше
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_3_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_3.svg"
                                             v-else>

                                        <a v-if="!!initEasterEgg1 && initEasterEgg1.needShow"
                                           @click="collectAndClose1"
                                           class="clnt-easter-dialog__left-hand-side-filler-easter-egg" />
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 3 <span class="of">из 3</span>
                                    </div>
                                    <div class="clnt-easter-dialog__title">
                                        С чего начать?
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        С "пасхалки", которая появилась на этих 4-х слайдах)
                                        Найдите ее и откройте доступ к первой подсказке
                                    </div>
                                    <div class="clnt-easter-dialog__text">
                                        Игра началась)
                                    </div>
                                    <div class="clnt-easter-dialog__text">
                                        P.s. Ваша мышка может помочь
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="initEasterEgg2">
                        <div>
                            <div class="clnt-easter-dialog__page">
                                <div class="clnt-easter-dialog__page-start">
                                    <img src="@/assets/images/easterEggs/easter_tour_2.png"
                                         class="clnt-easter-dialog__page-start__cover top">
                                    <div class="clnt-easter-dialog__tour-title">
                                        {{ currentRound.title }}
                                    </div>
                                    <div class="clnt-easter-dialog__date-line">
                                        {{ humanDate(currentRound.dateFrom) }} - {{ humanDate(currentRound.dateTo) }}
                                    </div>
                                    <div class="clnt-easter-dialog__title">
                                        Новый сезон открыт!
                                    </div>
                                    <div class="clnt-easter-dialog__subtitle accent">
                                        Найдите все "пасхалки" быстрее остальных и....пакуйте чемоданы
                                    </div>

                                    <div class="clnt-easter-dialog__subtitle">
                                        Теперь расскажем о том, что вам нужно знать, чтобы выиграть <br>(очень
                                        рекомендуем прочитать до конца)
                                    </div>
                                    <div class="clnt-easter-dialog__action">
                                        <div class="lds-popover lds-popover_size-sm">
                                            <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                                    @click="nextPage">
                                                Подробнее
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_1_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_1.svg"
                                             v-else>
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 1 <span class="of">из 3</span>
                                    </div>

                                    <div class="clnt-easter-dialog__title">
                                        Что вас ждет в Tour 2
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <b>Виды "пасхалок"</b>
                                        Теперь пасхалки активируются только после выполнения последовательности
                                        действий. Иногда вам придется сначала кое-где кликнуть или воспользоваться полем
                                        для ввода, а иногда и сформировать отчет или покопаться в аналитике.
                                        <br><br>
                                        Сделаете все правильно - увидите уведомление, а значит пасхалка появилась.
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <b>Подсказки 2.0</b>
                                        Расшифровать можно, но уже не так быстро)
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -primary"

                                            @click="nextPage">
                                            Дальше
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_2_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_2.svg"
                                             v-else>
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 2 <span class="of">из 3</span>
                                    </div>

                                    <div class="clnt-easter-dialog__title">
                                        Правила и лайфхаки
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <ol>
                                            <li>
                                                "Пасхалки" будут активироваться последовательно, 1 "пасхалка" в неделю,
                                                так что у вас будет время на поиск
                                            </li>
                                            <li>
                                                Лучше начинать разгадку и поиск "пасхалки" сразу после ее появления,
                                                потому что мы будем учитывать время с момента ее активации до момента,
                                                когда вы ее найдете
                                            </li>
                                            <li>
                                                Не забывайте про подсказки. Они помогут понять, что нужно делать или где
                                                нужно что-то сделать
                                            </li>
                                            <li>
                                                Стоит запоминать расположение и вид элементов на странице, иначе при
                                                возврате на нее вы можете не заметить изменений
                                            </li>
                                            <li>
                                                Помните о 2 основных правилах:
                                                <ol>
                                                    <li>Чтобы попасть в ТОП 10, нужно найти все "пасхалки"</li>
                                                    <li>Меньше времени - больше награда</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                            v-if="hasNextPage"
                                            @click="nextPage">
                                            Дальше
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_3_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_3.svg"
                                             v-else>

                                        <a v-if="!!initEasterEgg1 && initEasterEgg1.needShow"
                                           @click="collectAndClose1"
                                           class="clnt-easter-dialog__left-hand-side-filler-easter-egg" />
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 3 <span class="of">из 3</span>
                                    </div>
                                    <div class="clnt-easter-dialog__title">
                                        Игра началась
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        Первая подсказка активирована и уже ждет вас.
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        Если что, то ваш менеджер тоже на связи
                                        На случай, если вы "в тупике" - попросите дополнительную подсказку или любые другие разъяснения.
                                        Мы придумаем еще одну подсказку или подсветим вектор для размышления. Правда на это также уйдет немного времени)
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                                v-if="initEasterEgg2"
                                                @click="collectAndGoToTour2">
                                            Вступить в гонку
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="initEasterEgg3">
                        <div>
                            <div class="clnt-easter-dialog__page">
                                <div class="clnt-easter-dialog__page-start">
                                    <img src="@/assets/images/easterEggs/easter_tour_3.png"
                                         class="clnt-easter-dialog__page-start__cover top">
                                    <div class="clnt-easter-dialog__tour-title">
                                        {{ currentRound.title }}
                                    </div>
                                    <div class="clnt-easter-dialog__date-line">
                                        {{ humanDate(currentRound.dateFrom) }} - {{ humanDate(currentRound.dateTo) }}
                                    </div>
                                    <div class="clnt-easter-dialog__title">
                                        Сезон открыт!
                                    </div>
                                    <div class="clnt-easter-dialog__subtitle accent">
                                        Найдите все "пасхалки" быстрее остальных и....пакуйте чемоданы
                                    </div>

                                    <div class="clnt-easter-dialog__subtitle">
                                        Теперь расскажем о том, что вам нужно знать, чтобы выиграть <br>(очень
                                        рекомендуем прочитать до конца)
                                    </div>
                                    <div class="clnt-easter-dialog__action">
                                        <div class="lds-popover lds-popover_size-sm">
                                            <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                                    @click="nextPage">
                                                Подробнее
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_1_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_1.svg"
                                             v-else>
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 1 <span class="of">из 3</span>
                                    </div>

                                    <div class="clnt-easter-dialog__title">
                                        Что вас ждет в Tour 3
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <b>Виды "пасхалок"</b>
                                        Теперь пасхалки активируются только после выполнения последовательности
                                        действий. Иногда вам придется сначала кое-где кликнуть или воспользоваться полем
                                        для ввода, а иногда и сформировать отчет или покопаться в аналитике.
                                        <br><br>
                                        Сделаете все правильно - увидите уведомление, а значит пасхалка появилась.
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <b>Подсказки 3.0</b>
                                        Расшифровать можно, но уже не так быстро)
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -primary"

                                            @click="nextPage">
                                            Дальше
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_2_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_2.svg"
                                             v-else>
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 2 <span class="of">из 3</span>
                                    </div>

                                    <div class="clnt-easter-dialog__title">
                                        Правила и лайфхаки
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        <ol>
                                            <li>
                                                "Пасхалки" будут активироваться последовательно, 1 "пасхалка" в неделю,
                                                так что у вас будет время на поиск
                                            </li>
                                            <li>
                                                Лучше начинать разгадку и поиск "пасхалки" сразу после ее появления,
                                                потому что мы будем учитывать время с момента ее активации до момента,
                                                когда вы ее найдете
                                            </li>
                                            <li>
                                                Не забывайте про подсказки. Они помогут понять, что нужно делать или где
                                                нужно что-то сделать
                                            </li>
                                            <li>
                                                Стоит запоминать расположение и вид элементов на странице, иначе при
                                                возврате на нее вы можете не заметить изменений
                                            </li>
                                            <li>
                                                Помните о 2 основных правилах:
                                                <ol>
                                                    <li>Чтобы попасть в ТОП 10, нужно найти все "пасхалки"</li>
                                                    <li>Меньше времени - больше награда</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                            v-if="hasNextPage"
                                            @click="nextPage">
                                            Дальше
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="clnt-easter-dialog__page with-columns">
                                <div class="clnt-easter-dialog__left-hand-side">
                                    <div class="clnt-easter-dialog__left-hand-side-filler">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_3_mob.svg"
                                             v-if="mqSmall">
                                        <img class="clnt-easter-dialog__left-hand-side-filler-img"
                                             src="@/assets/images/easterEggs/filler_3.svg"
                                             v-else>

                                        <a v-if="!!initEasterEgg1 && initEasterEgg1.needShow"
                                           @click="collectAndClose1"
                                           class="clnt-easter-dialog__left-hand-side-filler-easter-egg" />
                                    </div>
                                </div>

                                <div class="clnt-easter-dialog__right-hand-side">
                                    <div class="clnt-easter-dialog__page-counter">
                                        Стр. 3 <span class="of">из 3</span>
                                    </div>
                                    <div class="clnt-easter-dialog__title">
                                        Игра началась
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        Первая подсказка активирована и уже ждет вас.
                                    </div>

                                    <div class="clnt-easter-dialog__text">
                                        Если что, то ваш менеджер тоже на связи
                                        На случай, если вы "в тупике" - попросите дополнительную подсказку или любые другие разъяснения.
                                        Мы придумаем еще одну подсказку или подсветим вектор для размышления. Правда на это также уйдет немного времени)
                                    </div>

                                    <div class="clnt-easter-dialog__action">
                                        <button
                                            class="clnt-easter-dialog__action-btn lds-btn lds-btn_fill lds-btn_size-sm -gray"
                                            @click="prevPage">
                                            Назад
                                        </button>

                                        <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                                v-if="initEasterEgg3"
                                                @click="collectAndGoToTour3">
                                            Вступить в гонку
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </VueSlickCarousel>
                <button v-if="initEasterEgg1"
                        class="clnt-easter-dialog__close"
                        @click="closeWelcomeModal">
                    <SvgIcon name="close" />
                </button>

                <button v-else-if="initEasterEgg2"
                        class="clnt-easter-dialog__close"
                        @click="collectAndClose2">
                    <SvgIcon name="close" />
                </button>

                <button v-else-if="initEasterEgg3"
                        class="clnt-easter-dialog__close"
                        @click="collectAndClose3">
                    <SvgIcon name="close" />
                </button>
            </div>
        </div>
    </v-easy-dialog>
</template>

<script>

import {
    mapGetters,
    mapActions,
    mapState,
} from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import VEasyDialog from 'v-easy-dialog';
import metaMixin from '@/mixins/metaMixin';
import SvgIcon from '@/components/common/SvgIcon';
import {tour1init, tour2init, tour3init} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'EasterEggsWelcomeModal',
    components: {
        SvgIcon,
        VEasyDialog,
        VueSlickCarousel,
    },
    mixins: [
        metaMixin,
    ],
    data() {
        return {
            page: 0,
            maxPageIndex: 3,
            settingsSlide: {
                autoplay: false,
                arrows: false,
                dots: false,
                slidesToShow: 1,
                infinite: false,
                swipe: false,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        adaptiveHeight: true,
                    },
                },
                ],
            },
        };
    },
    computed: {
        ...mapState('easterEggs', [
            'eggs',
            'currentRound',
        ]),
        ...mapGetters('easterEggs', [
            'showWelcomeModal',
            'getAvailable',
        ]),
        showModal: {
            set() {
            },
            get() {
                return this.showWelcomeModal;
            },
        },
        initEasterEgg1() {
            return this.getAvailable(tour1init);
        },
        initEasterEgg2() {
            return this.getAvailable(tour2init);
        },
        initEasterEgg3() {
            return this.getAvailable(tour3init);
        },
        hint() {
            const allEggs = Object.values(this.eggs);
            const firstEgg = allEggs[0];
            return firstEgg && firstEgg.available ? firstEgg.hint : '-';
        },
        hasNextPage() {
            return this.maxPageIndex > this.page;
        },
        mqSmall() {
            return this.$mq === 'md' || this.$mq === 'sm' || this.$mq === 'xs';
        },
    },
    created() {
        this.check(tour1init);
    },
    methods: {
        ...mapActions('easterEggs', [
            'closeWelcomeModal',
            'closeModal',
            'check',
            'collect',
        ]),
        collectAndClose1() {
            this.collect(this.initEasterEgg1);
            this.closeWelcomeModal();
        },
        collectAndClose2() {
            this.collect(this.initEasterEgg2)
                .then(() => this.closeModal());
            this.closeWelcomeModal();
        },
        collectAndGoToTour2() {
            this.collect(this.initEasterEgg2)
                .then(() => this.closeModal())
                .then(this.goToTourPage);
            this.closeWelcomeModal();
        },
        collectAndGoToTour3() {
            this.collect(this.initEasterEgg3)
                .then(() => this.closeModal())
                .then(this.goToTourPage);
            this.closeWelcomeModal();
        },
        collectAndClose3() {
            this.collect(this.initEasterEgg3)
                .then(() => this.closeModal());
            this.closeWelcomeModal();
        },
        goToTourPage() {
            if (this.$router.currentRoute.path !== '/easterEggsChallenge') {
                return this.$router.push('/easterEggsChallenge');
            }
        },
        nextPage() {
            this.$refs.carouselModal.next();
        },
        prevPage() {
            this.$refs.carouselModal.prev();
        },
        humanDate(dateString) {
            const dateObj = new Date(dateString);
            const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
            return dateObj.getDate() + ' ' + months[dateObj.getMonth()];
        },
    },
};
</script>
