import FormattingCoins from '@/mixins/formattingCoins';

const STATUS_DRAFT = '1';
const STATUS_PROGRESS = '2';
const STATUS_COMPLETED = '3';
const STATUS_ARCHIVE = '4';

const CODE = {
    [STATUS_DRAFT]: 'draft',
    [STATUS_PROGRESS]: 'progress',
    [STATUS_COMPLETED]: 'completed',
    [STATUS_ARCHIVE]: 'archive',
};

export default class Target {
    /**
     *
     * @param id
     * @param title
     * @param description
     * @param amount
     * @param progress
     * @param status
     * @param coverImagePath
     * @param hasReport
     * @param report
     */

    constructor(
        id,
        title,
        description,
        amount,
        progress,
        status,
        coverImagePath,
        hasReport,
        report,
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.amount = amount;
        this.progress = progress;
        this.status = status;
        this.coverImagePath = coverImagePath;
        this.hasReport = hasReport;
        this.report = report;
    }

    get amountFormatted() {
        return FormattingCoins.methods.formatCoins(parseFloat(this.amount));
    }

    get progressFormatted() {
        return FormattingCoins.methods.formatCoins(parseFloat(this.progress));
    }

    get imageSrc() {
        return `https://market.s3lds.ru/${this.coverImagePath}`;
    }

    get statusCode() {
        return CODE[this.status.id] || null;
    }
}
