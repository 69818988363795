<template>
    <div class="clnt-summary">
        <div class="clnt-summary__heading">
            <h2 class="clnt-summary__title">
                Сводка
            </h2>
            <DatePickerSummary />
        </div>
        <TableSummary
            :summary="summary" />
    </div>
</template>

<script>
import {mapState} from 'vuex';
import DatePickerSummary from '@/components/summary/DatePickerSummary';
import TableSummary from '@/components/summary/TableSummary';

export default {
    name: 'Summary',
    components: {
        DatePickerSummary,
        TableSummary,
    },
    computed: {
        ...mapState('summary', [
            'summary',
        ]),
    },
};
</script>
