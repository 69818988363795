import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueGtm from '@gtm-support/vue2-gtm';
import VueGtag from 'vue-gtag';
import vSelect from 'vue-select';
import VueMq from 'vue-mq';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VScrollLock from 'v-scroll-lock';
import Element from 'element-ui';
import ElementLocale from 'element-ui/lib/locale';
import ElementLang from './locales/ru-RU';
import App from './App.vue';
import router from './router';
import store from './store/index';

import './assets/scss/styles.scss';
import 'element-ui/lib/theme-chalk/index.css';
import VueYandexSmartCaptcha from './services/VueYandexSmartCaptcha.js';

Vue.use(router);
ElementLocale.use(ElementLang);

Vue.use(Element, { ElementLocale });

if (process.env.VUE_APP_GTM_ID) {
    Vue.use(VueGtm, {
        enabled: process.env.VUE_APP_GTM_ENABLED, // true, // process.env.NODE_ENV === 'production',
        id: process.env.VUE_APP_GTM_ID,
        debug: true,
        loadScript: true,
        vueRouter: router,
    });
}

if (process.env.VUE_APP_GA_GTAG_ID) {
    Vue.use(VueGtag, {
        config: {
            id: process.env.VUE_APP_GA_GTAG_ID,
        },
        enabled: process.env.VUE_APP_GA_GTAG_ENABLED,
    });
}
if (process.env.VUE_APP_YA_CAPTCHA_CLIENT_KEY) {
    Vue.use(VueYandexSmartCaptcha, process.env.VUE_APP_YA_CAPTCHA_CLIENT_KEY);
}

Vue.use(Vuelidate);
Vue.use(VueMq, {
    breakpoints: {
        xs: 550,
        sm: 768,
        md: 1024,
        ml: 1366,
        xl: Infinity,
    },
    defaultBreakpoint: 'sm',
});
Vue.use(VueAwesomeSwiper);
Vue.use(VScrollLock);

Vue.component('VSelect', vSelect);

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
