<template>
    <span class="clnt-timer-simple">
        {{ timeCurrent }}
    </span>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

Vue.prototype.$moment = moment;

export default {
    name: 'Timer',
    // eslint-disable-next-line vue/require-prop-types
    props: ['time'],
    data() {
        return {
            isRunning: false,
            timer: null,
            date: moment(this.time),
        };
    },
    computed: {
        timeCurrent() {
            if (this.date.format('m:ss') === '0:00') {
                this.stop();
            }
            return this.date.format('m:ss');
        },
        dateInitial() {
            return moment(this.time);
        },
    },
    watch: {
        time: function () {
            this.date = moment(this.time);
        },
    },
    methods: {
        start() {
            this.isRunning = true;

            this.timer = setInterval(() => {
                this.date = moment(this.date.subtract(1, 'seconds'));
            }, 1000);
        },
        stop() {
            this.isRunning = false;
            this.date = moment(this.time);
            clearInterval(this.timer);
            this.$emit('timerEnd');
        },
    },
};
</script>
