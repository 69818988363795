<template>
    <div class="lds-container clnt-statistics-of-offers">
        <div class="clnt-statistics-of-offers__content">
            <h1 class="clnt-statistics-of-offers__title">
                Статистика по офферам
            </h1>
            <form class="clnt-statistics-of-offers__form">
                <div class="clnt-statistics-of-offers__form-item">
                    <div class="clnt-statistics-of-offers__caption">
                        Какую статистику сформировать?
                    </div>
                    <div class="clnt-statistics-of-offers__radios">
                        <div class="clnt-statistics-of-offers__radio"
                             v-for="modeItem in listModeStatistic"
                             :key="`mode-${modeItem.value}`">
                            <input :id="`mode-${modeItem.value}`"
                                   v-model="mode"
                                   :value="modeItem.value"
                                   name="mode"
                                   type="radio"
                                   class="clnt-statistics-of-offers__radio-input">
                            <label :for="`mode-${modeItem.value}`"
                                   class="clnt-statistics-of-offers__radio-label">
                                {{ modeItem.name }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="clnt-statistics-of-offers__form-item"
                     data-test="geo"
                     v-if="visibleGeo">
                    <div class="clnt-statistics-of-offers__select-offer-block">
                        <label class="clnt-statistics-of-offers__caption">Выберите регион</label>
                        <div>
                            <VSelect class="clnt-statistics-of-webmaster__select lds-select lds-select_size-sm"
                                     v-model="geo"
                                     :options="listGeoStatistic"
                                     :filterable="false"
                                     autocomplete="off">
                                <template slot="no-options">
                                    Поиск...
                                </template>
                                <template slot="option"
                                          slot-scope="option">
                                    <div>
                                        {{ option.name }}
                                    </div>
                                </template>
                                <template slot="selected-option"
                                          slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                            </VSelect>
                        </div>
                    </div>
                </div>
                <div class="clnt-statistics-of-offers__form-item"
                     data-test="category"
                     v-if="visibleCategory">
                    <div class="clnt-statistics-of-offers__select-offer-block">
                        <label class="clnt-statistics-of-offers__caption">Выберите категорию</label>
                        <div>
                            <VSelect class="clnt-statistics-of-webmaster__select lds-select lds-select_size-sm"
                                     v-model="category"
                                     :options="listCategoriesStatistic"
                                     :filterable="false"
                                     autocomplete="off">
                                <template slot="no-options">
                                    Поиск...
                                </template>
                                <template slot="option"
                                          slot-scope="option">
                                    <div>
                                        {{ option.name }}
                                    </div>
                                </template>
                                <template slot="selected-option"
                                          slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                            </VSelect>
                        </div>
                    </div>
                </div>
                <div class="clnt-statistics-of-offers__form-item"
                     data-test="period"
                     v-if="visiblePeriod">
                    <div class="clnt-statistics-of-offers__caption">
                        Выберите период
                    </div>
                    <div class="clnt-statistics__period clnt-datepicker clnt-datepicker_sm">
                        <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_from"
                                     v-model="dateFrom"
                                     format="DD.MM.YYYY"
                                     :disabled-date="disabledRangeFrom"
                                     :lang="lang" />
                        <date-picker class="clnt-datepicker__item clnt-datepicker__item_prefix clnt-datepicker__item_to"
                                     v-model="dateTo"
                                     format="DD.MM.YYYY"
                                     :disabled-date="disabledRangeTo"
                                     :lang="lang" />
                    </div>
                </div>
                <div class="clnt-statistics-of-offers__buttons">
                    <div>
                        <div class="clnt-statistics-of-offers__form-action"
                             v-if="!statisticLoading">
                            <button class="lds-btn lds-btn_fill lds-btn_size-xs"
                                    :disabled="disableSubmit"
                                    type="submit"
                                    data-test="submit"
                                    @click.prevent="submitStatistics('Сформировать')">
                                Сформировать
                            </button>
                        </div>
                        <div v-else
                             class="lds-btn lds-btn_fill lds-btn_size-xs lds-btn_loader">
                            <SvgIcon name="loader" />
                            Формируется
                        </div>
                    </div>
                    <div class="clnt-statistics-of-offers__btn">
                        <div class="clnt-statistics-of-offers__form-action"
                             v-if="!statisticExportLoading">
                            <button class="lds-btn lds-btn_fill -gray lds-btn_size-xs"
                                    :disabled="disableSubmit"
                                    type="submit"
                                    @click.prevent="submitStatistics('Экспорт')"
                                    data-test="export">
                                Экспорт
                            </button>
                        </div>
                        <div v-else
                             class="lds-btn lds-btn_fill -gray lds-btn_size-xs lds-btn_loader">
                            <SvgIcon name="loader" />
                            Экспорт
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div
            class="clnt-statistics-of-offers__plug-template"
            v-if="!tableOfOffersLoading">
            <img class="clnt-statistics-of-offers__plug-template__icon"
                 src="@/assets/images/statistics/table-stat-offer.png"
                 alt>
            <div class="clnt-statistics-of-offers__describe">
                Выберите нужные параметры и нажмите кнопку сформировать, чтобы получить данные.
            </div>
        </div>
        <div v-else
             class="clnt-statistics-of-offers__table">
            <div class="clnt-statistics-of-offers__small-info">
                {{ smallInfoAboutOffers }}
            </div>
            <table-of-offers />
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import vSelect from 'vue-select';
import TableOfOffers from '@/components/statistics/TableOfOffers.vue';
import SvgIcon from '@/components/common/SvgIcon';
import settingsDatePickerLang from '@/services/settingsDatePickerLang';
import getLastWeek from '@/services/getLastWeek';
import EasterEggCookies, {tour2Egg4statMade} from '@/models/EasterEggs/EasterEggCookies';
import {tour2egg4} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'StatisticsOfOffers',
    components: {
        TableOfOffers,
        DatePicker,
        SvgIcon,
        vSelect: vSelect,
    },
    data() {
        return {
            mode: '',
            geo: '',
            smallInfoAboutOffers: '',
            category: '',
            dateFrom: getLastWeek.getLastMonday(),
            dateTo: getLastWeek.getLastSunday(),
            lang: settingsDatePickerLang,
            listModeStatistic: [
                {
                    value: 'own',
                    name: 'По своим офферам',
                },
                {
                    value: 'competitors',
                    name: 'По офферам конкурентов',
                },
            ],
        };
    },
    computed: {
        ...mapState('statistics', [
            'statisticLoading',
            'tableOfOffersLoading',
            'listGeoStatistic',
            'listCategoriesStatistic',
            'statisticExportLoading',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        isModeOwn() {
            return this.mode === 'own';
        },
        visibleGeo() {
            return this.mode && !this.isModeOwn;
        },
        visibleCategory() {
            return this.visibleGeo && this.geo;
        },
        visiblePeriod() {
            if (this.isModeOwn) {
                return true;
            }
            return this.mode && this.geo && this.category;
        },
        disableSubmit() {
            return !this.mode || !this.visiblePeriod || !this.dateFrom || !this.dateTo;
        },
        dateFromInfo() {
            return moment(this.dateFrom).format('DD-MM-YYYY');
        },
        dateToInfo() {
            return moment(this.dateTo).format('DD-MM-YYYY');
        },
    },
    watch: {
        mode() {
            this.period = '';
            this.geo = '';
            this.category = '';
        },
    },
    beforeMount() {
        this.$store.state.statistics.tableOfOffersLoading = false;
        this.getGeoStatisticOffer();
        this.getCategoriesStatisticOffer();
    },
    methods: {
        ...mapActions('statistics', [
            'getStatisticsInGrid',
            'getStatistic',
            'getGeoStatisticOffer',
            'getCategoriesStatistic',
            'getCategoriesStatisticOffer',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'showNextStepModal',
        ]),
        disabledRangeFrom(date) {
            const dateMax = this.dateTo || new Date();
            return date > dateMax;
        },
        disabledRangeTo(date) {
            return date > new Date() || date < this.dateFrom;
        },
        submitStatistics(event) {
            const dateFrom = moment(this.dateFrom).format('YYYY-MM-DD');
            const dateTo = moment(this.dateTo).format('YYYY-MM-DD');
            const category = this.category.label === 'All' ? '' : this.category.label;
            const geo = this.geo.iso_alpha3;
            const mode = this.mode;
            const url = {
                competitors: `/rest/stat/byOffers/getData?dateFrom=${dateFrom}&dateTo=${dateTo}&mode=competitors&category=${category}&geo=${geo}`,
                own: `/rest/stat/byOffers/getData?dateFrom=${dateFrom}&dateTo=${dateTo}&mode=own`,
            };
            if (!this.disableSubmit) {
                const data = {
                    mode,
                    geo,
                    category,
                    dateFrom,
                    dateTo,
                    url: mode === 'competitors' ? url.competitors : url.own,
                };
                if (event === 'Экспорт') {
                    this.getStatistic(data);
                }
                if (event === 'Сформировать') {
                    this.getStatisticsInGrid(data);
                    if (mode === 'competitors') {
                        this.smallInfoAboutOffers = `Статистика по офферам конкурентов по категории ${this.category.name} в регионе ${this.geo.name} в период с ${this.dateFromInfo} по ${this.dateToInfo}`;
                        this.checkEasterEgg();
                    } else {
                        this.smallInfoAboutOffers = `Статистика по своим офферам в период с ${this.dateFromInfo} по ${this.dateToInfo}`;
                    }
                }
            }
        },
        checkEasterEgg() {
            if (!EasterEggCookies.getState(tour2Egg4statMade)) {
                this.check(tour2egg4)
                    .then(() => {
                        if (this.getAvailable(tour2egg4)) {
                            EasterEggCookies.setState(tour2Egg4statMade, true);
                            this.showNextStepModal();
                        }
                    });
            }
        },
    },
};
</script>

<style scoped>

</style>
