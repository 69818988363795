<template>
    <div class="lds-container clnt-dynamic">
        <form class="clnt-dynamic__control">
            <div class="clnt-dynamic__control-item">
                <div class="clnt-dynamic__caption">
                    Выберите оффер
                </div>
                <vSelect class="clnt-dynamic__select lds-select lds-select_size-sm"
                         placeholder="Оффер"
                         v-model="offerSelected"
                         :options="offers"
                         label="title"
                         @open="selectClick()"
                         @search="offerSelectInput()">
                    <template #no-options="{ search, searching }">
                        <template v-if="searching">
                            Нет результатов <em>{{ search }}</em>.
                        </template>
                    </template>
                </vSelect>
            </div>
            <div class="clnt-dynamic__control-item">
                <div class="clnt-dynamic__caption">
                    Выберите период
                </div>
                <div class="clnt-datepicker clnt-datepicker_sm">
                    <date-picker class="clnt-datepicker__item
                                        clnt-datepicker__item_prefix
                                        clnt-datepicker__item_from"
                                 v-model="dateFrom"
                                 format="DD.MM.YYYY"
                                 :disabled-date="disabledRangeFrom"
                                 :lang="lang" />
                    <date-picker class="clnt-datepicker__item
                                        clnt-datepicker__item_prefix
                                        clnt-datepicker__item_to"
                                 v-model="dateTo"
                                 format="DD.MM.YYYY"
                                 :disabled-date="disabledRangeTo"
                                 :lang="lang" />
                </div>
            </div>
            <button v-if="!loadingStatisticDynamic"
                    class="clnt-dynamic__submit lds-btn lds-btn_fill lds-btn_size-xs"
                    type="button"
                    :disabled="disabledSubmit"
                    @click="submitStatisticDynamicByOffer()">
                Сформировать
            </button>
            <div v-else
                 class="lds-btn lds-btn_fill lds-btn_size-xs lds-btn_loader">
                <SvgIcon name="loader" />
                Формируется
            </div>
        </form>
        <div v-if="seriesARCR.length && seriesEPCEPL.length"
             class="clnt-dynamic__info">
            <div class="clnt-dynamic__diagram">
                <p v-if="offerSelected && dateFrom && dateTo"
                   class="clnt-dynamic__title clnt-dynamic__title_desktop">
                    Оффер {{ offerSelected.id }}, {{ offerSelected.title }}
                    за период с {{ dateFormatter(dateFrom) }} - {{ dateFormatter(dateTo)  }}
                </p>
                <ApexChart ref="chartARCR"
                           class="clnt-dynamic__diagram-item"
                           type="line"
                           :height="heightChart"
                           :options="chartOptionsARCR"
                           :series="seriesARCR" />
                <ApexChart ref="chartEPLEPC"
                           class="clnt-dynamic__diagram-item"
                           type="line"
                           :height="heightChart"
                           :options="chartOptionsEPCEPL"
                           :series="seriesEPCEPL" />
            </div>
            <div class="clnt-dynamic__right"
                 v-if="dataTable.length">
                <p class="clnt-dynamic__title">
                    Показатели по офферу
                </p>
                <div class="clnt-dynamic__table">
                    <el-table :data="dataTable"
                              ref="table"
                              :height="heightTable"
                              :cell-class-name="tableRowClassName">
                        <el-table-column
                            prop="period"
                            label="Период:"
                            width="120" />
                        <el-table-column
                            prop="CR"
                            label="CR %:"
                            width="50">
                            <template slot-scope="scope">
                                {{ formatPercent(scope.row.CR) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="AR"
                            label="AR %:"
                            width="50">
                            <template slot-scope="scope">
                                {{ formatPercent(scope.row.AR) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="EPC"
                            label="EPC:"
                            width="50">
                            <template slot-scope="scope">
                                {{ formatFixed(scope.row.EPC) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="EPL"
                            label="EPL:"
                            width="50">
                            <template slot-scope="scope">
                                {{ formatFixed(scope.row.EPL) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div v-else
             class="clnt-dynamic__empty">
            <img class="clnt-dynamic__empty-img"
                 src="@/assets/images/statistics/chart.svg"
                 alt="Статистика">
            <p class="clnt-dynamic__empty-text">
                Выберите оффер, укажите период и нажмите кнопку формировать, чтобы получить данные
            </p>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ApexChart from 'vue-apexcharts';
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Vue from 'vue';
import vuescroll from 'vuescroll/dist/vuescroll-native';
import settingsDatePickerLang from '@/services/settingsDatePickerLang';
import getLastWeek from '@/services/getLastWeek';
import SvgIcon from '@/components/common/SvgIcon.vue';
import {tour2egg5} from '@/models/EasterEggs/EasterEgg';

Vue.use(vuescroll);

export default {
    name: 'DynamicStatistics',
    components: {
        ApexChart,
        DatePicker,
        vSelect,
        SvgIcon,
    },
    data() {
        return {
            dateFrom: getLastWeek.getLastMonday(),
            dateTo: getLastWeek.getLastSunday(),
            lang: settingsDatePickerLang,
            offerSelected: null,
            chartOptions: {
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 4,
                    curve: 'smooth',
                },
                grid: {
                    show: false,
                },
            },
            chartOptionsARCR: null,
            chartOptionsEPCEPL: null,
            seriesARCR: [],
            seriesEPCEPL: [],
            scrollOptions: {
                rail: {
                    background: '#F5F7F9',
                    size: '7px',
                    opacity: 1,
                },
                bar: {
                    background: '#C5C7D0',
                    size: '7px',
                    keepShow: true,
                    minSize: 0.2,
                },
            },
        };
    },
    computed: {
        ...mapState('statistics', [
            'statisticDynamicByOffer',
            'offers',
            'loadingStatisticDynamic',
        ]),
        ...mapState('common', [
            'fromRout',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        disabledSubmit() {
            return !this.dateFrom || !this.dateTo || !this.offerSelected;
        },
        dataTable() {
            if (this.statisticDynamicByOffer) {
                const dates = Object.keys(this.statisticDynamicByOffer.AR);
                return dates.map(date => {
                    const values = {};
                    for (const [key, value] of Object.entries(this.statisticDynamicByOffer)) {
                        values[key] = value[date];
                    }
                    return {
                        period: date,
                        ...values,
                    };
                });
            }
            return [];
        },
        mqDesktop() {
            return this.$mq === 'xl';
        },
        heightChart() {
            return this.mqDesktop ? 349 : 260;
        },
        heightTable() {
            return this.mqDesktop ? 723 : 'auto';
        },
        easterTour2Egg5() {
            return this.getAvailable(tour2egg5);
        },
    },
    watch: {
        statisticDynamicByOffer(value) {
            const dates = Object.keys(value.AR);

            this.seriesARCR = [
                {
                    name: 'AR',
                    data: Object.values(value.AR).map(item => this.formatPercent(item)),
                },
                {
                    name: 'CR',
                    data: Object.values(value.CR).map(item => this.formatPercent(item)),
                },
            ];
            this.seriesEPCEPL = [
                {
                    name: 'EPC',
                    data: Object.values(value.EPC).map(item => this.formatFixed(item)),
                },
                {
                    name: 'EPL',
                    data: Object.values(value.EPL).map(item => this.formatFixed(item)),
                },
            ];

            if (!this.chartOptionsARCR) {
                this.chartOptionsARCR = {
                    colors: ['#F4555A', '#F5C06F'],
                    chart: this.chartFormatter('arcr'),
                    ...this.chartOptions,
                    yaxis: this.yaxisFormatter(true),
                };
                Vue.nextTick(() => {
                    this.$refs.chartARCR.updateOptions({
                        legend: this.legendFormatter(value),
                        xaxis: this.xaxisFormatter(dates),
                    });
                });
            } else {
                this.$refs.chartARCR.updateOptions({
                    legend: this.legendFormatter(value),
                    xaxis: this.xaxisFormatter(dates),
                });
            }

            if (!this.chartOptionsEPCEPL) {
                this.chartOptionsEPCEPL = {
                    colors: ['#649BE9', '#7AC362'],
                    chart: this.chartFormatter('epcepl'),
                    ...this.chartOptions,
                    legend: this.legendFormatter(value),
                    xaxis: this.xaxisFormatter(dates),
                    yaxis: this.yaxisFormatter(false),
                };
            } else {
                this.$refs.chartEPLEPC.updateOptions({
                    legend: this.legendFormatter(value),
                    xaxis: this.xaxisFormatter(dates),
                });
            }
        },
        offerSelected(value) {
            if (!value) {
                this.getOffers();
            }
        },
    },
    async created() {
        if (this.fromRout.meta.offerIdSave) {
            await this.getOffers(this.fromRout.params.offerID);
            this.offerSelected = this.offers[0];
            if (!this.disabledSubmit) {
                this.submitStatisticDynamicByOffer();
            }
        } else {
            await this.getOffers();
        }
    },
    methods: {
        ...mapActions('statistics', [
            'getStatisticDynamicByOffer',
            'getOffers',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        collectEasterTour2Egg5() {
            return this.collect(this.easterTour2Egg5);
        },
        selectClick() {
            this.offerSelected = null;
        },
        dateFormatter(value) {
            return moment(value).format('YYYY-MM-DD');
        },
        submitStatisticDynamicByOffer() {
            const data = {
                dateFrom: this.dateFormatter(this.dateFrom),
                dateTo: this.dateFormatter(this.dateTo),
                offerId: this.offerSelected.id,
            };
            this.getStatisticDynamicByOffer(data)
                .then(() => {
                    this.check(tour2egg5)
                        .then(eggAvailable => {
                            if (eggAvailable && !!this.easterTour2Egg5 && this.easterTour2Egg5.needShow) {
                                const menu = document.querySelector('.apexcharts-menu');
                                const div = document.createElement('div');
                                div.classList.add('apexcharts-menu-item');
                                div.append('Та самая пасхалка');
                                div.addEventListener('click', e => {
                                    this.collectEasterTour2Egg5()
                                        .then(() => e.target.remove());
                                });

                                menu.append(div);
                            }
                        });
                });
        },
        disabledRangeFrom(date) {
            const dateMax = this.dateTo || new Date();
            return date > dateMax;
        },
        disabledRangeTo(date) {
            return date > new Date() || date < this.dateFrom;
        },
        formatFixed(value) {
            return parseFloat(value).toFixed(2);
        },
        formatPercent(value) {
            return this.formatFixed(value * 100);
        },
        offerSelectInput(value) {
            this.getOffers(value);
        },
        legendFormatter(data) {
            return {
                show: true,
                horizontalAlign: 'left',
                position: 'top',
                markers: {
                    width: 16,
                    height: 4,
                    radius: 100,
                },
                formatter: val => {
                    const elem = data[val];
                    const firstValue = elem[Object.keys(elem)[0]];
                    const lastValue = elem[Object.keys(elem)[Object.keys(elem).length - 1]];
                    const dynamicValue = lastValue - firstValue;
                    const dynamicClass = dynamicValue > 0 ? 'apexcharts-legend-dynamic__up' : 'apexcharts-legend-dynamic__down';
                    const isPercent = val === 'AR' || val === 'CR';
                    const suffix = isPercent ? '%' : '₽';

                    const lastValueFormat = isPercent
                        ? this.formatPercent(lastValue)
                        : this.formatFixed(lastValue);
                    let dynamicValueFormat = this.formatPercent(dynamicValue) + '%';
                    if (!isPercent) {
                        const diff = dynamicValue / firstValue;
                        dynamicValueFormat = parseFloat(firstValue)
                            ? this.formatPercent(diff) + '%'
                            : '';
                    }

                    const titleTemplate = `<span class="apexcharts-legend-title">Средний ${val}</span>`;
                    const currentTemplate = `<span class="apexcharts-legend-current">${lastValueFormat}${suffix}</span>`;
                    const dynamicTemplate = `<span class="apexcharts-legend-dynamic ${dynamicClass}">
                                                ${dynamicValueFormat}
                                            </span>`;
                    let valuesTemplate = `<span class="apexcharts-legend-values">${currentTemplate}</span>`;
                    if (dynamicValue) {
                        valuesTemplate = `<span class="apexcharts-legend-values">${currentTemplate} ${dynamicTemplate}</span>`;
                    }
                    return titleTemplate + valuesTemplate;
                },
            };
        },
        xaxisFormatter(data) {
            return {
                categories: data,
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    color: '#BFC3D5',
                },
            };
        },
        yaxisFormatter(percent) {
            const options = {
                axisBorder: {
                    show: true,
                    color: '#BFC3D5',
                },
                labels: {
                    minWidth: 40,
                },
            };
            if (percent) {
                options.labels.formatter = val => val + '%';
            }
            return options;
        },
        chartFormatter(id) {
            return {
                id: id,
                group: 'dynamic',
                type: 'line',
                zoom: {
                    enabled: false,
                },
                stacked: false,
                fontFamily: 'Montserrat, sans-serif',
            };
        },
        tableRowClassName({columnIndex}) {
            if (columnIndex === 0) {
                return 'el-table__cell-bold';
            }
        },
    },
};
</script>
