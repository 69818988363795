<template>
    <section class="clnt-article">
        <div class="clnt-article__grid">
            <div class="clnt-article__info">
                <h1 class="clnt-article__h1 lds-h1">
                    Приоритет <br>в категории
                </h1>
                <p>
                    Мы предоставляем возможность увеличить количество показов оффера в десятки раз, разместив его на первых позициях в списке офферов в Личном Кабинете Вебмастера.
                </p>
                <p>
                    Чем-то это похоже на рекламу в 2GIS, Yandex, Google.
                </p>
                <p>
                    По нашей статистике конверсия в подключение увеличивается примерно в 3 раза. Соответственно, эта мера позволяет увеличивать трафик ориентировочно на 10-15%.
                </p>
                <div class="clnt-article__action">
                    <div class="lds-popover lds-popover_size-sm"
                         :class="{'lds-popover_active': isVisiblePopover(key)}">
                        <button class="lds-btn lds-btn_fill -blue"
                                @click="sendRequest"
                                :disabled="isDisabledBtn(key)">
                            Отправить заявку
                        </button>
                        <div class="lds-popover__info">
                            Спасибо. Заявка принята. В ближайшее время с Вами свяжется наш менеджер.
                        </div>
                    </div>
                </div>
            </div>
            <div class="clnt-article__img">
                <img src="@/assets/images/promotion/priority_cover.png"
                     alt="Приоритет в категории">
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'Priority',
    data() {
        return {
            key: 'priority',
        };
    },
    computed: {
        ...mapState('promotion', [
            'sentPromotion',
        ]),
        ...mapGetters('promotion', [
            'isDisabledBtn',
            'isVisiblePopover',
        ]),
    },
    methods: {
        ...mapActions('promotion', [
            'featureRequest',
        ]),
        sendRequest() {
            const data = {
                key: this.key,
                feature: 'Заявка подключение Приоритета в категории',
            };
            this.featureRequest(data);
        },
    },
};
</script>
