import axios from 'axios';
import Credential from '@/models/Auth/Credential';
import Auth from '@/models/Auth/Auth';

const mutations = {
    SET_CREDENTIAL: (state, data) => {
        state.credential = data;
    },
    SET_PROCESSING: (state, data) => {
        state.processing = data;
    },
    SET_AUTH_ERROR_MESSAGE: (state, data) => {
        state.authErrorMessage = data;
    },
    SET_RESTORE_PASS_ERROR_MESSAGE: (state, data) => {
        state.restorePassErrorMessage = data;
    },
    SET_RESTORE_PASS_VALID_ERRORS: (state, data) => {
        state.restorePassValidErrors = data;
    },
    SET_RESTORE_PASS_SENT: (state, data) => {
        state.restorePassSent = data;
    },
    SET_CHANGE_PASS_ERROR_MESSAGE: (state, data) => {
        state.changePassErrorMessage = data;
    },
    SET_REGISTRATION_ERROR_MESSAGE: (state, data) => {
        state.registrationErrorMessage = data;
    },
    SET_REGISTRATION_VALID_ERROR: (state, data) => {
        state.registrationValidErrors = data;
    },
};

const actions = {
    signIn: ({ commit }, data) => {
        commit('SET_PROCESSING', true);
        return axios
            .post('/rest/account/signIn', data)
            .then(response => {
                const result = response.data;
                const credential = new Credential(
                    result.token,
                    result.expire,
                    result.refresh_token,
                );
                commit('SET_CREDENTIAL', credential);
                Auth.saveCredentials(credential);
                commit('SET_AUTH_ERROR_MESSAGE', '');
            })
            .catch(error => {
                const result = error.response.data;
                if (result.message) {
                    commit('SET_AUTH_ERROR_MESSAGE', result.message);
                } else {
                    console.error(result);
                }
            })
            .finally(() => {
                commit('SET_PROCESSING', false);
            });
    },
    signOut: ({ commit, state }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.credential.token}`,
            },
        };
        return axios
            .post(
                '/rest/account/signOut', {}, config,
            )
            .then(() => {
                Auth.removeCredentials();
                commit('SET_CREDENTIAL', null);
                commit(
                    'market/setEmptyCart', null, { root: true },
                );
            })
            .catch(error => {
                console.error(error);
            });
    },
    signUp: ({ commit }, data) => {
        commit('SET_PROCESSING', true);
        return axios
            .post('/rest/account/signUp', data)
            .then(response => {
                const result = response.data;
                const credential = new Credential(
                    result.token,
                    result.expire,
                    result.refresh_token,
                );
                commit('SET_CREDENTIAL', credential);
                Auth.saveCredentials(credential);
                commit('SET_REGISTRATION_ERROR_MESSAGE', '');
                commit('SET_REGISTRATION_VALID_ERROR', null);
            })
            .catch(error => {
                const result = error.response.data;
                if (result.errors) {
                    const resultError = result.errors;
                    const errorMessage = {};
                    if ('email' in resultError) {
                        errorMessage.email = resultError.email.join(', ');
                    }
                    if ('password_repeat' in resultError) {
                        errorMessage.password_repeat = resultError.password_repeat.join(', ');
                    }
                    commit('SET_REGISTRATION_VALID_ERROR', errorMessage);
                } else if (result.message) {
                    commit('SET_REGISTRATION_ERROR_MESSAGE', result.message);
                }
            })
            .finally(() => {
                commit('SET_PROCESSING', false);
            });
    },
    restorePass: ({ commit }, data) => {
        commit('SET_PROCESSING', true);
        return axios
            .post('/rest/account/restorePassword', data)
            .then(response => {
                const result = response.data;
                if (result.success) {
                    commit('SET_RESTORE_PASS_ERROR_MESSAGE', '');
                    commit('SET_RESTORE_PASS_VALID_ERRORS', null);
                    commit('SET_RESTORE_PASS_SENT', true);
                    setTimeout(() => {
                        commit('SET_RESTORE_PASS_SENT', false);
                    }, 60000);
                }
            })
            .catch(error => {
                const result = error.response.data;
                console.error(result);
                if (result.errors) {
                    const resultError = result.errors;
                    const errorMessage = {};
                    if ('email' in resultError) {
                        errorMessage.email = resultError.email.join(', ');
                    }
                    commit('SET_RESTORE_PASS_VALID_ERRORS', errorMessage);
                } else if (result.message) {
                    commit('SET_RESTORE_PASS_ERROR_MESSAGE', result.message);
                }
                commit('SET_RESTORE_PASS_SENT', false);
            })
            .finally(() => {
                commit('SET_PROCESSING', false);
            });
    },
    changePass: ({ commit }, data) => {
        commit('SET_PROCESSING', true);
        return axios
            .post('/rest/account/resetPassword', data)
            .then(() => {
                commit('SET_CHANGE_PASS_ERROR_MESSAGE', '');
            })
            .catch(error => {
                const result = error.response.data;
                commit('SET_CHANGE_PASS_ERROR_MESSAGE', result.message);
            })
            .finally(() => {
                commit('SET_PROCESSING', false);
            });
    },
};

const state = () => ({
    credential: null,
    processing: false,
    authErrorMessage: '',
    restorePassErrorMessage: '',
    restorePassValidErrors: null,
    restorePassSent: false,
    changePassErrorMessage: '',
    registrationErrorMessage: '',
    registrationValidErrors: null,
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
