import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index';
import Main from '@/views/Main.vue';
import Authorization from '@/views/Authorization.vue';
import RestorePassword from '@/views/RestorePassword.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import Registration from '@/views/Registration.vue';
import OmniConnect from '@/views/OmniConnect.vue';
import NotFound from '@/views/NotFound.vue';
import Conditions from '@/views/Conditions.vue';
import Offers from '@/views/Offers/index.vue';
import Offer from '@/views/Offers/detailed.vue';
import Market from '@/views/Market/index.vue';
import MarketCatalog from '@/components/market/MarketCatalog.vue';
import MarketProduct from '@/views/Market/product.vue';
import MarketOrder from '@/views/Market/order.vue';
import MarketOrders from '@/views/Market/orders.vue';
import MarketCharity from '@/views/Market/Charity/index.vue';
import MarketCharityLanding from '@/components/market/MarketCharityLanding.vue';
import MarketCharityDonations from '@/views/Market/Charity/charityDonations.vue';
import MarketCharityReportsRoot from '@/views/Market/Charity/charityReportsRoot.vue';
import MarketCharityReports from '@/views/Market/Charity/charityReports.vue';
import MarketCharityReport from '@/views/Market/Charity/charityReport.vue';
import MarketCharityPhilanthropists from '@/views/Market/Charity/сharityPhilanthropists.vue';
import LeagueRatingDescription from '@/components/leagueRating/LeagueRatingDescription.vue';
import Auth from '@/models/Auth/Auth';
import Promotion from '@/views/Promotion';
import Priority from '@/views/Promotion/priority.vue';
import Telegram from '@/views/Promotion/telegram.vue';
import Cosmo from '@/views/Promotion/cosmo.vue';
import Deployment from '@/views/Promotion/deployment.vue';
import Feedback from '@/views/Feedback.vue';
import Help from '@/views/Help.vue';
import StatisticsOfOffers from '@/components/statistics/StatisticsOfOffers.vue';
import StatisticsOfWebmasters from '@/components/statistics/StatisticsOfWebmasters.vue';
import Statistics from '@/views/Statistics/index.vue';
import Dynamic from '@/views/Statistics/dynamic.vue';
import AccrualHistory from '@/views/Market/accrualHistory';
import OutputLC from '@/views/Market/OutputLC';
import Agreement from '@/components/common/Agreement';
import TasksWrap from '@/views/Tasks/index.vue';
import TrafficContracts from '@/views/Tasks/trafficContracts';
import Tasks from '@/views/Tasks/tasks.vue';
import EasterEggsChallenge from '@/views/EasterEggs/index';
import Leaders from '@/views/Leaders/index.vue';
import LeadersGold from '@/views/Leaders/gold.vue';
import LeadersCommon from '@/views/Leaders/common.vue';
import LeadersRegulations from '@/views/Leaders/regulations.vue';
import LeadersWinners from '@/views/Leaders/winners.vue';
import EasterEggsRules from '@/components/easterEggs/easterEggsRules';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Main',
        component: Main,
    },
    {
        path: '/auth/',
        name: 'Authorization',
        component: Authorization,
        meta: {
            layout: 'login',
            title: 'Вход в личный кабинет',
            heading: 'Вход в личный кабинет',
            notAuth: true,
        },
    },
    {
        path: '/auth/restore-password/',
        name: 'RestorePassword',
        component: RestorePassword,
        meta: {
            layout: 'login',
            title: 'Восстановление пароля',
            heading: 'Забыли пароль?',
            notAuth: true,
        },
    },
    {
        // path: '/auth/change-password/',
        path: '/resetPassword/',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            layout: 'login',
            title: 'Восстановление пароля',
            heading: 'Введите новый пароль',
            notAuth: true,
        },
    },
    {
        path: '/registration/',
        name: 'Registration',
        component: Registration,
        meta: {
            layout: 'login',
            title: 'Регистрация рекламодателя',
            heading: 'Регистрация рекламодателя',
            notAuth: true,
        },
    },
    {
        path: '/conditions',
        name: 'Conditions',
        component: Conditions,
        meta: {
            title: 'Условия работы',
            notAuthRequired: true,
            breadcrumbs: {
                name: 'Условия работы',
            },
            heading: 'Условия работы',
        },
    },
    {
        path: '/offers',
        name: 'Offers',
        component: Offers,
        meta: {
            title: 'Офферы',
            nav: {
                name: 'Офферы',
            },
            breadcrumbs: {
                name: 'Офферы',
            },
            heading: 'Список офферов',
            gtm: 'Offers',
        },
        children: [
            {
                path: '/offers/:offerID',
                name: 'Offer',
                component: Offer,
                meta: {
                    layout: 'default',
                    title: 'Оффер',
                    breadcrumbs: {
                        name: '',
                        id: ':offerID',
                    },
                    heading: false,
                    gtm: 'Offer',
                    offerIdSave: true,
                },
            },
        ],
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
            nav: {
                name: 'Статистика',
            },
            breadcrumbs: {
                name: '',
            },
        },
        children: [
            {
                path: '/statistics/dynamic',
                name: 'Dynamic',
                component: Dynamic,
                meta: {
                    title: 'Динамика показателей',
                    breadcrumbs: {
                        name: 'Динамика показателей',
                    },
                    nav: {
                        name: 'Динамика показателей',
                    },
                    heading: 'Динамика показателей',
                    pageCode: 'stat-dynamic',
                    saveFromRoute: true,
                },
            },
            {
                path: '/statistics/offers',
                name: 'StatisticsOfOffers',
                component: StatisticsOfOffers,
                meta: {
                    title: 'Статистика по офферам',
                    breadcrumbs: {
                        name: 'Статистика по офферам',
                    },
                    nav: {
                        name: 'Статистика по офферам',
                    },
                    pageCode: 'stat-offers',
                },
            },
            {
                path: '/statistics/webmasters',
                name: 'StatisticsOfWebmasters',
                component: StatisticsOfWebmasters,
                meta: {
                    layout: 'default',
                    title: 'Показатели по вебмастерам',
                    breadcrumbs: {
                        name: 'Показатели по вебмастерам',
                    },
                    nav: {
                        name: 'Показатели по вебмастерам',
                    },
                    pageCode: 'stat-webmaster',
                    saveFromRoute: true,
                },
            },
        ],
    },
    {
        path: '/loyalty',
        name: 'TasksWrap',
        component: TasksWrap,
        meta: {
            nav: {
                name: 'Бонусы',
            },
            breadcrumbs: {
                name: '',
            },
        },
        children: [
            {
                path: '/loyalty/tasks',
                name: 'Tasks',
                component: Tasks,
                meta: {
                    layout: 'default',
                    title: 'Задания',
                    nav: {
                        name: 'Задания',
                    },
                    breadcrumbs: {
                        name: 'Задания',
                    },
                    pageCode: 'tasks',
                },
            },
            {
                path: '/loyalty/accrual-history',
                name: 'AccrualHistory',
                component: AccrualHistory,
                meta: {
                    title: 'История баланса',
                    breadcrumbs: {
                        name: 'История баланса',
                    },
                    nav: {
                        name: 'История баланса',
                    },
                    heading: false,
                    pageCode: 'accrual-history',
                },
            },
            {
                path: '/loyalty/traffic-contracts',
                name: 'TrafficContracts',
                component: TrafficContracts,
                meta: {
                    layout: 'default',
                    title: 'Программы лояльности',
                    nav: {
                        name: 'Программы лояльности',
                    },
                    breadcrumbs: {
                        name: 'Программы лояльности',
                    },
                    heading: 'Программы лояльности',
                    pageCode: 'TrafficContracts',
                },
            },
            {
                path: '/loyalty/output-lc',
                name: 'OutputLC',
                component: OutputLC,
                meta: {
                    title: 'Вывод средств',
                    breadcrumbs: {
                        name: 'Вывод средств',
                    },
                    nav: {
                        name: 'Вывод средств',
                    },
                },
            },
        ],
        childrenNav: [
            {
                path: '/market',
                meta: {
                    nav: {
                        name: 'Потратить',
                    },
                },
            },
        ],
    },
    {
        path: '/market',
        component: Market,
        meta: {
            nav: {
                name: 'Leads.Market',
            },
            breadcrumbs: {
                name: 'Leads.Market',
            },
            pageCode: 'market',
        },
        children: [
            {
                path: '/market',
                name: 'MarketCatalog',
                component: MarketCatalog,
                meta: {
                    layout: 'defaultWithoutHeading',
                    title: 'Leads.Market',
                    heading: 'Каталог товаров Leads.Market',
                    props: {
                        isVisibilityCartBtn: true,
                    },
                    nav: {
                        name: 'Каталог',
                    },
                },
            },
            {
                path: '/market/categories/:categoryId?',
                name: 'MarketCatalogCategories',
                component: MarketCatalog,
                meta: {
                    layout: 'defaultWithoutHeading',
                    breadcrumb: {
                        name: 'Leads.Market',
                    },
                    title: 'Leads.Market',
                    heading: 'Каталог товаров Leads.Market',
                    props: {
                        isVisibilityCartBtn: true,
                    },
                },
            },
            {
                path: '/market/product/:productId',
                name: 'MarketProduct',
                component: MarketProduct,
                meta: {
                    layout: 'defaultWithoutHeading',
                    title: 'Leads.Market',
                    breadcrumbs: {
                        name: '',
                        id: ':productId',
                    },
                    heading: '',
                    props: {
                        isVisibilityCartBtn: true,
                    },
                },
            },
            {
                path: '/market/orders',
                name: 'MarketOrders',
                component: MarketOrders,
                meta: {
                    layout: 'defaultWithoutHeading',
                    title: 'История заказов',
                    breadcrumbs: {
                        name: 'История заказов',
                    },
                    heading: 'История заказов',
                    nav: {
                        name: 'История заказов',
                    },
                },
            },
            {
                path: '/market/order',
                name: 'MarketOrder',
                component: MarketOrder,
                meta: {
                    layout: 'defaultWithoutHeading',
                    title: 'Оформление заказа',
                    breadcrumbs: {
                        name: 'Оформление заказа',
                    },
                    heading: 'Оформление заказа',
                    props: {
                        isVisibilitySelectedQuantity: true,
                    },
                },
            },
        ],
        childrenNav: [
            {
                path: '/loyalty/tasks',
                meta: {
                    nav: {
                        name: 'Заработать баллы',
                    },
                },
            },
        ],
    },
    {
        path: '/centrmama',
        component: MarketCharity,
        meta: {
            breadcrumbs: {
                name: 'Centrmama',
            },
            nav: {
                name: '❤️ Centrmama',
            },
        },
        children: [
            {
                path: '/centrmama',
                name: 'MarketCharity',
                component: MarketCharityLanding,
                meta: {
                    layout: 'defaultWithoutHeading',
                    title: 'Centrmama',
                    heading: false,
                    nav: {
                        name: 'Принять участие',
                    },
                },
            },
            {
                path: '/centrmama/donates/list',
                name: 'MarketCharityDonations',
                component: MarketCharityDonations,
                meta: {
                    layout: 'defaultWithoutHeading',
                    title: 'Журнал взносов',
                    breadcrumbs: {
                        name: 'Журнал взносов',
                    },
                    heading: false,
                    nav: {
                        name: 'Журнал взносов',
                    },
                },
            },
            {
                path: '/centrmama/reports',
                component: MarketCharityReportsRoot,
                meta: {
                    breadcrumbs: {
                        name: 'Отчеты',
                    },
                    nav: {
                        name: 'Отчеты',
                    },
                },
                children: [
                    {
                        path: '/centrmama/reports',
                        name: 'MarketCharityReports',
                        component: MarketCharityReports,
                        meta: {
                            layout: 'defaultWithoutHeading',
                            title: 'Отчеты',
                            heading: false,
                        },
                    },
                    {
                        path: '/centrmama/reports/:reportId',
                        name: 'MarketCharityReport',
                        component: MarketCharityReport,
                        meta: {
                            layout: 'defaultWithoutHeading',
                            title: 'Отчет',
                            breadcrumbs: {
                                name: '',
                                id: ':reportId',
                            },
                            heading: false,
                        },
                    },
                ],
            },
            {
                path: '/centrmama/philanthropists',
                name: 'MarketCharityPhilanthropists',
                component: MarketCharityPhilanthropists,
                meta: {
                    layout: 'defaultWithoutHeading',
                    title: 'Благотворители',
                    breadcrumbs: {
                        name: 'Благотворители',
                    },
                    heading: false,
                    nav: {
                        name: 'Благотворители',
                    },
                },
            },
        ],
    },
    {
        path: '/leaders',
        name: 'Leaders',
        component: Leaders,
        meta: {
            breadcrumbs: {
                name: 'Лидеры роста',
            },
            nav: {
                name: 'Лидеры роста',
            },
            pageCode: 'leaders',
        },
        children: [
            {
                path: '/leaders/gold',
                name: 'LeadersGold',
                component: LeadersGold,
                meta: {
                    title: 'Золотая лига',
                    breadcrumbs: {
                        name: 'Золотая лига',
                    },
                    nav: {
                        name: 'Золотая лига',
                    },
                },
            },
            {
                path: '/leaders/common',
                name: 'LeadersCommon',
                component: LeadersCommon,
                meta: {
                    title: 'Общий зачет',
                    breadcrumbs: {
                        name: 'Общий зачет',
                    },
                    nav: {
                        name: 'Общий зачет',
                    },
                },
            },
            {
                path: '/leaders/winners',
                name: 'LeadersWinners',
                component: LeadersWinners,
            },
            {
                path: '/leaders/regulations',
                name: 'LeadersRegulations',
                component: LeadersRegulations,
            },
        ],
    },
    {
        path: '/easterEggsChallenge',
        name: 'EasterEggsChallenge',
        component: EasterEggsChallenge,
        meta: {
            breadcrumbs: {
                name: 'Розыгрыш',
            },
            pageCode: 'EasterEggsChallenge',
        },
        children: [],
    },
    {
        path: '/easterEggsChallenge/rules',
        name: 'EasterEggsChallengeRules',
        component: EasterEggsRules,
        meta: {
            breadcrumbs: {
                name: 'Правила розыгрыша',
            },
            pageCode: 'EasterEggsRules',
        },
        children: [],
    },
    {
        path: '/leagues',
        name: 'LeagueRatingDescription',
        component: LeagueRatingDescription,
        meta: {
            layout: 'default',
        },
    },
    {
        path: '/agreement',
        name: 'Agreement',
        component: Agreement,
        meta: {
            layout: 'default',
            breadcrumbs: {
                name: 'Соглашение',
            },
        },
    },
    {
        path: '/promotion',
        name: 'Promotion',
        component: Promotion,
        meta: {
            breadcrumbs: {
                name: '',
            },
            pageCode: 'promotions',
        },
        children: [
            {
                path: '/promotion/priority',
                name: 'Priority',
                component: Priority,
                meta: {
                    layout: 'article',
                    title: 'Приоритет в категории',
                    breadcrumbs: {
                        name: 'Приоритет в категории',
                    },
                },
            },
            {
                path: '/promotion/telegram',
                name: 'Telegram',
                component: Telegram,
                meta: {
                    layout: 'article',
                    title: 'Реклама в Telegram-канале',
                    breadcrumbs: {
                        name: 'Реклама в Telegram-канале',
                    },
                },
            },
            {
                path: '/promotion/cosmo',
                name: 'Cosmo',
                component: Cosmo,
                meta: {
                    layout: 'article',
                    title: '#Cosmo',
                    breadcrumbs: {
                        name: '#Cosmo',
                    },
                },
            },
            {
                path: '/promotion/deployment',
                name: 'Deployment',
                component: Deployment,
                meta: {
                    layout: 'article',
                    title: 'Размещение в Leads.Market',
                    breadcrumbs: {
                        name: 'Размещение в Leads.Market',
                    },
                },
            },
        ],
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: Feedback,
        meta: {
            layout: 'default',
            title: 'Обратная связь',
            breadcrumbs: {
                name: 'Обратная связь',
            },
            heading: false,
            saveFromRoute: true,
        },
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            layout: 'default',
            title: 'Справка',
            breadcrumbs: {
                name: 'Справка',
            },
            heading: 'Справка',
        },
    },
    {
        path: '/omni/connect',
        name: 'OmniConnect',
        component: OmniConnect,
        meta: {
            layout: 'empty',
        },
    },
    {
        path: '*',
        name: 'notFound',
        component: NotFound,
        meta: {
            title: '404',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/app',
    routes,
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
});

router.beforeEach((
    to, from, next,
) => {
    const route = to.matched;
    const heading = route[route.length - 1].meta.heading;
    Store.commit('common/setHeading', heading);

    const breadcrumbs = [];
    if (route[0].meta.breadcrumbs) {
        for (let i = 0; i < route.length; i++) {
            const item = route[i];
            if (item.meta.breadcrumbs) {
                const data = {
                    name: item.meta.breadcrumbs.name,
                    path: item.path,
                    id: item.meta.breadcrumbs.id || null,
                };
                breadcrumbs.push(data);
            }
        }
    }
    Store.commit('common/setBreadcrumbs', breadcrumbs);

    if (!Auth.isAuthenticated()) {
        if (to.meta.notAuth || to.meta.notAuthRequired) {
            next();
        } else {
            Store.commit('common/setTargetRout', to.fullPath);
            next('/auth/');
        }
    } else {
        Store.commit('authorization/SET_CREDENTIAL', Auth.getCredentials());
        if (to.meta.notAuth) {
            next('/');
        } else {
            next();
        }
    }

    if (to.meta.saveFromRoute) {
        Store.commit('common/setFromRout', from);
    }
});

router.afterEach(to => {
    const prefix = to.meta.prefix || 'LEADS | ';
    const title = to.meta.title || 'Личный кабинет';
    document.title = prefix + title;
    document.body.style.overflow = '';
});

export default router;
