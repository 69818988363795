<template>
    <div>
        <div class="clnt-connect__error"
             v-if="connectAccountErrorMessage">
            {{ connectAccountErrorMessage }}
        </div>
        <div class="clnt-connect__error"
             v-if="codeErrorMessage">
            {{ codeErrorMessage }}
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'OmniErrorMessage',
    computed: {
        ...mapState('omni', [
            'connectAccountErrorMessage',
            'codeErrorMessage',
        ]),
    },
};
</script>

<style scoped>

</style>
