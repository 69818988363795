<template>
    <div class="clnt-auth">
        <form class="clnt-auth__form"
              @submit.prevent="authSubmit()">
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.user.email.$error}">
                    <label class="lds-control__label"
                           for="email">Почта</label>
                    <input type="text"
                           autocomplete="on"
                           class="lds-input"
                           id="email"
                           placeholder="email"
                           v-model="user.email"
                           @change="$v.user.email.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.email.$error && $v.user.email.$model === ''">
                        Поле обязательно для заполнения
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.user.email.$error && $v.user.email.$model !== ''">
                        Некорректный ввод. Заполните поле по шаблону example@domain.com
                    </div>
                </div>
            </div>
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.user.password.$error}">
                    <label class="lds-control__label"
                           for="password">Пароль</label>
                    <input type="password"
                           autocomplete="current-password"
                           class="lds-input"
                           id="password"
                           v-model="user.password"
                           @change="$v.user.password.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.password.$error">
                        Поле обязательно для заполнения
                    </div>
                </div>
            </div>
            <button type="submit"
                    class="lds-btn lds-btn_fill -blue">
                Вход
            </button>
        </form>
        <div class="clnt-auth__error"
             v-if="authErrorMessage">
            {{ authErrorMessage }}
        </div>
        <div class="clnt-auth__info">
            <p>Забыли пароль?</p>
            <p>
                Перейдите по
                <router-link class="lds-link"
                             :to="{name: 'RestorePassword'}">
                    ссылке
                </router-link>
                для восстановления.
            </p>
            <!--p>
                Еще не зарегистрированы?
                <router-link class="lds-link"
                             to="/registration/">
                    Создайте аккаунт
                </router-link>
            </p-->
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
// eslint-disable-next-line import/extensions
import {email, required} from 'vuelidate/lib/validators';

export default {
    name: 'AuthorizationForm',
    data() {
        return {
            user: {
                email: '',
                password: '',
            },
        };
    },
    validations: {
        user: {
            password: {
                required,
            },
            email: {
                required,
                email,
            },
        },
    },
    computed: {
        ...mapState('authorization', [
            'credential',
            'authErrorMessage',
        ]),
        ...mapState('common', [
            'targetRout',
        ]),
    },
    methods: {
        ...mapActions('authorization', [
            'signIn',
        ]),
        ...mapActions('account', [
            'loadAccountInfo',
            'loadBalance',
        ]),
        ...mapActions('tasks', [
            'loadTasks',
        ]),
        async authSubmit() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                try {
                    const data = {
                        'email': this.user.email,
                        'password': this.user.password,
                    };
                    if (this.$yaCaptcha) {
                        data.captcha = await this.$yaCaptcha.execute();
                    }

                    await this.signIn(data);
                    if (this.credential) {
                        const path = this.targetRout || '/';
                        this.$router.push(path);
                        this.loadAccountInfo();
                        this.loadBalance();
                        this.loadTasks();
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
    },
};
</script>
