<template>
    <div class="clnt-auth">
        <div class="clnt-auth__text">
            У вас уже есть аккаунт?
            <router-link class="lds-link"
                         :to="{name: 'Authorization'}">
                Войти
            </router-link>
        </div>
        <form class="clnt-auth__form"
              @submit.prevent="registrationSubmit()">
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.user.company.$error}">
                    <label class="lds-control__label"
                           for="company">Название компании</label>
                    <input type="text"
                           autocomplete="on"
                           class="lds-input"
                           id="company"
                           placeholder="Leads"
                           v-model="user.company"
                           @change="$v.user.company.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.company.$error && $v.user.company.$model == ''">
                        Поле обязательно для заполнения.
                    </div>
                </div>
            </div>
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error':
                         $v.user.email.$error ||
                         (registrationValidErrors && registrationValidErrors.email) }">
                    <label class="lds-control__label"
                           for="email">Почта</label>
                    <input type="email"
                           autocomplete="on"
                           class="lds-input"
                           id="email"
                           placeholder="email"
                           v-model="user.email"
                           @change="$v.user.email.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.email.$error && $v.user.email.$model == ''">
                        Поле обязательно для заполнения.
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.user.email.$error && $v.user.email.$model != ''">
                        Некорректный ввод. Заполните поле по шаблону example@domain.com.
                    </div>
                    <div class="lds-control__message"
                         v-if="registrationValidErrors && registrationValidErrors.email">
                        {{ registrationValidErrors.email }}
                    </div>
                </div>
            </div>
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.user.password.$error}">
                    <label class="lds-control__label"
                           for="password">Пароль</label>
                    <input type="password"
                           autocomplete="current-password"
                           class="lds-input"
                           id="password"
                           v-model="user.password"
                           @change="$v.user.password.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.password.$error && $v.user.password.$model == ''">
                        Поле обязательно для заполнения.
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.user.password.$error && $v.user.password.$model !== ''">
                        Пароль должен содержать более 8 символов.
                    </div>
                    <div class="lds-control__message"
                         v-if="false">
                        Неправильный пароль.
                    </div>
                </div>
            </div>
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error':
                         $v.user.passwordRepeat.$error ||
                         !passwordCheck ||
                         (registrationValidErrors && registrationValidErrors.password_repeat) }">
                    <label class="lds-control__label"
                           for="password-check">Подтвердить пароль</label>
                    <input type="password"
                           autocomplete="current-password"
                           class="lds-input"
                           id="password-check"
                           v-model="user.passwordRepeat"
                           @change="$v.user.passwordRepeat.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.passwordRepeat.$error">
                        Поле обязательно для заполнения.
                    </div>
                    <div class="lds-control__message"
                         v-if="!passwordCheck">
                        Пароль не совпадает.
                    </div>
                    <div class="lds-control__message"
                         v-if="registrationValidErrors && registrationValidErrors.password_repeat">
                        {{ registrationValidErrors.password_repeat }}
                    </div>
                </div>
            </div>
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.user.phone.$error}">
                    <label class="lds-control__label"
                           for="phone">Телефон</label>
                    <input type="tel"
                           autocomplete="on"
                           class="lds-input"
                           id="phone"
                           v-model="user.phone"
                           @input="inputPhone()"
                           @change="$v.user.phone.$touch()">
                    <div class="lds-control__message"
                         v-if="$v.user.phone.$error && $v.user.phone.$model === ''">
                        Поле обязательно для заполнения.
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.user.phone.$error && $v.user.phone.$model != ''">
                        Некорректный номер.
                    </div>
                </div>
            </div>
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error': $v.user.agree.$error}">
                    <div class="lds-checkbox">
                        <input type="checkbox"
                               id="agree"
                               class="lds-checkbox__checkbox"
                               v-model="user.agree"
                               @change="$v.user.agree.$touch()">
                        <label for="agree"
                               class="lds-checkbox__label">
                            Я согласен с
                            <router-link :to="{name: 'Conditions'}">
                                условиями работы
                            </router-link>
                            с сетью Leads.su
                        </label>
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.user.agree.$error">
                        Поле обязательно для заполнения.
                    </div>
                </div>
            </div>
            <div class="clnt-auth__error"
                 v-if="registrationErrorMessage">
                {{ registrationErrorMessage }}
            </div>
            <button type="submit"
                    class="lds-btn lds-btn_fill -blue"
                    :class="{'-disabled': $v.$invalid}">
                Войти в кабинет
            </button>
        </form>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import {
    required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'RegistrationForm',
    data() {
        return {
            user: {
                company: '',
                email: '',
                password: '',
                passwordRepeat: '',
                phone: '',
                agree: false,
            },
            passwordCheck: true,
        };
    },
    validations: {
        user: {
            company: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(8),
            },
            passwordRepeat: {
                required,
            },
            phone: {
                required,
                minLength: minLength(10),
            },
            agree: {
                sameAs: sameAs(() => true),
            },
        },
    },
    computed: {
        ...mapState('authorization', [
            'credential',
            'registrationErrorMessage',
            'registrationValidErrors',
        ]),
    },
    methods: {
        ...mapActions('authorization', [
            'signUp',
        ]),
        inputPhone() {
            const prefix = '+';
            const value = this.user.phone.replace(/[^0-9]/g, '');
            this.user.phone = prefix + value;
        },
        registrationSubmit() {
            this.$v.$touch();

            if (this.user.password !== this.user.passwordRepeat) {
                this.passwordCheck = false;
            } else {
                this.passwordCheck = true;
            }

            if (!this.$v.$invalid && this.passwordCheck) {
                const data = {
                    'company': this.user.company,
                    'email': this.user.email,
                    'phone': this.user.phone,
                    'first_name': '',
                    'last_name': '',
                    'password': this.user.password,
                    'password_repeat': this.user.passwordRepeat,
                    'agree': this.user.agree,
                };

                this.signUp(data)
                    .then(() => {
                        if (this.credential) {
                            this.$router.push('/');
                        }
                    })
                    .catch(error => console.error(error));
            }
        },
    },
};
</script>
