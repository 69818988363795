const CODE_MARKET_ADV_ORDER = 'market_adv_order';
const CODE_LOYALTY_ADV_REWARD = 'loyalty_adv_reward';
const CODE_REJECT_LOYALTY_ADV_REWARD = 'reject_loyalty_adv_reward';

const CODE = {
    [CODE_MARKET_ADV_ORDER]: 'blue',
    [CODE_LOYALTY_ADV_REWARD]: 'green',
    [CODE_REJECT_LOYALTY_ADV_REWARD]: 'red',
};

export default class AccrualHistoryType {
    constructor(
        code, id, title,
    ) {
        this.code = code;
        this.id = id;
        this.title = title;
    }

    get className() {
        return CODE[this.code] || null;
    }
}
