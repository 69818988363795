<template>
    <div v-show="code">
        <div class="clnt-connect__title">
            Введите код в чате
        </div>
        <div class="clnt-connect__code"
             ref="codeValue"
             @click="copyCode"
             @copy="copyCode">
            {{ formattingCode(code) }}
        </div>
        <div class="clnt-connect__timer"
             v-show="codeAvailable">
            Время действия кода закончится через
            <timer ref="timerForCode"
                   :time="codeLiveTimeMS"
                   @timerEnd="timerEnd()"
                   class="clnt-connect__timer-value" />
        </div>
        <div class="clnt-connect__timer"
             v-if="!codeAvailable">
            Время действия кода истекло
        </div>
        <div class="clnt-connect__actions">
            <div class="clnt-connect__actions-item clnt-connect__info">
                <button class="clnt-connect__btn lds-btn lds-btn_fill -blue"
                        @click="copyCode">
                    Скопировать код
                </button>
                <transition name="fade">
                    <div class="clnt-connect__info-mess"
                         v-if="codeCopied">
                        Код скопирован
                    </div>
                </transition>
            </div>
            <button class="clnt-connect__actions-item clnt-connect__btn lds-btn lds-btn_fill -gray"
                    :class="{'-disabled': codeAvailable}"
                    :disabled="codeAvailable"
                    data-test="update_code"
                    @click="getCode">
                Обновить код
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Timer from '@/components/common/Timer';

export default {
    name: 'OmniCodeConnectionPage',
    components: {
        Timer,
    },
    props: {
        code: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            codeAvailable: true,
            codeCopied: false,
        };
    },
    computed: {
        ...mapGetters('omni', [
            'codeLiveTimeMS',
        ]),
    },
    methods: {
        timerEnd() {
            this.codeAvailable = false;
        },
        formattingCode(val) {
            return val.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
        },
        getCode() {
            this.$emit('getCode');
            this.codeAvailable = true;
        },
        copyCode() {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(this.code);
            } else {
                const codeElement = this.$refs.codeValue;
                const codeText = codeElement.textContent.replace(/ /g, '');
                const textarea = document.createElement('textarea');
                textarea.value = codeText;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('Copy');
                textarea.remove();
            }

            this.codeCopied = true;
            setTimeout(() => {
                this.codeCopied = false;
            }, 3000);
        },
    },

};
</script>

<style scoped>

</style>
