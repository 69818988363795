export default class Account {
    /**
     *
     * @param id
     * @param name
     * @param phone
     * @param email
     */

    constructor(
        id, name, phone, email,
    ) {
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.email = email;
    }
}
