<template>
    <div class="lds-container">
        <div class="lm-order">
            <div class="lm-order__cart">
                <Cart />
            </div>
            <div class="lm-order__contacts">
                <div v-if="pricesMaintenance"
                     class="lm-order__form">
                    <div class="lm-order__form-title">
                        Друзья!
                    </div>
                    <div class="lm-order__form-total-text">
                        Прямо сейчас мы следим за курсом рубля, чтобы сохранить для вас лучшие цены.<br>
                        Обновленный LEADS MARKET будет запущен после выходных!
                    </div>
                </div>

                <form class="lm-order__form"
                      v-else>
                    <div class="lm-order__form-title">
                        Адрес доставки
                    </div>
                    <div class="lm-order__form-delivery"
                         v-if="accountContacts">
                        <Contact v-for="item in accountContacts"
                                 :key="item.id"
                                 :contact="item"
                                 :active="isSelected(item)"
                                 @delete="unselectContact(item)"
                                 @select="selectContact(item)" />
                    </div>
                    <div class="lm-order__form-add">
                        <button class="lm-btn lm-btn_add"
                                @click.prevent="openModalNewContact()">
                            + Добавить новый адрес доставки
                        </button>
                    </div>
                    <div class="lm-order__form-sum">
                        <div class="lm-order__form-title">
                            Общая стоимость заказа
                        </div>
                        <div class="lm-order__form-discount-block"
                             v-if="cart.hasDiscount">
                            <div class="lm-order__form-total">
                                <div class="lm-order__form-total-text">
                                    Товары
                                </div>
                                <div class="lm-order__form-total-value -goods">
                                    {{ formatCoins(cartTotalAmountWithoutDiscount) }}
                                    <SvgIcon name="leads-coins" />
                                </div>
                            </div>
                            <div class="lm-order__form-total">
                                <div class="lm-order__form-total-text">
                                    Скидка
                                </div>
                                <div class="lm-order__form-total-value -discount">
                                    - {{ formatCoins(cartTotalDiscount) }}
                                    <SvgIcon name="leads-coins" />
                                </div>
                            </div>
                        </div>

                        <div class="lm-order__form-total">
                            <div class="lm-order__form-total-text">
                                Итого
                            </div>
                            <div class="lm-order__form-total-value">
                                {{ formatCoins(cartTotalAmount) }}
                                <SvgIcon name="leads-coins" />
                            </div>
                        </div>
                        <div class="lm-order__form-notice"
                             v-if="!canBuy">
                            Недостаточно средств
                        </div>
                    </div>
                    <button class="lds-btn lds-btn_fill lds-btn_size-lg lds-btn_full -dark"
                            :disabled="orderDisabled"
                            @click.prevent="makeOrder()">
                        Оформить заказ
                    </button>
                </form>
            </div>
        </div>
        <ContactCardForm
            id="contactCardForm"
            v-if="modalNewContact"
            @close="closeModalNewContact()" />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Cart from '@/components/market/MarketCart.vue';
import Contact from '@/components/market/MarketContact.vue';
import ContactCardForm from '@/components/market/MarketContactCardForm.vue';
import SvgIcon from '@/components/common/SvgIcon.vue';
import formattingCoins from '@/mixins/formattingCoins';

export default {
    name: 'OrderPage',
    components: {
        Cart,
        Contact,
        ContactCardForm,
        SvgIcon,
    },
    mixins: [
        formattingCoins,
    ],
    data: () => ({
        selectedContact: null,
        modalNewContact: false,
        messageSuccess: 'Ваш заказ успешно оформлен',
        pricesMaintenance: false,
    }),
    computed: {
        ...mapState('account', [
            'accountInfo',
            'accountBalance',
        ]),
        ...mapState('market', [
            'cart',
            'accountContacts',
        ]),
        cartTotalAmount() {
            return this.cart.totalAmount;
        },
        cartTotalAmountWithoutDiscount() {
            return this.cart.totalOldAmount;
        },
        cartTotalDiscount() {
            return this.cart.totalDiscount;
        },
        canBuy() {
            if (this.accountBalance) {
                return parseFloat(this.accountBalance) >= parseFloat(this.cartTotalAmount);
            }
            return false;
        },
        orderDisabled() {
            return this.loading || (this.selectedContact === null) || this.cart.isEmpty || !this.canBuy;
        },
    },
    methods: {
        ...mapActions('account', [
            'loadBalance',
        ]),
        ...mapActions('market', [
            'sendOrder',
        ]),
        ...mapMutations('market', [
            'setSelectedCartContact',
        ]),
        ...mapMutations('common', [
            'setNotification',
        ]),
        selectContact(contact) {
            this.selectedContact = contact;
        },
        unselectContact(contact) {
            if (contact === this.selectedContact) {
                this.selectedContact = null;
            }
        },
        isSelected(contact) {
            return this.selectedContact && (contact.id === this.selectedContact.id);
        },
        openModalNewContact() {
            this.modalNewContact = true;
        },
        closeModalNewContact() {
            this.modalNewContact = false;
        },
        makeOrder() {
            if (!this.loading) {
                this.setSelectedCartContact(this.selectedContact);
                return this.sendOrder()
                    .then(() => {
                        this.loadBalance();
                        this.$router.push({
                            name: 'MarketOrders',
                        });
                        this.setNotification({
                            value: this.messageSuccess,
                            type: 'success',
                        });
                    })
                    .catch(error => {
                        this.setNotification({
                            value: error,
                            type: 'error',
                        });
                    });
            }
        },
    },
};
</script>
