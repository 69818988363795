<template>
    <DynamicStatistics />
</template>

<script>
import DynamicStatistics from '@/components/statistics/DynamicStatistics.vue';

export default {
    name: 'StatisticsDynamicPage',
    components: {
        DynamicStatistics,
    },
};
</script>
