import Vue from 'vue';
import axios from 'axios';
import Category from '@/models/Market/Category';
import Product from '@/models/Market/Product';
import Cart from '@/models/Market/Cart';
import CartPosition from '@/models/Market/CartPosition';
import AccountContact from '@/models/Market/AccountContact';
import AccountAddress from '@/models/Market/AccountAddress';
import Order from '@/models/Market/Order';
import OrderStatus from '@/models/Market/OrderStatus';
import AccrualHistoryItem from '@/models/Market/AccrualHistoryItem';
import AccrualHistoryType from '@/models/Market/AccrualHistoryType';

const mutations = {
    setLoading: (state, data) => {
        state.loading = data;
    },
    setCategory: (state, data) => {
        Vue.set(
            state.categories, data.id, data,
        );
    },
    setProduct: (state, data) => {
        Vue.set(
            state.products, data.id, data,
        );
    },
    appendProductToCart: (state, data) => {
        const positions = state.cart.cartPositions;

        Vue.set(
            positions, data.id, new CartPosition(
                data,
                1,
                data.salePrice.amount,
                data.oldSalePrice ? data.oldSalePrice.amount : null,
            ),
        );
    },
    setEmptyCart: state => {
        state.cart = new Cart();
    },
    setSelectedCartContact: (state, data) => {
        Vue.set(
            state.cart, 'contact', data,
        );
    },
    setAccountContacts: (state, data) => {
        state.accountContacts = data;
    },
    appendAccountContact: (state, data) => {
        Vue.set(
            state.accountContacts, data.id, data,
        );
    },
    deleteAccountContact: (state, id) => {
        Vue.delete(state.accountContacts, id);
    },
    setCountries: (state, data) => {
        state.countries = data;
    },
    setRegions: (state, data) => {
        state.regions = data;
    },
    setOrder: (state, data) => {
        Vue.set(
            state.orders, data.id, data,
        );
    },
    setTarget: (state, data) => {
        state.target = data;
    },
    setPriceFrom: (state, value) => {
        if (Number.isNaN(value)) {
            state.priceFrom = '';
        }
        state.priceFrom = value;
    },
    setPriceTo: (state, value) => {
        if (Number.isNaN(value)) {
            state.priceTo = '';
        }
        state.priceTo = value;
    },
    setAccrualHistory: (state, data) => {
        state.accrualHistory = data;
    },
    setAccrualHistoryLoading: (state, data) => {
        state.accrualHistoryLoading = data;
    },
    setOperationsTypes: (state, data) => {
        state.operationsTypes = data;
    },
};

const actions = {
    loadAccountContacts: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get('/rest/market/account/get', config)
            .then(response => {
                const accountData = response.data.data;
                const contactsData = accountData.contacts;
                const contacts = {};
                for (const contactData of contactsData) {
                    contacts[contactData.id] = new AccountContact(
                        contactData.id,
                        contactData.fullName,
                        contactData.phones,
                        contactData.email,
                        new AccountAddress(
                            contactData.address.country,
                            contactData.address.region,
                            contactData.address.city,
                            contactData.address.cityAddress,
                        ),
                    );
                }
                commit('setAccountContacts', contacts);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    loadCategories: ({ commit, rootState }) => {
        commit('setLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get('/rest/market/categories', config)
            .then(response => {
                for (const categoryData of response.data.data) {
                    commit('setCategory', new Category(
                        categoryData.id,
                        categoryData.name,
                        categoryData.status,
                        categoryData.description,
                        categoryData.created,
                        categoryData.modified,
                    ));
                }
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    loadProducts: ({ commit, state, rootState }) => {
        commit('setLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get('/rest/market/products', config)
            .then(response => {
                for (const productData of response.data.data) {
                    const category = state.categories[productData.categoryId] || null;
                    commit('setProduct', new Product(
                        productData.id,
                        category,
                        productData.name,
                        productData.alias,
                        productData.description,
                        productData.shortDescription,
                        productData.status,
                        productData.mainImage,
                        productData.salePrice,
                        productData.oldSalePrice,
                        productData.created,
                        productData.modified,
                    ));
                }
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    loadCartFromLocalStorage: ({ state, rootState }) => {
        const cart = JSON.parse(localStorage.getItem('cart')) || {};
        const idAccount = rootState.account.accountInfo.id;
        const products = state.products;

        if (cart[idAccount]) {
            const positions = cart[idAccount].idProducts;

            if (positions) {
                for (const prop in positions) {
                    const product = products[prop];
                    const item = positions[prop];

                    if (!product) {
                        continue;
                    }

                    Vue.set(
                        state.cart.cartPositions, item.id, new CartPosition(
                            product,
                            item.count,
                            product.salePrice.amount,
                            product.oldSalePrice ? product.oldSalePrice.amount : null,
                        ),
                    );
                }
            }
        }
    },
    addToCart: ({ commit, state, rootState }, data) => {
        commit('appendProductToCart', data);

        const positions = state.cart.cartPositions;
        const idProduct = data.id;
        const idAccount = rootState.account.accountInfo.id;

        const cartLocal = JSON.parse(localStorage.getItem('cart')) || {};
        const listProducts = {
            idAccount: idAccount,
            idProducts: cartLocal[idAccount] ? cartLocal[idAccount].idProducts : {},
        };
        listProducts.idProducts[idProduct] = {
            id: idProduct,
            count: positions[idProduct].count,
        };
        cartLocal[idAccount] = listProducts;
        localStorage.setItem('cart', JSON.stringify(cartLocal));
    },
    incrementCount: ({ state, rootState }, data) => {
        const positions = state.cart.cartPositions;
        const idProduct = data.id;
        const idAccount = rootState.account.accountInfo.id;

        positions[idProduct].increment();

        const cartLocal = JSON.parse(localStorage.getItem('cart'));
        if (cartLocal) {
            cartLocal[idAccount].idProducts[idProduct].count += 1;
            localStorage.setItem('cart', JSON.stringify(cartLocal));
        }
    },
    decrementCount: ({ state, rootState }, data) => {
        const positions = state.cart.cartPositions;
        const idProduct = data.product.id;
        const idAccount = rootState.account.accountInfo.id;
        const element = positions[idProduct];
        const count = element.count;

        element.decrement();

        const cartLocal = JSON.parse(localStorage.getItem('cart')) || {};
        if (cartLocal) {
            const productCurrent = cartLocal[idAccount].idProducts[idProduct];
            let countOld = productCurrent.count;
            const countNew = countOld < 0 ? countOld-- : 1;
            productCurrent.count = countNew;
        }

        if (data.remove && count === 1) {
            Vue.delete(positions, idProduct);
            if (cartLocal) {
                delete cartLocal[idAccount].idProducts[idProduct];
            }
        }

        localStorage.setItem('cart', JSON.stringify(cartLocal));
    },
    changeCount: ({ state, rootState }, data) => {
        const positions = state.cart.cartPositions;
        const idProduct = data.product.id;
        const idAccount = rootState.account.accountInfo.id;
        const value = data.value;

        positions[idProduct].changeCount(value);

        const cartLocal = JSON.parse(localStorage.getItem('cart'));
        if (cartLocal) {
            cartLocal[idAccount].idProducts[idProduct].count = value;
            localStorage.setItem('cart', JSON.stringify(cartLocal));
        }
    },
    clearCart: ({ commit, rootState }) => {
        commit('setEmptyCart');

        const idAccount = rootState.account.accountInfo.id;
        const cartLocal = JSON.parse(localStorage.getItem('cart')) || {};

        if (cartLocal) {
            delete cartLocal[idAccount];
            localStorage.setItem('cart', JSON.stringify(cartLocal));
        }
    },
    removeFromCart: ({ state, rootState }, id) => {
        const positions = state.cart.cartPositions;
        const idAccount = rootState.account.accountInfo.id;
        const cartPosition = positions[id];

        if (!cartPosition) {
            return;
        }

        Vue.delete(positions, id);

        const cartLocal = JSON.parse(localStorage.getItem('cart')) || {};
        if (cartLocal) {
            delete cartLocal[idAccount].idProducts[id];
            localStorage.setItem('cart', JSON.stringify(cartLocal));
        }
    },
    sendOrder: ({
        commit, dispatch, state, rootState,
    }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        const cart = state.cart;
        const itemsOrder = [];
        for (const position of Object.values(cart.cartPositions)) {
            itemsOrder.push({
                productId: position.product.id,
                count: position.count,
            });
        }
        const data = {
            contactId: cart.contact.id,
            items: itemsOrder,
        };
        return axios.post(
            '/rest/market/orders/create', data, config,
        )
            .then(response => {
                const orderData = response.data.data;
                const order = new Order(
                    orderData.id,
                    orderData.items.map(item => {
                        const itemCartPosition = new CartPosition(
                            state.products[item.product.id],
                            item.count,
                            item.salePrice.amount,
                            item.oldSalePrice ? item.oldSalePrice.amount : null,
                        );
                        return itemCartPosition;
                    }),
                    state.accountContacts[orderData.contact.id],
                    new OrderStatus(orderData.status),
                    orderData.trackNumber,
                    orderData.created,
                );
                commit('setOrder', order);
                dispatch('clearCart');
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    addNewContact: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.post(
            '/rest/market/account/addContact', data, config,
        )
            .then(response => {
                const result = response.data.data;
                const address = result.address;
                commit('appendAccountContact', new AccountContact(
                    result.id,
                    result.fullName,
                    result.phones,
                    result.email,
                    new AccountAddress(
                        address.country,
                        address.region,
                        address.city,
                        address.cityAddress,
                    ),
                ));
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    deleteContact: ({ commit, rootState }, id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get(`/rest/market/account/deleteContact?id=${id}`, config)
            .then(() => {
                commit('deleteAccountContact', id);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    loadGeoCountries: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get('/rest/market/geo/countries', config)
            .then(response => {
                const result = response.data;
                commit('setCountries', result);
            })
            .catch(error => {
                throw error;
            });
    },
    loadGeoRegions: ({ commit, rootState }, id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get(`/rest/market/geo/regions?country_id=${id}`, config)
            .then(response => {
                const result = response.data;
                commit('setRegions', result);
            })
            .catch(error => {
                throw error;
            });
    },
    loadOrders: ({ commit, state, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get('/rest/market/orders/all', config)
            .then(response => {
                for (const orderData of response.data.data) {
                    const items = orderData.items.map(item => {
                        const itemData = new CartPosition(
                            new Product(
                                item.product.id,
                                state.categories[item.product.categoryId],
                                item.product.name,
                                item.product.alias,
                                item.product.description,
                                item.product.shortDescription,
                                item.product.status,
                                item.product.mainImage,
                                item.product.salePrice,
                                item.product.oldSalePrice,
                                item.product.created,
                                item.product.modified,
                            ),
                            item.count,
                            item.salePrice.amount,
                            item.oldSalePrice ? item.oldSalePrice.amount : null,
                        );
                        return itemData;
                    });
                    const contact = state.accountContacts[orderData.contact.id] || new AccountContact(
                        orderData.contact.id,
                        orderData.contact.fullName,
                        orderData.contact.phones,
                        orderData.contact.email,
                        new AccountAddress(
                            orderData.contact.address.country,
                            orderData.contact.address.region,
                            orderData.contact.address.city,
                            orderData.contact.address.cityAddress,
                        ),
                    );
                    const order = new Order(
                        orderData.id,
                        items,
                        contact,
                        new OrderStatus(orderData.status),
                        orderData.trackNumber,
                        orderData.created,
                    );
                    commit('setOrder', order);
                }
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getAccrualHistory: ({ commit, rootState }, payload) => {
        commit('setAccrualHistoryLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        let query = `page=${payload.page}&perPage=${payload.perPage}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`;
        if (payload.type) {
            query = query + `&operationTypeId=${payload.type}`;
        }
        if (payload.id) {
            query = query + `&operationId=${payload.id}`;
        }
        return axios.get(`/rest/market/history/get?${query}`, config)
            .then(response => {
                const result = response.data.data;
                commit('setAccrualHistory', {
                    hasMore: result.hasMore,
                    items: result.items.map(item => new AccrualHistoryItem(
                        item.id,
                        item.approvedPointsChange,
                        item.comment,
                        item.created,
                        new AccrualHistoryType(
                            item.operationType.code,
                            item.operationType.id,
                            item.operationType.title,
                        ),
                        item.orderedPointsChange,
                    )),
                });
                commit('setAccrualHistoryLoading', false);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getOperationsTypes: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios.get('/rest/market/history/operationsTypes', config)
            .then(response => {
                const list = [
                    {
                        id: 0,
                        name: 'Все',
                        label: 'Все',
                    },
                    ...response.data.map(item => ({
                        id: item.id,
                        name: item.title,
                        label: item.title,
                    })),
                ];
                commit('setOperationsTypes', list);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const state = () => ({
    cart: new Cart(),
    heading: '',
    loading: true,
    categories: {},
    products: {},
    accountContacts: null,
    countries: [],
    regions: [],
    orders: [],
    priceFrom: '',
    priceTo: '',
    accrualHistory: null,
    accrualHistoryLoading: false,
    operationsTypes: [],
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
