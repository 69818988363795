export default class NotificationService {
    static buildNotificationMessage(data) {
        let messageString;
        if (data.response && data.response.data.errors) {
            messageString = this.dataToMessage(data.response.data);
        } else if (data.response && data.response.data.message) {
            messageString = data.response.data.message;
        } else if (data.request && data.status) {
            messageString = 'SERVER ERROR Status: ' + data.request.status + ' Status text: ' + data.request.statusText;
        } else if (data.response && (data.response.status === 403)) {
            messageString = 'Доступ запрещен';
        } else if (data.message) {
            messageString = '';
        } else {
            messageString = data;
        }

        return messageString;
    }

    static dataToMessage(response) {
        if (!response.errors) {
            return null;
        }

        const errors = [];
        for (const field in response.errors) {
            if (!response.errors.hasOwnProperty(field)) {
                continue;
            }
            const errorItem = response.errors[field];
            if (typeof errorItem === 'string' || errorItem instanceof String) {
                errors.push(errorItem);
            } else {
                errorItem.forEach(error => errors.push(error));
            }
        }

        return errors.join('<br>');
    }
}
