export default class Round {
    constructor(
        id,
        title,
        available,
        isCurrent,
        isTallyCurrent,
        firstPlaceReward,
        otherReward,
        welcomeShown,
        dateFrom,
        dateTo,
        tallyDateFrom,
        tallyDateTo,
        eggs,
    ) {
        this.id = id;
        this.title = title;
        this.available = available;
        this.isCurrent = isCurrent;
        this.isTallyCurrent = isTallyCurrent;
        this.firstPlaceReward = firstPlaceReward;
        this.otherReward = otherReward;
        this.welcomeShown = welcomeShown;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.tallyDateFrom = tallyDateFrom;
        this.tallyDateTo = tallyDateTo;
        this.eggs = Object.values(eggs).sort((a, b) => a.order - b.order);
    }

    get countAll() {
        return this.eggs.length;
    }

    get countCollected() {
        return this.eggs.filter(egg => egg.collected).length;
    }

    get progressPercent() {
        return ((this.countCollected / this.countAll) * 100).toFixed(2);
    }

    get isAllCollected() {
        return this.countAll === this.countCollected;
    }

    setEgg(egg) {
        const eggIndex = this.eggs.indexOf(egg);

        this.eggs[eggIndex] = egg;
    }
}
