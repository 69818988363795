<template>
    <div v-if="existingLeague"
         class="lds-popover lds-popover_lg lds-popover_hover"
         @mouseenter="mouseenter()">
        <router-link :to="{name: 'LeagueRatingDescription'}">
            <SvgIcon class="lds-popover__ico"
                     :name="icoName" />
        </router-link>
        <div class="lds-popover__info">
            <div class="lds-popover__content clnt-offer-league">
                <div class="clnt-offer-league__icon">
                    <SvgIcon :name="icoName" />
                    <div class="clnt-offer-league__name">
                        {{ title }}
                    </div>
                </div>
                <p class="clnt-offer-league__description">
                    Лиги (Олимпиада Офферов) - система оценки офферов исходя из рейтинга.
                    Чем выше рейтинг, тем выше Лига.
                </p>
                <router-link class="clnt-offer-league__link"
                             :to="{name: 'LeagueRatingDescription'}">
                    Узнать больше
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon';
import { LEAGUES_CODE, LEAGUES_TITLE } from '@/services/enumLeague';

export default {
    name: 'League',
    components: {
        SvgIcon,
    },
    props: {
        league: {
            type: Number,
            required: true,
        },
    },
    computed: {
        existingLeague() {
            return this.league && this.icoName;
        },
        icoName() {
            const code = LEAGUES_CODE[this.league] || '';
            return code ? `league-${code}` : '';
        },
        title() {
            return LEAGUES_TITLE[this.league] || '';
        },
    },
    methods: {
        mouseenter() {
            this.$emit('mouseenter');
        },
    },
};
</script>
