<template>
    <div class="lm-page">
        <template v-if="!maintenance">
            <MarketHeader />
            <router-view />
        </template>
        <Stub v-else
              class="clnt-stub_sm"
              title="Уважаемые партнеры!"
              :text="textMaintenance"
              :actions="false" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MarketHeader from '@/components/market/MarketHeader';
import Stub from '@/components/common/Stub';
import settingsMaintenance from '@/mixins/settingsMaintenance';

export default {
    name: 'Market',
    components: {
        MarketHeader,
        Stub,
    },
    mixins: [
        settingsMaintenance,
    ],
    computed: {
        ...mapState('common', [
            'maintenance',
        ]),
    },
    created() {
        this.loadAccountContacts()
            .then(() => this.loadCategories())
            .then(() => this.loadProducts())
            .then(() => this.loadCartFromLocalStorage())
            .then(() => this.loadOrders());
    },
    methods: {
        ...mapActions('market', [
            'loadAccountContacts',
            'loadCategories',
            'loadProducts',
            'loadCartFromLocalStorage',
            'loadOrders',
        ]),
    },
};
</script>
