<template>
    <div v-if="info"
         class="clnt-el-table">
        <el-table
            v-if="info.items.length"
            class="clnt-el-table__table"
            :cell-class-name="tableRowClassName"
            :data="info.items">
            <el-table-column
                prop="id"
                label="ID операции"
                width="90" />
            <el-table-column
                prop="operationType.title"
                label="Тип операции"
                width="150" />
            <el-table-column
                prop="created"
                label="Дата создания"
                width="90" />
            <el-table-column
                prop="approvedPointsChange"
                label="Баланс баллов"
                width="90">
                <template slot-scope="scope">
                    {{ formatLC(scope.row.approvedPointsChange) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="orderedPointsChange"
                label="Заказано"
                width="90">
                <template slot-scope="scope">
                    {{ formatLC(scope.row.orderedPointsChange) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="comment"
                label="Комментарий"
                width="120" />
        </el-table>
        <p v-else
           class="clnt-el-table__empty">
            Нет данных
        </p>
    </div>
</template>

<script>
import formattingCoins from '@/mixins/formattingCoins';

export default {
    name: 'AccrualHistoryTable',
    mixins: [
        formattingCoins,
    ],
    props: {
        info: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    methods: {
        tableRowClassName({row, column, columnIndex}) {
            const property = column.property;
            const classCell = 'clnt-el-table__cell-';

            if (columnIndex === 0) {
                return classCell + 'bold';
            }

            if (property === 'approvedPointsChange') {
                if (Number(row.approvedPointsChange) > 0) {
                    return classCell + 'green';
                } if (Number(row.approvedPointsChange) < 0) {
                    return classCell + 'red';
                }
                return classCell + 'gray';
            }

            if (property === 'orderedPointsChange') {
                if (Number(row.orderedPointsChange) > 0) {
                    return classCell + 'green';
                } if (Number(row.orderedPointsChange) < 0) {
                    return classCell + 'red';
                }
                return classCell + 'gray';
            }

            if (property === 'operationType.title') {
                return classCell + row.operationType.className;
            }
        },
        formatLC(value) {
            return this.formatCoins(value);
        },
    },
};
</script>
