<template>
    <div class="clnt-body">
        <div class="lds-container">
            <div class="clnt-main">
                <div class="clnt-main__left">
                    <div class="clnt-main__item">
                        <Summary />
                    </div>
                    <div v-if="!maintenance && tasksPreview.length"
                         class="clnt-main__item">
                        <div class="clnt-main__heading">
                            <h2 class="clnt-main__title">
                                Задания
                            </h2>
                            <router-link :to="{name: 'Tasks'}"
                                         class="lds-link">
                                Смотреть все
                            </router-link>
                        </div>
                        <TasksPreview :tasks="tasksPreview" />
                    </div>
                </div>
                <div class="clnt-main__right">
                    <div class="clnt-main__item">
                        <Instruments />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TasksPreview from '@/components/tasks/TasksPreview';
import Instruments from '@/components/main/Instruments';
import Summary from '@/components/summary/Summary';

export default {
    name: 'Main',
    components: {
        Summary,
        TasksPreview,
        Instruments,
    },
    computed: {
        ...mapState('common', [
            'maintenance',
        ]),
        ...mapGetters('tasks', [
            'getSortStatuses',
        ]),
        tasksPreview() {
            return this.getSortStatuses(['run', 'new']).slice(0, 3);
        },
    },
};
</script>
