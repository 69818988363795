<template>
    <article class="clnt-leaders-offer"
             :class="{'clnt-leaders-offer_accent': isJoined}">
        <p v-if="place"
           class="clnt-leaders-offer__position">
            {{ place }} место
        </p>
        <h3 class="clnt-leaders-offer__title">
            {{ offer.id }}
        </h3>
        <router-link :to="{
                         name: 'Offer',
                         params: {
                             offerID: offer.id,
                         },
                     }"
                     class="clnt-leaders-offer__link">
            {{ offer.title }}
        </router-link>
        <ul class="clnt-leaders-offer__info">
            <li class="clnt-leaders-offer__info-item">
                <p class="clnt-leaders-offer__info-title">
                    Текущий рейтинг:
                </p>
                <p class="clnt-leaders-offer__info-value">
                    {{ offer.rating }}
                </p>
            </li>
        </ul>
        <button v-if="!isJoined"
                class="clnt-leaders-offer__btn lds-btn lds-btn_full lds-btn_fill"
                @click="join()">
            Подключить
        </button>
        <button v-else
                class="clnt-leaders-offer__btn lds-btn lds-btn_full lds-btn_fill -gray"
                @click="leave()">
            Отключить
        </button>
    </article>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'LeadersOffer',
    props: {
        offer: {
            type: Object,
            default() {
                return null;
            },
        },
        currentRound: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    computed: {
        ...mapState('leaders', [
            'typeRound',
        ]),
        offerAdditionalData() {
            return this.currentRound && this.currentRound.participants.find(item => Number(item.offer.id) === Number(this.offer.id));
        },
        isJoined() {
            return !!this.offerAdditionalData;
        },
        place() {
            if (this.isJoined) {
                return this.offerAdditionalData.place;
            }
            return '';
        },
    },
    methods: {
        ...mapActions('leaders', [
            'joinToRound',
            'leaveFromRound',
        ]),
        async join() {
            const data = {
                offerId: this.offer.id,
                roundId: this.currentRound.id,
            };
            await this.joinToRound(data);
            this.$emit('update');
        },
        async leave() {
            const data = {
                offerId: this.offer.id,
                roundId: this.currentRound.id,
            };
            await this.leaveFromRound(data);
            this.$emit('update');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
