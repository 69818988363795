export default {
    methods: {
        serialize(object) {
            const parameters = [];
            for (const property in object) {
                if (object.hasOwnProperty(property)) {
                    parameters.push(encodeURI(property + '=' + object[property]));
                }
            }
            return parameters.join('&');
        },
    },
};
