export default class Order {
    constructor(
        id,
        positions,
        contact,
        status,
        trackNumber,
        created,
    ) {
        this.id = id;
        this.positions = positions;
        this.contact = contact;
        this.status = status;
        this.trackNumber = trackNumber;
        this.created = new Date(Date.parse(created.replace(/ /g, 'T')));
    }

    get positionsSumAmount() {
        return this.positions.reduce((accumulator, position) => accumulator + position.sumPrice, 0);
    }

    get dateFormatter() {
        return new Intl.DateTimeFormat('ru');
    }

    get formattedCreated() {
        return this.dateFormatter.format(this.created);
    }
}
