import axios from 'axios';

const actions = {
    sendFeedback: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .post(
                '/rest/feedBack/send', data, config,
            )
            .then(() => {
                const dataNotification = {
                    value: 'Ваше сообщение успешно отправлено',
                    type: 'success',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
                return true;
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
                return false;
            });
    },
};

export default {
    namespaced: true,
    actions,
};
