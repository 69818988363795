<template>
    <div class="lds-container">
        <div class="lds-agreement"
             v-html="htmlTextAgreement" />
    </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    name: 'Agreement',
    mounted() {
        this.$store.dispatch('agreementLC/getAgreementTextLC');
    },
    computed: {
        ...mapState('agreementLC', [
            'agreement',
        ]),
        htmlTextAgreement() {
            return this.agreement;
        },
    },
};
</script>

<style scoped>

</style>
