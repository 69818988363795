import axios from 'axios';
import Account from '@/models/Account/Account';

const mutations = {
    setAccountInfo: (state, data) => {
        state.accountInfo = data;
    },
    setAccountBalance: (state, data) => {
        state.accountBalance = data;
    },
};

const actions = {
    loadAccountInfo: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/account/info', config)
            .then(response => {
                const result = response.data;
                const account = new Account(
                    result.id,
                    result.name,
                    result.phone,
                    result.email,
                );
                commit('setAccountInfo', account);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    loadBalance: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/market/account/getBalance', config)
            .then(response => {
                const result = response.data.data;
                commit('setAccountBalance', result.totalPoints);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const state = () => ({
    accountInfo: null,
    accountBalance: 0,
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
