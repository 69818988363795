<template>
    <section class="lds-container">
        <div class="client-easter-progress">
            <div class="clnt-easter-progress__tour">
                <div class="clnt-easter-progress__tour_subtitle">
                    Текущий статус
                </div>
                <div class="clnt-easter-progress__tour_title">
                    Tour de Leads
                </div>
                <VueSlickCarousel v-bind="settingsTour"
                                  ref="tourCarousel">
                    <div class="clnt-easter-progress__tour-item"
                         v-for="(round, j) in rounds"
                         :key="`line_` + round.id"
                         :class="{'inactive': !round.isCurrent}">
                        <div class="clnt-easter-progress__tour-item_box">
                            <div class="clnt-easter-progress__tour-item_title">
                                {{ round.title }}
                            </div>
                            <div class="clnt-easter-progress__tour-item_subtitle">
                                Сроки проведения:
                            </div>
                            <div class="clnt-easter-progress__tour-item_date">
                                с {{ formatDate(round.dateFrom) }} по {{ formatDate(round.dateTo) }}
                            </div>
                        </div>
                        <div class="clnt-easter-progress__tour-item_progress-bar">
                            <div class="clnt-easter-progress__tour-item_circle-box">
                                <div class="clnt-easter-progress__tour-item_circle"
                                     :class="{'active': round.isCurrent || (rounds[j-1] && rounds[j-1].isTallyCurrent) }" />
                                <div class="clnt-easter-progress__tour-item_popover"
                                     v-if="rounds[j-1] && rounds[j-1].tallyDateFrom && rounds[j-1].tallyDateTo">
                                    <div class="clnt-easter-progress__tour-item_popover-title">
                                        Итоги и вручение наград:
                                    </div>
                                    <div class="clnt-easter-progress__tour-item_popover-date">
                                        с {{ formatDate(rounds[j-1].tallyDateFrom) }} по {{ formatDate(rounds[j-1].tallyDateTo) }}
                                    </div>
                                </div>
                            </div>
                            <progress max="100"
                                      :value="round.progressPercent" />
                        </div>
                        <div class="clnt-easter-progress__tour-item_open-title">
                            Открыто пасхалок
                        </div>
                        <div class="clnt-easter-progress__tour-item_title">
                            {{ round.countCollected }} из {{ round.countAll }}
                        </div>
                    </div>
                    <div class="clnt-easter-progress__tour-item inactive">
                        <div class="clnt-easter-progress__tour-item_box">
                            <div class="clnt-easter-progress__tour-item_title">
                                Grand Prix
                            </div>
                        </div>
                        <div class="clnt-easter-progress__tour-item_progress-bar">
                            <div class="clnt-easter-progress__tour-item_circle-box">
                                <div class="clnt-easter-progress__tour-item_circle" />
                                <div class="clnt-easter-progress__tour-item_popover">
                                    <div class="clnt-easter-progress__tour-item_popover-title">
                                        Итоги и вручение наград:
                                    </div>
                                    <div class="clnt-easter-progress__tour-item_popover-date">
                                        с 20.12.22 по 25.12.22
                                    </div>
                                </div>
                            </div>
                            <progress max="100"
                                      value="0" />
                            <div class="clnt-easter-progress__tour-item_circle-box">
                                <div class="clnt-easter-progress__tour-item_circle" />
                            </div>
                        </div>
                        <div class="clnt-easter-progress__tour-item_open-title">
                            Разыграем на Moneyfest-митапе супер-тур на 300 000 рублей среди ТОП-5 каждого этапа
                        </div>
                    </div>
                </VueSlickCarousel>
                <div class="clnt-easter-progress__tour_button">
                    <router-link to="easterEggsChallenge/rules"
                                 class="lds-btn lds-btn_fill">
                        Посмотреть правила розыгрыша
                    </router-link>
                </div>
            </div>
            <div class="lds-heading__title">
                Этапы розыгрыша
            </div>
            <div class="clnt-easter-progress__stage">
                <VueSlickCarousel
                    ref="stageCarousel"
                    v-bind="settingsStage">
                    <div class="clnt-easter-progress__stage-slide"
                         :key="`detailed_` + round.id"
                         v-for="(round, ind) in rounds">
                        <div class="clnt-easter-progress__stage-item"
                             :class="{'inactive': !round.isCurrent}">
                            <div class="clnt-easter-progress__stage-info">
                                <div class="clnt-easter-progress__stage-info_content">
                                    <div class="clnt-easter-progress__stage-info_title">
                                        {{ round.title }}
                                    </div>
                                    <div class="clnt-easter-progress__stage-info_wrapper">
                                        <div class="clnt-easter-progress__stage-info_box">
                                            <div class="clnt-easter-progress__stage-info_num">
                                                1 место
                                            </div>
                                            <div class="clnt-easter-progress__stage-info_subtitle">
                                                {{ round.firstPlaceReward }}
                                            </div>
                                        </div>
                                        <div class="clnt-easter-progress__stage-info_box">
                                            <div class="clnt-easter-progress__stage-info_num">
                                                2-10 место (сертификаты)
                                            </div>
                                            <div class="clnt-easter-progress__stage-info_subtitle">
                                                {{ round.otherReward }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clnt-easter-progress__stage-info_image-circle" />
                                <div class="clnt-easter-progress__img-block">
                                    <img
                                        class="clnt-easter-progress__stage-info_images"
                                        :src="coverImgScrOne(ind + 1)"
                                        alt="">
                                    <div v-if="ind === 0 && availableToour3Egg6"
                                         class="clnt-easter-progress__tour3egg6"
                                         @click="collect(tour3EasterEgg6)" />
                                    <div v-if="showRound3Egg8Step(ind)"
                                         @click="tour3Egg8Advance"
                                         class="clnt-easter-progress__tour-3-egg-8-step" />
                                </div>
                            </div>
                            <div class="clnt-easter-progress__stage-status">
                                <div class="clnt-easter-progress__stage-status_title">
                                    Статус пасхалок
                                </div>
                                <div class="clnt-easter-progress__stage-status_subtitle">
                                    Статус
                                </div>
                            </div>
                            <div class="clnt-easter-progress__stage-description"
                                 v-for="(egg, i) in round.eggs"
                                 :key="`egg_` + egg.id">
                                <div class="clnt-easter-progress__stage-description_box">
                                    <div class="clnt-easter-progress__stage-description_badge"
                                         :class="{'found': egg.collected, 'prompt': egg.needShow, 'not-active': !egg.available && !egg.collected}" />
                                    <div class="clnt-easter-progress__stage-description_title">
                                        <template v-if="!egg.code.includes('init')">
                                            Пасхалка {{ i + 1 }}
                                        </template>
                                        <template v-else>
                                            Начало раунда
                                        </template>
                                    </div>
                                    <div class="clnt-easter-progress__stage-description_prompt"
                                         v-if="egg.collected">
                                        Найдена
                                    </div>

                                    <div class="clnt-easter-progress__stage-description_prompt"
                                         v-if="!egg.available && !egg.collected && !round.isCurrent">
                                        Скрыто
                                    </div>

                                    <div class="clnt-easter-progress__stage-description_prompt"
                                         v-if="!egg.available && !egg.collected && round.isCurrent">
                                        Откроется {{ egg.formattedAvailableFrom }}
                                    </div>

                                    <div class="clnt-easter-progress__stage-description_prompt arrow"
                                         @click="egg.showHint = !egg.showHint"
                                         :class="{ active: egg.showHint }"
                                         v-if="egg.needShow">
                                        Подсказка
                                    </div>
                                </div>
                                <div class="clnt-easter-progress__stage-description_prompt-text"
                                     v-if="egg.showHint && egg.needShow">
                                    <template v-if="!isTour3Egg8(egg)">
                                        {{ egg.hint }}
                                    </template>
                                    <div
                                        v-else
                                        v-html="prepareTour3Egg8(egg.hint)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </section>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';
import metaMixin from '@/mixins/metaMixin';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import EasterEggCookies, {tour3Egg6RiddleSolved, tour3Egg8WindowClicked} from '@/models/EasterEggs/EasterEggCookies.js';
import {tour3egg6, tour3egg8} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'EasterEggsProgressLine',
    components: {
        VueSlickCarousel,
    },
    mixins: [
        metaMixin,
    ],
    data() {
        return {
            settingsTour: {
                autoplay: false,
                arrows: false,
                dots: false,
                slidesToShow: 4,
                infinite: false,
                swipe: false,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        dots: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: true,
                        swipe: true,
                    },
                }, {
                    breakpoint: 991,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        swipe: true,
                    },
                }, {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        swipe: true,
                    },
                }],
            },
            settingsStage: {
                autoplay: false,
                arrows: true,
                dots: false,
                slidesToShow: 3,
                infinite: false,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        dots: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                }, {
                    breakpoint: 991,
                    settings: {
                        dots: false,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                }],
            },
            showPrompt: false,
            tour3Egg8WindowClicked: false,
        };
    },
    computed: {
        ...mapState('easterEggs', [
            'rounds',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        tour3egg8() {
            return this.getAvailable(tour3egg8);
        },
        activeRoundIndex() {
            let i = 0;
            for (const round of this.rounds) {
                if (round.isCurrent) {
                    return i;
                }
                ++i;
            }
            return 0;
        },
        tour3EasterEgg6() {
            return this.getAvailable(tour3egg6);
        },
        tour3EasterEgg6ConditionsSolved() {
            return EasterEggCookies.getState(tour3Egg6RiddleSolved);
        },
        coverImgScr() {
            return require('@/assets/images/easterEggs/easter_tour_mini_cover_1-1.png');
        },
        availableToour3Egg6() {
            return this.tour3EasterEgg6ConditionsSolved && this.tour3EasterEgg6 && this.tour3EasterEgg6.needShow;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.settingsStage.initialSlide = this.activeRoundIndex;
            this.settingsTour.initialSlide = this.activeRoundIndex;
        });
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
            'showNextStepModal',
        ]),
        formatDate(date) {
            const dateObj = new Date(date);
            const options = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            };
            return dateObj.toLocaleDateString('ru', options);
        },
        coverImgSrc(i) {
            return require(`@/assets/images/easterEggs/easter_tour_mini_cover_${i}.png`);
        },
        coverImgScrOne(i) {
            if (i === 1 && this.tour3EasterEgg6ConditionsSolved && this.tour3EasterEgg6 && this.tour3EasterEgg6.needShow) {
                return this.coverImgScr;
            }
            return require(`@/assets/images/easterEggs/easter_tour_mini_cover_${i}.png`);
        },
        showRound3Egg8Step(tourIndex) {
            const windowClicked = EasterEggCookies.getState(tour3Egg8WindowClicked, false);
            const thirdTour = 2;
            return tourIndex === thirdTour
                && !this.tour3Egg8WindowClicked
                && !windowClicked
                && this.tour3egg8
                && this.tour3egg8.needShow;
        },
        tour3Egg8Advance() {
            this.tour3Egg8WindowClicked = true;
            EasterEggCookies.setState(tour3Egg8WindowClicked, true);
            this.showNextStepModal();
        },
        /** @param {EasterEgg} egg */
        isTour3Egg8(egg) {
            if (egg.code !== tour3egg8) { return; }

            const conditionFulfilled = EasterEggCookies.getState(tour3Egg8WindowClicked, false);
            return conditionFulfilled
                && this.tour3egg8
                && this.tour3egg8.needShow;
        },
        /** @param {string} text */
        prepareTour3Egg8(text) {
            const eggClass = 'clnt-easter-progress__tour-3-egg-8';
            // Заменить только второе вхождение
            let occurrence = 0;
            const markedText = text.replaceAll('пасхалка', match => {
                if (occurrence === 1) {
                    return '<span class="' + eggClass + '">' + match + '</span>';
                }
                occurrence++;
                return match;
            });
            this.$nextTick(() => {
                const eggElement = document.querySelector('.' + eggClass);
                eggElement.addEventListener('click', () => {
                    this.collect(this.tour3egg8);
                });
            });
            return markedText;
        },
    },
};
</script>
