<template>
    <div class="clnt-leaders-offers"
         v-if="offers.length && currentRound">
        <h1 class="clnt-leaders-offers__title">
            Ваши офферы
        </h1>
        <div class="clnt-leaders-offers__list">
            <LeadersOffer v-for="item in offers"
                          :key="item.id"
                          :offer="item"
                          :current-round="currentRound"
                          @update="update" />
        </div>
    </div>
</template>

<script>
import LeadersOffer from '@/components/Leaders/leadersOffer/index';

export default {
    name: 'LeadersOffers',
    components: {
        LeadersOffer,
    },
    props: {
        offers: {
            type: Array,
            required: true,
        },
        currentRound: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    methods: {
        update() {
            this.$emit('update');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
