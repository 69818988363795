const easterCookiePath = '/';
const easterCookieName = 'leads_easterEggs';
const easterCookieMaxAge = 60 * 60 * 24 * 7;

export const tour2Egg6RiddleSolved = 'tour2egg6_riddle_solved';
export const tour2Egg4statMade = 'tour2egg8_competitors_stat_made';
export const tour2Egg8birdClicked = 'tour2egg8_bird_clicked';
export const tour3Egg5ButtonClicked = 'tour3egg5_button_clicked';
export const tour3Egg6RiddleSolved = 'tour3egg6_riddle_solved';
export const tour3Egg7WindowClicked = 'tour3egg7_heart_clicked';
export const tour3Egg8WindowClicked = 'tour3egg8_window_clicked';

class EasterEggCookies {
    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    get cookiesObject() {
        const cookiesArray = document.cookie.split('; ');
        const result = {};
        for (const cookieRow of cookiesArray) {
            const keyValue = cookieRow.split('=');
            if (EasterEggCookies.isJson(keyValue[1])) {
                result[keyValue[0] || 0] = JSON.parse(keyValue[1]);
            } else {
                result[keyValue[0] || 0] = keyValue[1] || null;
            }
        }

        return result;
    }

    get easterCookies() {
        return this.cookiesObject[easterCookieName] || {};
    }

    getState(key, defaultValue = null) {
        return this.easterCookies[key] || defaultValue;
    }

    setState(key, value) {
        const current = this.easterCookies;
        current[key] = value;
        const cookieValue = JSON.stringify(current);
        let cookieString = `${easterCookieName}=${cookieValue}; `;
        cookieString += `max-age=${easterCookieMaxAge}; `;
        cookieString += `path=${easterCookiePath}; `;

        document.cookie = cookieString;
    }

    deleteState(key) {
        const current = this.easterCookies;
        delete current[key];
        const cookieValue = JSON.stringify(current);
        let cookieString = `${easterCookieName}=${cookieValue}; `;
        cookieString += `max-age=${easterCookieMaxAge}; `;
        cookieString += `path=${easterCookiePath}; `;

        document.cookie = cookieString;
    }
}

export default new EasterEggCookies();
