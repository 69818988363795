<template>
    <div class="lds-container">
        <Loader v-if="loading" />
        <template v-else>
            <BannerTasks />
            <BannerTasksMob />
            <div class="clnt-empty"
                 v-if="isEmptyTasksList">
                Задач нет
                <span v-if="tour3Egg5ShowOnce()"
                      class="clnt-tasks__tour-3-egg-5">
                    <SvgIcon
                        v-if="tour3Egg5ConditionsFulfilled"
                        @click.native="collect(tour3Egg5)"
                        name="present" />
                </span>
            </div>
            <div class="clnt-tasks"
                 v-else>
                <template v-if="visibleBlock(tasksListNew)">
                    <h2 class="clnt-tasks__title">
                        Новые задания
                        <span v-if="tour3Egg5ShowOnce()"
                              class="clnt-tasks__tour-3-egg-5">
                            <SvgIcon
                                v-if="tour3Egg5ConditionsFulfilled"
                                @click.native="collect(tour3Egg5)"
                                name="present" />
                        </span>
                    </h2>
                    <TasksGroup v-for="(item, id) in tasksListNew"
                                :key="`new-${id}`"
                                :tasks="item"
                                :slider-id="`new-${id}`"
                                :title="item[0].offerTitle"
                                :offer-id="id.toString()" />
                </template>

                <template v-if="visibleBlock(tasksListCurrent)">
                    <h2 class="clnt-tasks__title">
                        Текущие задания
                        <span v-if="tour3Egg5ShowOnce()"
                              class="clnt-tasks__tour-3-egg-5">
                            <SvgIcon
                                v-if="tour3Egg5ConditionsFulfilled"
                                @click.native="collect(tour3Egg5)"
                                name="present" />
                        </span>
                    </h2>
                    <TasksGroup v-for="(item, id) in tasksListCurrent"
                                :key="`current-${id}`"
                                :tasks="item"
                                :slider-id="`current-${id}`"
                                :title="item[0].offerTitle"
                                :offer-id="id.toString()" />
                </template>

                <template v-if="visibleBlock(tasksListCompleted)">
                    <h2 class="clnt-tasks__title">
                        Завершенные задания
                        <span v-if="tour3Egg5ShowOnce()"
                              class="clnt-tasks__tour-3-egg-5">
                            <SvgIcon
                                v-if="tour3Egg5ConditionsFulfilled"
                                @click.native="collect(tour3Egg5)"
                                name="present" />
                        </span>
                    </h2>
                    <TasksGroup v-for="(item, id) in tasksListCompleted"
                                :key="`completed-${id}`"
                                :tasks="item"
                                :slider-id="`completed-${id}`"
                                :title="item[0].offerTitle"
                                :offer-id="id.toString()" />
                </template>
            </div>
        </template>
        <RiddleModal />
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import Loader from '@/components/common/Loader';
import TasksGroup from '@/components/tasks/TasksGroup';
import BannerTasks from '@/components/tasks/BannerTasks';
import {tour2egg6, tour3egg5} from '@/models/EasterEggs/EasterEgg';
import RiddleModal from '@/components/easterEggs/easterEggsRiddle/tour2egg6';
import SvgIcon from '@/components/common/SvgIcon';
import EasterEggCookies, {tour3Egg5ButtonClicked} from '@/models/EasterEggs/EasterEggCookies';
import BannerTasksMob from '@/components/tasks/BannerTasksMob';

let tour3Egg5Showed = 0;

export default {
    name: 'TasksList',
    components: {
        BannerTasksMob,
        BannerTasks,
        Loader,
        TasksGroup,
        RiddleModal,
        SvgIcon,
    },
    computed: {
        ...mapState('tasks', [
            'loading',
        ]),
        ...mapGetters('tasks', [
            'getSortStatuses',
            'isEmptyTasksList',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        tasksListNew() {
            return this.groupOffer(this.getSortStatuses(['new', 'teaser']));
        },
        tasksListCurrent() {
            return this.groupOffer(this.getSortStatuses(['run', 'run-checking', 'done', 'checking']));
        },
        tasksListCompleted() {
            return this.groupOffer(this.getSortStatuses(['not-done', 'completed']));
        },
        tour3Egg5() {
            return this.getAvailable(tour3egg5);
        },
        tour3Egg5ConditionsFulfilled() {
            const buttonClicked = EasterEggCookies.getState(tour3Egg5ButtonClicked, false);
            return buttonClicked
                && this.tour3Egg5
                && this.tour3Egg5.needShow;
        },
    },
    mounted() {
        this.check(tour2egg6);
        this.check(tour3egg5);
    },
    methods: {
        groupOffer(list) {
            const result = list.reduce((r, a) => {
                const name = a.offerId || 0;
                r[name] = r[name] || [];
                r[name].push(a);
                return r;
            }, {});
            return result;
        },
        visibleBlock(obj) {
            return Object.keys(obj).length !== 0;
        },
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        tour3Egg5ShowOnce() {
            // С первого раза не возвращает в шаблоне нужный результат
            if (tour3Egg5Showed <= 2) {
                tour3Egg5Showed++;
                return true;
            }
            return false;
        },
    },
};
</script>
