<template>
    <div class="lm-contact"
         :class="{'lm-contact_active': active}">
        <div class="lm-contact__body"
             @click="selectCurrent">
            <span class="lm-contact__marker" />
            <div class="lm-contact__category">
                {{ contact.comment }}
            </div>
            <div class="lm-contact__name">
                {{ contact.fullName }}
            </div>
            <div class="lm-contact__title">
                {{ contact.address.string }}
            </div>
            <div class="lm-contact__info">
                <div class="lm-contact__phone">
                    {{ getContactPhone }}
                </div>
                <div class="lm-contact__email">
                    {{ contact.email }}
                </div>
            </div>
        </div>
        <button class="lm-contact__delete"
                @click.prevent="deleteContactCurrent">
            <SvgIcon name="trash" />
        </button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AccountContact from '@/models/Market/AccountContact';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'Contact',
    components: {
        SvgIcon,
    },
    props: {
        contact: AccountContact,
        active: Boolean,
    },
    computed: {
        getContactPhone() {
            return this.contact.phones.join(', ');
        },
    },
    methods: {
        ...mapActions('market', [
            'deleteContact',
        ]),
        selectCurrent() {
            this.$emit('select');
        },
        deleteContactCurrent() {
            this.deleteContact(this.contact.id);
            this.$emit('delete');
        },
    },
};
</script>
