<template>
    <div class="lds-container"
         v-if="!loading">
        <template v-if="!offerNotFound">
            <div class="clnt-offer">
                <header class="clnt-offer__header">
                    <div class="clnt-offer__header-info">
                        <div class="clnt-offer__info">
                            <h1 class="clnt-offer__title">
                                ID: {{ offer.id }}
                            </h1>
                            <div class="clnt-offer__status"
                                 :class="`clnt-offer__status_${offer.status.class}`">
                                {{ offer.status.title }}
                            </div>
                        </div>
                        <div class="clnt-offer__name">
                            {{ offer.name }}
                        </div>
                    </div>
                    <div class="clnt-offer__header-btns">
                        <router-link class="clnt-offer__header-btn lds-btn lds-btn_fill lds-btn_size-sm -blue"
                                     :to="{ name: 'StatisticsOfWebmasters'}">
                            Статистика по вебмастерам
                        </router-link>
                        <router-link class="clnt-offer__header-btn lds-btn lds-btn_fill lds-btn_size-sm -blue"
                                     :to="{ name: 'Dynamic'}">
                            Статистика в динамике
                        </router-link>
                    </div>
                </header>
                <div class="clnt-offer__categories">
                    <div class="clnt-offer-category"
                         v-for="(item, index) in offer.categories"
                         :key="index">
                        <div class="clnt-offer-category__top">
                            <div class="clnt-offer-category__top-info">
                                <div class="clnt-offer-category__top-title">
                                    {{ item.name }}
                                </div>
                                <div class="clnt-offer-category__top-rating">
                                    <div class="clnt-offer-category__top-rating-text">
                                        {{ item.rating }}
                                    </div>
                                    <League :league="item.league.value"
                                            class="lds-popover_right-center" />
                                </div>
                            </div>
                            <div class="lds-popover lds-popover_size-sm"
                                 :class="{'lds-popover_active': visiblePopover(offer.key, item.id)}">
                                <button class="clnt-offer-category__top-btn lds-btn lds-btn_fill lds-btn_size-sm -blue"
                                        @click="improveRating(offer.key, offer.subject, item.id)"
                                        :disabled="disabledBtn(offer.key, item.id)">
                                    Оставить заявку на разбор
                                </button>
                                <div class="lds-popover__info">
                                    Спасибо. Заявка принята.
                                    В ближайшее время с Вами свяжется наш менеджер.
                                </div>
                            </div>
                        </div>
                        <div class="clnt-offer-category__grades"
                             v-if="item.grades.length">
                            <div class="clnt-offer-category__grade"
                                 v-for="(itemGrade, indexGrade) in item.grades"
                                 :key="indexGrade">
                                <div class="clnt-offer-category__grade-info">
                                    <div class="clnt-offer-category__grade-title">
                                        {{ itemGrade.title }}
                                    </div>
                                    <div class="clnt-offer-category__grade-text">
                                        {{ itemGrade.description }}
                                    </div>
                                </div>
                                <div class="clnt-offer-category__grade-action">
                                    <div class="clnt-offer-rating clnt-offer-category__grade-rating">
                                        <star-rate :value="parseFloat(itemGrade.value)"
                                                   :class="rateStyle(itemGrade.value)"
                                                   type="star1"
                                                   :disabled="true"
                                                   :star-half="false">
                                            <span slot="rateChar"
                                                  slot-scope="props"
                                                  :class="props.computeClass" />
                                        </star-rate>
                                    </div>
                                    <button
                                        class="clnt-offer-category__grade-btn lds-btn lds-btn_border lds-btn_size-sm -blue"
                                        @click="openModalGrade(itemGrade, item.id)">
                                        Улучшить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TasksGroup v-if="!maintenance && tasks.length"
                            :tasks="tasks"
                            :slider-id="`tasks-${tasks[0].offerId}`"
                            :offer-id="tasks[0].offerId.toString()" />
            </div>
            <v-easy-dialog v-model="modalGrade">
                <div>
                    <div class="clnt-offer-dialog">
                        <button class="clnt-offer-dialog__close"
                                @click="modalGrade = false">
                            <SvgIcon name="close" />
                        </button>
                        <div class="clnt-offer-dialog__title">
                            {{ modalGradeData.title }}
                        </div>
                        <div class="clnt-offer-rating clnt-offer-rating_lg clnt-offer-dialog__rating">
                            <star-rate :value="parseFloat(modalGradeData.value)"
                                       :class="rateStyle(modalGradeData.value)"
                                       type="star1"
                                       :disabled="true"
                                       :star-half="false">
                                <span slot="rateChar"
                                      slot-scope="props"
                                      :class="props.computeClass" />
                            </star-rate>
                        </div>
                        <div class="clnt-offer-dialog__description"
                             v-html="descriptionFull" />
                        <div class="clnt-offer-dialog__text"
                             v-html="modalGradeData.descriptionAction" />
                        <div class="clnt-offer-dialog__action">
                            <div class="lds-popover lds-popover_size-sm"
                                 :class="{'lds-popover_active': visiblePopover(modalGradeData.key, modalGradeData.categoryId)}">
                                <button class="lds-btn lds-btn_fill lds-btn_size-sm -blue"
                                        @click="improveRating(modalGradeData.key, modalGradeData.subject, modalGradeData.categoryId)"
                                        :disabled="disabledBtn(modalGradeData.key, modalGradeData.categoryId)">
                                    Оставить заявку
                                </button>
                                <div class="lds-popover__info">
                                    Спасибо. Заявка принята.
                                    В ближайшее время с Вами свяжется наш менеджер.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-easy-dialog>
        </template>
        <NotFound v-else />
    </div>
</template>

<script>
import {
    mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import StarRate from 'vue-cute-rate';
import VEasyDialog from 'v-easy-dialog';
import metaMixin from '@/mixins/metaMixin';
import League from '@/components/common/League';
import TasksGroup from '@/components/tasks/TasksGroup';
import NotFound from '@/components/common/NotFound.vue';
import SvgIcon from '@/components/common/SvgIcon';
import {tour3egg2} from '@/models/EasterEggs/EasterEgg.js';

export default {
    name: 'Offer',
    components: {
        TasksGroup,
        League,
        NotFound,
        SvgIcon,
        StarRate,
        VEasyDialog,
    },
    mixins: [
        metaMixin,
    ],
    data() {
        return {
            modalGrade: false,
            modalGradeData: {},
            eggClass: 'clnt-offer-dialog__tour3-egg2',
            originalDescriptionFull: '',
            descriptionFull: '',
        };
    },
    computed: {
        ...mapState('offers', [
            'offer',
            'offerNotFound',
            'loading',
            'sentRatingRequest',
        ]),
        ...mapGetters('tasks', [
            'getTasksOffer',
        ]),
        ...mapState('common', [
            'maintenance',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        easterEgg() {
            return this.getAvailable(tour3egg2);
        },
        offerId() {
            return this.$route.params.offerID;
        },
        tasks() {
            return this.getTasksOffer(this.offerId);
        },
    },
    watch: {
        offer: function () {
            this.updateMeta();
        },
    },
    created() {
        this.loadOfferId(this.offerId);
        this.updateMeta();
    },
    methods: {
        ...mapActions('offers', [
            'loadOfferId',
            'sendRatingRequest',
        ]),
        ...mapMutations('common', [
            'replaceBreadcrumbs',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        collectEasterEgg() {
            this.collect(this.easterEgg);
            this.descriptionFull = this.originalDescriptionFull;
        },
        updateMeta() {
            const data = {
                id: ':offerID',
                name: this.offerId,
            };
            this.replaceBreadcrumbs(data);
            this.setTitlePage('Оффер ' + this.offerId);
        },
        improveRating(
            type, subject, id,
        ) {
            const data = {
                type: type,
                subject: subject,
                offerId: this.offerId,
                categoryId: id,
            };
            this.sendRatingRequest(data);
        },
        disabledBtn(key, categoryId) {
            const current = this.sentRatingRequest[this.offerId];
            return current && current[categoryId] && current[categoryId][key];
        },
        visiblePopover(key, categoryId) {
            const current = this.sentRatingRequest[this.offerId];
            const currentCategory = current ? current[categoryId] : null;
            if (currentCategory && currentCategory[key] && currentCategory[key].visible) {
                return current[categoryId][key].visible;
            }
            return false;
        },
        rateStyle(value) {
            let className;
            switch (value) {
                case '1':
                    className = 'lowest';
                    break;
                case '2':
                    className = 'low';
                    break;
                case '3':
                    className = 'medium';
                    break;
                case '4':
                    className = 'high';
                    break;
                case '5':
                    className = 'highest';
                    break;
                default: {
                    className = '';
                    break;
                }
            }
            return '-rate-' + className;
        },
        openModalGrade(grade, id) {
            const categoryId = {
                categoryId: id,
            };

            this.modalGradeData = Object.assign(grade, categoryId);
            this.modalGrade = true;

            this.descriptionFull = this.modalGradeData.descriptionFull;

            if (this.easterEgg) {
                this.setupTour3Egg2Riddle();
            }
        },
        setupTour3Egg2Riddle() {
            // replace text with clickable one
            this.originalDescriptionFull = this.modalGradeData.descriptionFull;
            this.descriptionFull = this.originalDescriptionFull.replace('персонально', '<span class="' + this.eggClass + '">персонально</span>');
            // add event listener
            setTimeout(() => {
                const eggElement = document.querySelector('.' + this.eggClass);
                if (!this.easter) {
                    eggElement.addEventListener('click', () => {
                        this.collectEasterEgg();
                    });
                }
            });
        },
    },
};
</script>
