import axios from 'axios';

const mutations = {
    setStatisticLoading: (state, data) => {
        state.statisticLoading = data;
    },
    setStatisticExportLoading: (state, data) => {
        state.statisticExportLoading = data;
    },
    setTableOfOffersLoading: (state, data) => {
        state.tableOfOffersLoading = data;
    },
    setTableOfWebmastersLoaded: (state, data) => {
        state.tableOfWebmastersLoaded = data;
    },
    setCategoriesStatistic: (state, data) => {
        state.listCategoriesStatistic = data;
    },
    setGeoStatistic: (state, data) => {
        state.listGeoStatistic = data;
    },
    setStatisticDynamicByOffer: (state, data) => {
        state.statisticDynamicByOffer = data;
    },
    setOffers: (state, data) => {
        state.offers = data;
    },
    setLoadingStatisticDynamic: (state, data) => {
        state.loadingStatisticDynamic = data;
    },
    setOwnStatisticsInGrid: (state, data) => {
        state.ownStatistics = data;
    },
    setStatisticsInGrid: (state, data) => {
        state.statisticsInGrid = data;
    },
    setStatisticsWebInGrid: (state, data) => {
        state.statisticsWebInGrid = data;
    },
    setStatisticsWebInGridSort: (state, data) => {
        state.statisticsWebInGridSort = data;
    },
};

const actions = {
    getCategoriesStatistic: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/stat/dictionaries/offersCategories', config)
            .then(response => {
                commit('setCategoriesStatistic', response.data.data);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getCategoriesStatisticOffer: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/stat/dictionaries/offersCategories', config)
            .then(response => {
                const dataCategoriesStatisticList = response.data.data.map(value => ({
                    ...value,
                    label: value.id,
                }));
                dataCategoriesStatisticList.unshift({
                    count: '',
                    id: '999',
                    label: 'All',
                    name: 'Все',
                    status: 'active',
                });
                commit('setCategoriesStatistic', dataCategoriesStatisticList);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getGeoStatisticOffer: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/stat/dictionaries/offersGeo', config)
            .then(response => {
                const dataStatisticList = response.data.map(value => ({
                    ...value,
                    label: value.iso_alpha3,
                }));
                commit('setGeoStatistic', dataStatisticList);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getGeoStatistic: ({ commit, rootState }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get('/rest/stat/dictionaries/offersGeo', config)
            .then(response => {
                commit('setGeoStatistic', response.data);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getStatistic: ({ commit, rootState }, data) => {
        commit('setStatisticExportLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/xml',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
            responseType: 'blob',
        };
        let params = `mode=${data.mode}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;
        if (data.geo) {
            params = params + `&geo=${data.geo}`;
        }
        if (data.category) {
            params = params + `&category=${data.category}`;
        }
        return axios
            .get(`/rest/stat/byOffers/getXlsx?${params}`, config)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const filename = response.headers['content-disposition'].match(/filename="([^"]*)/)[1];
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setStatisticExportLoading', false);
            });
    },
    getStatisticDynamicByOffer: ({ commit, rootState }, data) => {
        commit('setLoadingStatisticDynamic', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
            params: {
                dateFrom: data.dateFrom,
                dateTo: data.dateTo,
                offerId: data.offerId,
            },
        };
        return axios
            .get('/rest/stat/dynamicByOffer/getData', config)
            .then(response => {
                commit('setStatisticDynamicByOffer', response.data.data);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setLoadingStatisticDynamic', false);
            });
    },
    getOffers: ({ commit, rootState }, data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        const query = data ? `?q=${data}` : '';
        return axios
            .get(`/rest/stat/dictionaries/offerSearch${query}`, config)
            .then(response => {
                commit('setOffers', response.data);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
    getStatisticsInGrid: ({ commit, rootState }, data) => {
        commit('setStatisticLoading', true);
        commit('setTableOfOffersLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(data.url, config)
            .then(response => {
                const result = response.data.data;
                commit('setStatisticsInGrid', result);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
                commit('setTableOfOffersLoading', false);
            })
            .finally(() => {
                commit('setStatisticLoading', false);
            });
    },
    getWebmasterStatisticsInGrid({ commit, rootState }, data) {
        commit('setStatisticLoading', true);
        commit('setTableOfWebmastersLoaded', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/stat/byAffiliates/getData?dateFrom=${data.dateFrom}&dateTo=${data.dateTo}&offerId=${data.offerId}`, config)
            .then(response => {
                const result = response.data.data;
                commit('setStatisticsWebInGrid', result);
                commit('setStatisticsWebInGridSort', result);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
                commit('setTableOfWebmastersLoaded', false);
            })
            .finally(() => {
                commit('setStatisticLoading', false);
            });
    },
    getExportStatisticOfWebmaster: ({ commit, rootState }, data) => {
        commit('setStatisticExportLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/xml',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
            responseType: 'blob',
        };
        return axios
            .get(`/rest/stat/byAffiliates/getXlsx?offerId=${data.offerId}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`, config)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const filename = response.headers['content-disposition'].match(/filename="([^"]*)/)[1];
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                commit('setStatisticExportLoading', false);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            });
    },
};

const state = () => ({
    statisticLoading: false,
    statisticExportLoading: false,
    tableOfOffersLoading: false,
    tableOfWebmastersLoaded: false,
    listCategoriesStatistic: null,
    listGeoStatistic: null,
    statisticDynamicByOffer: null,
    offers: [],
    loadingStatisticDynamic: false,
    ownStatistics: [],
    statisticsInGrid: [],
    statisticsWebInGrid: [],
    statisticsWebInGridSort: [],
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
