<template>
    <div class="clnt-auth">
        <form class="clnt-auth__form"
              @submit.prevent="restorePassSubmit()">
            <div class="lds-control-item">
                <div class="lds-control"
                     :class="{'lds-control_error':
                         $v.user.email.$error ||
                         restorePassErrorMessage ||
                         (restorePassValidErrors && restorePassValidErrors.email) }">
                    <label class="lds-control__label"
                           for="email">
                        Введите свой email для сброса пароля
                    </label>
                    <input type="email"
                           autocomplete="on"
                           class="lds-input"
                           id="email"
                           placeholder="email"
                           v-model="user.email"
                           @change="emailChange()">
                    <div class="lds-control__message"
                         v-if="$v.user.email.$error && $v.user.email.$model == ''">
                        Поле обязательно для заполнения
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.user.email.$error && $v.user.email.$model != ''">
                        Некорректный ввод. Заполните поле по шаблону example@domain.com
                    </div>
                    <div class="lds-control__message"
                         v-if="restorePassErrorMessage">
                        {{ restorePassErrorMessage }}
                    </div>
                    <div class="lds-control__message"
                         v-if="restorePassValidErrors && restorePassValidErrors.email">
                        {{ restorePassValidErrors.email }}
                    </div>
                </div>
            </div>
            <button type="submit"
                    class="lds-btn lds-btn_full lds-btn_fill -blue"
                    :disabled="restorePassSent">
                Сбросить пароль
            </button>
            <div class="clnt-auth__info clnt-auth__info_lg"
                 v-if="restorePassSent">
                Письмо для восстановления пароля отправлено на Вашу почту.
            </div>
        </form>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { required, email } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
    name: 'RestorePasswordForm',
    data() {
        return {
            user: {
                email: '',
            },
            sentUser: null,
        };
    },
    validations: {
        user: {
            email: {
                required,
                email,
            },
        },
    },
    computed: {
        ...mapState('authorization', [
            'restorePassErrorMessage',
            'restorePassValidErrors',
            'restorePassSent',
        ]),
    },
    methods: {
        ...mapActions('authorization', [
            'restorePass',
        ]),
        ...mapMutations('authorization', {
            setRestorePassSent: 'SET_RESTORE_PASS_SENT',
        }),
        async restorePassSubmit() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                const data = {
                    'email': this.user.email,
                };

                if (this.$yaCaptcha) {
                    data.captcha = await this.$yaCaptcha.execute();
                }

                this.restorePass(data);
                this.sentUser = data;
            }
        },
        emailChange() {
            this.$v.user.email.$touch();

            if (!this.$v.user.email.$error && this.sentUser && this.sentUser.email !== this.user.email) {
                this.setRestorePassSent(false);
            }
        },
    },
};
</script>
