<template>
    <section class="clnt-traffics">
        <div class="lds-container">
            <template v-if="trafficContracts.length">
                <template v-if="listActive.length">
                    <h2 class="clnt-traffics__title">
                        Текущие
                    </h2>
                    <div class="clnt-traffics__list">
                        <TrafficsContractsCard v-for="item in listActive"
                                               :key="item.id"
                                               :info="item" />
                    </div>
                </template>
                <template v-if="listCompleted.length">
                    <h2 class="clnt-traffics__title">
                        Завершенные
                    </h2>
                    <div class="clnt-traffics__list">
                        <TrafficsContractsCard v-for="item in listCompleted"
                                               :key="item.id"
                                               :info="item" />
                    </div>
                </template>
            </template>
            <p v-else
               class="clnt-empty">
                Нет программ лояльности
            </p>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TrafficsContractsCard from '@/components/trafficContracts/TrafficContractsCard/index';
import { statusActive, statusCompleted } from '@/models/TrafficContracts/TrafficContracts';

export default {
    name: 'TrafficContracts',
    components: {
        TrafficsContractsCard,
    },
    computed: {
        ...mapState('tasks', [
            'trafficContracts',
        ]),
        ...mapGetters('tasks', [
            'getTrafficContractsStatus',
        ]),
        listActive() {
            return this.getTrafficContractsStatus(statusActive);
        },
        listCompleted() {
            return this.getTrafficContractsStatus(statusCompleted);
        },
    },
    created() {
        this.getTrafficContracts();
    },
    methods: {
        ...mapActions('tasks', [
            'getTrafficContracts',
        ]),
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
