export default {
    methods: {
        numberFormatCoins() {
            const money = new Intl.NumberFormat('ru-RU', {
                style: 'decimal',
                minimumFractionDigits: 0,
            });

            return money;
        },
        formatCoins(value) {
            const money = this.numberFormatCoins();
            const amount = String(parseInt(value).toFixed(0));

            return money.format(amount);
        },
        formatCoinsShort(value) {
            const money = this.numberFormatCoins();
            const amount = String(parseInt(value).toFixed(0));
            return amount.length > 3
                ? money.format(amount.slice(0, -3)) + ' к'
                : money.format(amount);
        },
    },
};
