import FormattingCoins from '@/mixins/formattingCoins';

export default class Product {
    constructor(
        id,
        category,
        name,
        alias,
        description,
        shortDescription,
        status,
        mainImage,
        salePrice,
        oldSalePrice,
        created,
        modified,
    ) {
        this.id = id;
        this.category = category;
        this.name = name;
        this.alias = alias;
        this.description = description;
        this.shortDescription = shortDescription;
        this.status = status;
        this.mainImage = mainImage;
        this.salePrice = salePrice;
        this.oldSalePrice = oldSalePrice;
        this.created = created;
        this.modified = modified;
        this.moneyFormatter = new Intl.NumberFormat('ru-RU', {
            style: 'decimal',
            minimumFractionDigits: 0,
        });
    }

    get price() {
        return parseFloat(this.salePrice.amount);
    }

    get formattedPrice() {
        return FormattingCoins.methods.formatCoins(this.price);
    }

    get formattedOldPrice() {
        const amount = parseFloat(this.oldSalePrice.amount);
        return FormattingCoins.methods.formatCoins(amount);
    }

    get hasOldPrice() {
        return this.oldSalePrice !== null;
    }

    get formattedShortPrice() {
        return FormattingCoins.methods.formatCoinsShort(this.price);
    }

    get imageSrc() {
        return `https://market.s3lds.ru/${this.mainImage.path}`;
    }

    get isActive() {
        return this.status === 'active';
    }

    inCategory(id) {
        return this.category && (Number(id) === Number(this.category.id));
    }

    canBuyWith(balance) {
        return this.price <= parseFloat(balance);
    }

    isPriceBetween(priceFrom, priceTo) {
        return priceFrom < this.price && this.price <= priceTo;
    }

    isGreaterThan(priceFrom) {
        return priceFrom < this.price;
    }
}
