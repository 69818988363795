export default class Donation {
    constructor(
        id,
        amount,
        created,
    ) {
        this.id = id;
        this.amount = amount;
        this.created = created;
    }
}
