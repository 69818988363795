<template>
    <v-easy-dialog v-model="showModal">
        <div>
            <div class="clnt-easter-dialog">
                <button class="clnt-easter-dialog__close"
                        @click="closeModal">
                    <SvgIcon name="close" />
                </button>
                <div class="clnt-easter-dialog__title -top">
                    Отгадайте ребус!
                </div>

                <div class="clnt-easter-dialog__cover">
                    <img class="clnt-easter-dialog__imgTour3egg6"
                         src="@/assets/images/easterEggs/rebus3-6.jpg"
                         alt="Ребус">
                </div>

                <div class="clnt-easter-dialog__subtitle">
                    <input class="lds-input"
                           v-model="solution"
                           placeholder="Решите ребус и получите подсказку к следующей &quot;пасхалке&quot;.">
                </div>
                <div class="clnt-easter-dialog__action">
                    <div class="lds-popover lds-popover_size-sm">
                        <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                :class="{shake: isWrong}"
                                @click="solve">
                            <span v-if="isWrong">Неправильно</span>
                            <span v-else>Решить</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </v-easy-dialog>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import VEasyDialog from 'v-easy-dialog';
import EasterEggCookies, {tour3Egg6RiddleSolved} from '@/models/EasterEggs/EasterEggCookies';
import SvgIcon from '@/components/common/SvgIcon';

const STATUS = {
    READY: 'READY',
    WRONG: 'WRONG',
    RIGHT: 'RIGHT',
};

export default {
    name: 'RiddleModalTour3',
    components: {
        VEasyDialog,
        SvgIcon,
    },
    data() {
        return {
            status: STATUS.READY,
            solution: '',
        };
    },
    computed: {
        showModal: {
            get() {
                return this.tour3egg6RiddleModal.show;
            },
            set(e) {
                if (!e) {
                    this.setTour3egg6RiddleModal({show: false});
                }
            },
        },
        isWrong() {
            return this.status === STATUS.WRONG;
        },
        ...mapState('easterEggs', ['tour3egg6RiddleModal']),
    },
    methods: {
        solve() {
            const cleanInput = this.solution.trim().toLowerCase();
            const solved = (cleanInput === 'блохэй') || (cleanInput === 'blokhay');
            if (solved) {
                this.status = STATUS.RIGHT;
                EasterEggCookies.setState(tour3Egg6RiddleSolved, true);
                this.setNextStepModal({show: true});
                this.setTour3egg6RiddleModal({show: false});
                this.setTour3Egg6RiddleSolved({show: false});
            } else {
                this.status = STATUS.WRONG;
                EasterEggCookies.setState(tour3Egg6RiddleSolved, false);
                this.setTour3egg6RiddleModal({show: true});
                setTimeout(() => {
                    this.status = STATUS.RIGHT;
                }, 1000);
            }
        },
        closeModal() {
            this.setTour3egg6RiddleModal({show: false});
        },
        ...mapMutations('easterEggs', [
            'setTour3egg6RiddleModal',
            'setTour3Egg6RiddleSolved',
            'setNextStepModal',
        ]),
    },
};
</script>

<style scoped>
.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
