const datePickerLang = Object.freeze({
    formatLocale: {
        monthsShort: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        firstDayOfWeek: 1,
    },
    monthBeforeYear: false,
});

export default datePickerLang;
