<template>
    <section class="clnt-leaders-wrap">
        <LeadersOffers class="clnt-leaders-wrap__offers"
                       :offers="offers"
                       :current-round="currentRound"
                       @update="updateCurrentStat" />
        <LeadersStat class="clnt-leaders-wrap__stat"
                     :offers="offers"
                     :current-round="currentRound"
                     :award-title="awardTitle" />
        <LeadersWinner class="clnt-leaders-wrap__winner"
                       :info="winners" />
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { typesRound } from '@/services/enums/leaders';
import LeadersOffers from '@/components/Leaders/leadersOffers/index';
import LeadersStat from '@/components/Leaders/leadersStat/index';
import LeadersWinner from '@/components/Leaders/leadersWinner/index';

export default {
    name: 'LeadersWrap',
    components: {
        LeadersOffers,
        LeadersStat,
        LeadersWinner,
    },
    props: {
        offers: {
            type: Array,
            required: true,
        },
        currentRound: {
            type: Object,
            default() {
                return null;
            },
        },
        winners: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    computed: {
        ...mapState('leaders', [
            'typeRound',
        ]),
        awardTitle() {
            let text;

            switch (this.typeRound) {
                case typesRound.COMMON:
                    text = 'ТОП-5 лидеров роста получат';
                    break;
                case typesRound.GOLD:
                    text = 'Победитель получит';
                    break;
                default:
                    text = '';
                    break;
            }
            return text;
        },
    },
    methods: {
        updateCurrentStat() {
            this.$emit('update');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
