import moment from 'moment';

const statusNew = '1';
const statusRun = '2';
const statusDone = '3';
const statusNotDone = '4';
const statusCompleted = '5';
const statusTeaser = '6';
const statusChecking = '7';

const complicacyEasy = '1';
const complicacyMiddle = '2';
const complicacyHard = '3';

const TITLE = {
    [statusNew]: 'Новое задание',
    [statusRun]: 'Активно до ',
    [statusDone]: 'Выполнено',
    [statusNotDone]: 'Завершено',
    [statusCompleted]: 'Завершено',
    [statusTeaser]: '',
    [statusChecking]: 'На проверке',
};

const NAME = {
    [statusNew]: 'new',
    [statusRun]: 'run',
    [statusDone]: 'done',
    [statusNotDone]: 'not-done',
    [statusCompleted]: 'completed',
    [statusTeaser]: 'teaser',
    [statusChecking]: 'checking',
};

const COMPLICACY_CLASS = {
    [complicacyEasy]: 'easy',
    [complicacyMiddle]: 'middle',
    [complicacyHard]: 'hard',
};

export default class Task {
    /**
     *
     * @param id
     * @param name
     * @param description
     * @param status
     * @param statusCode
     * @param amount
     * @param offerId
     * @param offerTitle
     * @param isCheckingRequired
     */

    constructor(
        id, name, description, status, statusCode, amount, dueDate, offerId, offerTitle, complicacy, complicacyCode, isCheckingRequired,
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.status = status;
        this.statusCode = statusCode;
        this.amount = amount;
        this.dueDate = dueDate;
        this.offerId = offerId;
        this.offerTitle = offerTitle;
        this.complicacy = complicacy;
        this.complicacyCode = complicacyCode;
        this.isCheckingRequired = isCheckingRequired;
    }

    get statusTitle() {
        return TITLE[this.statusCode] || null;
    }

    get statusName() {
        return NAME[this.statusCode] || null;
    }

    get dueDateFormatting() {
        return moment(this.dueDate).format('DD.MM.YYYY');
    }

    get complicacyClass() {
        return COMPLICACY_CLASS[this.complicacyCode] || null;
    }
}
