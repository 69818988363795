<template>
    <div class="clnt-tasks-gallery"
         v-if="tasks.length">
        <div class="clnt-tasks-gallery__slider">
            <swiper ref="mySwiper"
                    :options="swiperOptions">
                <swiper-slide
                    v-for="item in tasks"
                    :key="item.id">
                    <Task :task="item" />
                </swiper-slide>
            </swiper>
            <div class="swiper-scrollbar"
                 :class="`swiper-scrollbar-${sliderId}`" />
        </div>
    </div>
</template>

<script>
import SwiperCore, { Scrollbar } from 'swiper';
import Task from '@/components/tasks/Task';
// eslint-disable-next-line import/extensions
import '@/assets/scss/vendors/swiper.scss';

SwiperCore.use([Scrollbar]);

export default {
    name: 'TasksGallery',
    components: {
        Task,
    },
    props: {
        tasks: {
            type: Array,
            required: true,
        },
        sliderId: {
            type: String,
            default: '0',
        },
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 24,
                watchSlidesProgress: true,
                breakpoints: {
                    768: {
                        spaceBetween: 16,
                    },
                },
                scrollbar: {
                    el: `.swiper-scrollbar-${this.sliderId}`,
                    draggable: true,
                },
            },
        };
    },
};
</script>
