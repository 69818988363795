<template>
    <div v-if="!code">
        <div class="clnt-connect__text"
             v-if="accountInfo">
            Подключить Бот к&nbsp;учетной записи
            {{ accountInfo.id }}, {{ accountInfo.name }}?
        </div>
        <button class="clnt-connect__btn lds-btn lds-btn_fill -blue"
                @click="$emit('getCode')">
            Подключить
        </button>
    </div>
</template>

<script>

export default {
    name: 'OmniCodeConnectionButton',
    props: {
        code: {
            type: String,
            default: '',
        },
        accountInfo: {
            type: Object / null,
            required: true,
        },
    },

};
</script>
