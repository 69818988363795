<template>
    <div class="clnt-modal-goal">
        <button class="clnt-modal-goal__close"
                @click="close()">
            <SvgIcon name="close" />
        </button>
        <div class="clnt-modal-goal__wrapper">
            <div class="clnt-modal-goal__image-target">
                <img v-if="info.coverImagePath"
                     class="clnt-modal-goal__image"
                     :src="targetImagePath"
                     :alt="info.title">
                <img v-else
                     class="clnt-modal-goal__image"
                     src="@/assets/images/charity/default.png"
                     alt="">
                <div class="clnt-modal-goal__tour3egg7"
                     v-if="tour3EasterEgg7Step"
                     @click="collectTour3Egg7" />
            </div>
            <div class="clnt-modal-goal__info">
                <h3 class="clnt-modal-goal__title">
                    {{ info.title }}
                </h3>
                <div class="clnt-modal-goal__amount-block">
                    <div class="clnt-modal-goal__progress-amount">
                        {{ info.progressFormatted }}
                    </div>
                    <div class="clnt-modal-goal__amount">
                        из {{ info.amountFormatted  }}
                    </div>
                </div>
                <div class="clnt-modal-goal__progress">
                    <progress :max="info.amount"
                              :value="info.progress" />
                </div>
                <div class="clnt-modal-goal__description"
                     v-html="info.description" />
                <form v-if="info.statusCode === 'progress'"
                      @submit.prevent="sendDonation()"
                      class="lds-control clnt-modal-goal__donation"
                      :class="{'lds-control_error': $v.amount.$error}">
                    <label :for="`amount-target-${info.id}`"
                           class="clnt-modal-goal__label">
                        Размер пожертвования
                    </label>
                    <div class="clnt-modal-goal__donate">
                        <input :id="`amount-target-${info.id}`"
                               class="lds-input clnt-modal-goal__input"
                               v-money="maskCurrency"
                               v-model="amount">
                        <button class="lds-btn lds-btn_fill -green clnt-modal-goal__button"
                                :disabled="processing"
                                type="submit">
                            Отправить
                        </button>
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.amount.$error && !$v.amount.minValue">
                        Минимальная сумма {{ minAmount }} LC
                    </div>
                    <div class="lds-control__message"
                         v-if="$v.amount.$error && !$v.amount.maxValue">
                        Максимальная сумма {{ formatCoins(maxAvailableAmount) }} LC
                    </div>
                </form>
                <button v-if="info.statusCode === 'completed'"
                        class="lds-btn lds-btn_fill -green"
                        disabled
                        data-test="completed">
                    Сбор завершен
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import { VMoney } from 'v-money';
import { maxValue, minValue } from 'vuelidate/lib/validators';
import formattingCoins from '@/mixins/formattingCoins';
import SvgIcon from '@/components/common/SvgIcon';
import {tour3egg7} from '@/models/EasterEggs/EasterEgg';
import EasterEggCookies, {tour3Egg7WindowClicked} from '@/models/EasterEggs/EasterEggCookies';

export default {
    name: 'ModalTarget',
    directives: {
        money: VMoney,
    },
    components: {
        SvgIcon,
    },
    mixins: [
        formattingCoins,
    ],
    props: {
        info: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    data() {
        return {
            maskCurrency: {
                thousands: ' ',
                precision: 0,
            },
            amountConvert: 0,
            minAmount: 1,
            maxAmount: parseFloat(this.info.amount) - parseFloat(this.info.progress),
        };
    },
    computed: {
        ...mapState('account', [
            'accountBalance',
        ]),
        ...mapState('centrmama', [
            'processing',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        amount: {
            get() {
                return this.amountConvert;
            },
            set(value) {
                this.amountConvert = value.toString().replace(/\s+/g, '');
            },
        },
        maxAvailableAmount() {
            return Math.min(this.maxAmount, this.accountBalance);
        },
        easterImagePath() {
            return require('@/assets/images/easterEggs/gold-easter-egg.jpg');
        },
        tour3EasterEgg7() {
            return this.getAvailable(tour3egg7);
        },
        tour3EasterEgg7WindowClicked() {
            return EasterEggCookies.getState(tour3Egg7WindowClicked);
        },
        tour3EasterEgg7Step() {
            return this.tour3EasterEgg7 && this.tour3EasterEgg7.needShow && this.tour3EasterEgg7WindowClicked
                && (this.info.title === 'Продукты' || this.info.title === 'Тестовый товар');
        },
        targetImagePath() {
            if (this.tour3EasterEgg7Step) {
                return this.easterImagePath;
            }
            return this.info.imageSrc;
        },
    },
    validations: {
        amount: {
            minValue(value) {
                const number = value ? parseFloat(value) : 0;
                return minValue(this.minAmount)(number);
            },
            maxValue(value) {
                const number = value ? parseFloat(value) : 0;
                return maxValue(this.maxAvailableAmount)(number);
            },
        },
    },
    mounted() {
        this.check(tour3egg7);
    },
    methods: {
        ...mapActions('centrmama', [
            'makeDonation',
        ]),
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        async sendDonation() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = {
                targetId: this.info.id,
                amount: parseFloat(this.amount),
            };
            const result = await this.makeDonation(data);
            if (result) {
                this.close();
            }
        },
        close() {
            this.$emit('close');
        },
        collectTour3Egg7() {
            this.collect(this.tour3EasterEgg7);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
