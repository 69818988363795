import axios from 'axios';

const mutations = {
    setLoading: (state, data) => {
        state.loading = data;
    },
    setSummary: (state, data) => {
        state.summary = data;
    },
};
const getters = {};
const actions = {
    getSummary: ({ commit, rootState }, data) => {
        commit('setLoading', true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${rootState.authorization.credential.token}`,
            },
        };
        return axios
            .get(`/rest/offers/summary?dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`, config)
            .then(response => {
                const result = response.data.data;
                commit('setSummary', result);
            })
            .catch(error => {
                const dataNotification = {
                    value: error,
                    type: 'error',
                };
                commit(
                    'common/setNotification', dataNotification, { root: true },
                );
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
};
const state = () => ({
    loading: false,
    summary: [],
});

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
